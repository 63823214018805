import { createId } from '@paralleldrive/cuid2'
import {
	createEntityAdapter,
	createSelector,
	createSlice,
	EntityState,
	PayloadAction,
	Update,
} from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import type { RootState } from '@/app/store'

export type TStaffGroup = {
	id: string
	name: string
}

const staffGroupsAdapter = createEntityAdapter<TStaffGroup>()

export const {
	selectIds: selectStaffGroupIds,
	selectEntities: selectStaffGroupEntities,
	selectAll: selectAllStaffGroups,
	selectTotal: selectTotalStaffGroups,
	selectById: selectStaffGroupById,
} = staffGroupsAdapter.getSelectors<RootState>(state => state.staffGroups)

export type StaffGroupsState = EntityState<TStaffGroup, string>

const initialState: StaffGroupsState = staffGroupsAdapter.getInitialState()

export const staffGroupsSlice = createSlice({
	name: 'staffGroups',
	initialState,
	reducers: {
		createStaffGroup: {
			reducer: (state, action: PayloadAction<TStaffGroup>) => {
				const staffGroupExists = Object.values(state.entities).some(
					(staffGroup): staffGroup is TStaffGroup =>
						staffGroup.name === action.payload.name,
				)
				if (!staffGroupExists && action.payload.name !== '') {
					staffGroupsAdapter.addOne(state, action.payload)
				}
			},
			prepare: (staffGroupData: Omit<TStaffGroup, 'id'>) => {
				const id = createId()
				return { payload: { ...staffGroupData, id } }
			},
		},
		editStaffGroup: (
			state,
			action: PayloadAction<Update<TStaffGroup, string>>,
		) => {
			staffGroupsAdapter.updateOne(state, action.payload)
		},
		deleteStaffGroup: (state, action: PayloadAction<string>) => {
			staffGroupsAdapter.removeOne(state, action.payload)
		},
	},
	extraReducers: builder => {
		builder.addCase(PURGE, state => {
			staffGroupsAdapter.removeAll(state)
		})
	},
})

export const { createStaffGroup, editStaffGroup, deleteStaffGroup } =
	staffGroupsSlice.actions

export const selectStaffGroupNames = createSelector(
	[selectAllStaffGroups],
	staffGroups => staffGroups.map(staffGroup => staffGroup.name),
)

export default staffGroupsSlice.reducer
