import { createId } from '@paralleldrive/cuid2'
import {
	createEntityAdapter,
	createSelector,
	createSlice,
	EntityState,
	Update,
} from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import type { RootState } from '@/app/store'

export type TTool = {
	id: string
	name: string
}

const toolsAdapter = createEntityAdapter<TTool>()

export const {
	selectIds: selectToolIds,
	selectEntities: selectToolEntities,
	selectAll: selectAllTools,
	selectTotal: selectTotalTools,
	selectById: selectToolById,
} = toolsAdapter.getSelectors<RootState>(state => state.tools)

export type ToolsState = EntityState<TTool, string>

const initialState: ToolsState = toolsAdapter.getInitialState()

export const toolsSlice = createSlice({
	name: 'tools',
	initialState,
	reducers: {
		createTool: {
			reducer: (state, action: PayloadAction<TTool>) => {
				const toolExists = Object.values(state.entities).some(
					(tool): tool is TTool => tool.name === action.payload.name,
				)
				if (!toolExists && action.payload.name !== '') {
					toolsAdapter.addOne(state, action.payload)
				}
			},
			prepare: (toolData: Omit<TTool, 'id'>) => {
				const id = createId()
				return { payload: { ...toolData, id } }
			},
		},
		editTool: (state, action: PayloadAction<Update<TTool, string>>) => {
			toolsAdapter.updateOne(state, action.payload)
		},
		deleteTool: (state, action: PayloadAction<string>) => {
			toolsAdapter.removeOne(state, action.payload)
		},
	},
	extraReducers: builder => {
		builder.addCase(PURGE, state => {
			toolsAdapter.removeAll(state)
		})
	},
})

export const { createTool, editTool, deleteTool } = toolsSlice.actions

export const selectToolNames = createSelector([selectAllTools], tools =>
	tools.map(tool => tool.name),
)

export default toolsSlice.reducer
