'use no memo'

import { useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { TOperator } from '@repo/types'
import { Check, Pencil, Plus, Trash2, X } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { GenericAlertDialog } from '@/components/generic-alert-dialog'
import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { DialogFooter } from '@/components/ui/dialog'
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
	createOperator,
	deleteOperator,
	editOperator,
	selectAllOperators,
} from '@/features/operators/operators-slice'

type ManageOperatorsDialogProps = {
	open: boolean
	onOpenChange: (open: boolean) => void
	container?: HTMLElement | null
}

const operatorSchema = z.object({
	name: z
		.string()
		.trim()
		.min(1, 'Name is required')
		.max(50, 'Name must be 50 characters or less'),
	initials: z
		.string()
		.trim()
		.min(1, 'Initials are required')
		.max(3, 'Initials must be 3 characters or less')
		.toUpperCase(),
})

type OperatorFormData = z.infer<typeof operatorSchema>

function generateInitials(name: string): string {
	return name
		.trim()
		.split(/\s+/)
		.filter(Boolean)
		.map((word, _index, arr) =>
			// If single word, take first 3 chars, otherwise take first char of each word
			arr.length === 1 ? word.slice(0, 3) : word.charAt(0),
		)
		.join('')
		.toUpperCase()
		.slice(0, 3)
}

export function ManageOperatorsDialog({
	open,
	onOpenChange,
	container,
}: ManageOperatorsDialogProps) {
	const dispatch = useAppDispatch()
	const operators = useAppSelector(selectAllOperators)
	const [editingOperator, setEditingOperator] = useState<TOperator | null>(null)
	const [operatorToDelete, setOperatorToDelete] = useState<TOperator | null>(
		null,
	)
	const [showNewOperatorForm, setShowNewOperatorForm] = useState(false)

	// Form for creating new operator
	const newOperatorForm = useForm({
		resolver: zodResolver(operatorSchema),
		defaultValues: {
			name: '',
			initials: '',
		},
	})

	// Form for editing operator
	const editOperatorForm = useForm<OperatorFormData>({
		resolver: zodResolver(operatorSchema),
	})

	const handleCreateOperator = (data: OperatorFormData) => {
		dispatch(createOperator(data))
		newOperatorForm.reset()
		setShowNewOperatorForm(false)
		toast.success('Operator created successfully')
	}

	const handleEditOperator = (data: OperatorFormData) => {
		if (!editingOperator) return

		dispatch(
			editOperator({
				id: editingOperator.id,
				changes: {
					...editingOperator,
					...data,
				},
			}),
		)
		setEditingOperator(null)
		toast.success('Operator updated successfully')
	}

	const startEditing = (operator: TOperator) => {
		setEditingOperator(operator)
		editOperatorForm.setValue('name', operator.name)
		editOperatorForm.setValue('initials', operator.initials)
	}

	const handleDeleteOperator = (operator: TOperator) => {
		void dispatch(deleteOperator(operator.id))
		setOperatorToDelete(null)
		toast.success('Operator deleted successfully')
	}

	return (
		<>
			<GenericDialog
				open={open}
				onClose={() => {
					onOpenChange(false)
				}}
				container={container}
				title="Manage Operators"
				description="Create, edit, or remove operators from the system."
				className="flex h-[90svh] flex-col gap-0 p-0 sm:max-w-[600px]"
				headerClassName="border-b p-6"
			>
				<div className="flex-1 space-y-4 overflow-y-auto p-6">
					{operators.length === 0 &&
					!editingOperator &&
					!showNewOperatorForm ? (
						<Card className="border-dashed p-6">
							<div className="text-center">
								<p className="text-sm font-medium">No operators available</p>
								<p className="text-muted-foreground mt-1 text-sm">
									Create operators to get started
								</p>
								<Button
									variant="outline"
									onClick={() => {
										setShowNewOperatorForm(true)
									}}
									className="mt-4 h-9 w-full sm:w-[200px]"
								>
									<Plus className="text-muted-foreground" />
									Create Operator
								</Button>
							</div>
						</Card>
					) : (
						<>
							{/* Create operator button */}
							{!editingOperator && !showNewOperatorForm && (
								<Button
									variant="outline"
									onClick={() => {
										setShowNewOperatorForm(true)
									}}
									className="h-9 w-full"
								>
									<Plus className="text-muted-foreground" />
									Create Operator
								</Button>
							)}

							{/* New operator form */}
							{showNewOperatorForm && !editingOperator && (
								<Card className="relative overflow-hidden p-4">
									<Form {...newOperatorForm}>
										<form
											onSubmit={e =>
												void newOperatorForm.handleSubmit(handleCreateOperator)(
													e,
												)
											}
										>
											<div className="flex flex-col gap-4">
												<div className="flex flex-row items-start gap-2 sm:gap-4">
													<div className="flex-1">
														<FormField
															control={newOperatorForm.control}
															name="name"
															render={({ field }) => (
																<FormItem>
																	<FormControl>
																		<Input
																			{...field}
																			aria-invalid={Boolean(
																				newOperatorForm.formState.errors.name,
																			)}
																			autoFocus
																			placeholder="Operator name"
																			className="h-9"
																			onChange={e => {
																				field.onChange(e)
																				const currentInitials =
																					newOperatorForm.getValues('initials')
																				// Only generate if initials is empty or matches previous generated value
																				if (
																					!currentInitials ||
																					currentInitials ===
																						generateInitials(field.value)
																				) {
																					const generatedInitials =
																						generateInitials(e.target.value)
																					newOperatorForm.setValue(
																						'initials',
																						generatedInitials,
																						{
																							shouldValidate: true,
																						},
																					)
																				}
																			}}
																		/>
																	</FormControl>
																	<FormMessage />
																</FormItem>
															)}
														/>
													</div>
													<div className="w-14">
														<FormField
															control={newOperatorForm.control}
															name="initials"
															render={({ field }) => (
																<FormItem>
																	<FormControl>
																		<Input
																			{...field}
																			placeholder="ABC"
																			aria-invalid={Boolean(
																				newOperatorForm.formState.errors
																					.initials,
																			)}
																			maxLength={3}
																			className="h-9 font-mono uppercase"
																			onChange={e => {
																				field.onChange(
																					e.target.value.toUpperCase(),
																				)
																			}}
																		/>
																	</FormControl>
																	<FormMessage />
																</FormItem>
															)}
														/>
													</div>
													<div className="flex items-center justify-end gap-2">
														<Button
															type="submit"
															variant="ghost"
															size="sm"
															className="size-9 p-0"
														>
															<Check />
														</Button>
														<Button
															type="button"
															variant="ghost"
															size="sm"
															onClick={() => {
																newOperatorForm.reset()
																setShowNewOperatorForm(false)
															}}
															className="size-9 p-0"
														>
															<X />
														</Button>
													</div>
												</div>
											</div>
										</form>
									</Form>
								</Card>
							)}

							{/* Existing operators */}
							{operators.length > 0 && (
								<div className="flex flex-col-reverse gap-4">
									{operators.map(operator => (
										<Card
											key={operator.id}
											className="relative overflow-hidden p-4"
										>
											{editingOperator?.id === operator.id ? (
												<Form {...editOperatorForm}>
													<form
														onSubmit={e =>
															void editOperatorForm.handleSubmit(
																handleEditOperator,
															)(e)
														}
													>
														<div className="flex flex-col gap-4">
															<div className="flex flex-row items-start gap-2 sm:gap-4">
																<div className="flex-1">
																	<FormField
																		control={editOperatorForm.control}
																		name="name"
																		render={({ field }) => (
																			<FormItem>
																				<FormControl>
																					<Input
																						{...field}
																						aria-invalid={Boolean(
																							editOperatorForm.formState.errors
																								.name,
																						)}
																						placeholder="Operator name"
																						className="h-9"
																					/>
																				</FormControl>
																				<FormMessage />
																			</FormItem>
																		)}
																	/>
																</div>
																<div className="w-14">
																	<FormField
																		control={editOperatorForm.control}
																		name="initials"
																		render={({ field }) => (
																			<FormItem>
																				<FormControl>
																					<Input
																						{...field}
																						aria-invalid={Boolean(
																							editOperatorForm.formState.errors
																								.initials,
																						)}
																						placeholder="ABC"
																						maxLength={3}
																						className="h-9 font-mono uppercase"
																					/>
																				</FormControl>
																				<FormMessage />
																			</FormItem>
																		)}
																	/>
																</div>
																<div className="flex items-center justify-end gap-2">
																	<Button
																		type="submit"
																		variant="ghost"
																		size="sm"
																		className="size-9 p-0"
																	>
																		<Check />
																	</Button>
																	<Button
																		type="button"
																		variant="ghost"
																		size="sm"
																		onClick={() => {
																			setEditingOperator(null)
																			editOperatorForm.reset()
																		}}
																		className="size-9 p-0"
																	>
																		<X />
																	</Button>
																</div>
															</div>
														</div>
													</form>
												</Form>
											) : (
												<div className="flex flex-col gap-4">
													<div className="flex flex-row items-center gap-4">
														<div className="flex-1">
															<div className="flex h-9 items-center text-sm">
																{operator.name}
															</div>
														</div>
														<div className="w-14">
															<div className="flex h-9 items-center font-mono text-sm">
																{operator.initials}
															</div>
														</div>
														<div className="flex items-center justify-end gap-2">
															<Button
																variant="ghost"
																size="sm"
																onClick={() => {
																	startEditing(operator)
																}}
																className="size-9 p-0"
															>
																<Pencil />
															</Button>
															<Button
																variant="ghost"
																size="sm"
																onClick={() => {
																	setOperatorToDelete(operator)
																}}
																className="text-destructive hover:text-destructive size-9 p-0"
															>
																<Trash2 />
															</Button>
														</div>
													</div>
												</div>
											)}
										</Card>
									))}
								</div>
							)}
						</>
					)}
				</div>

				<DialogFooter className="border-t p-6">
					<Button
						variant="outline"
						onClick={() => {
							onOpenChange(false)
						}}
						className="w-full sm:w-auto"
					>
						Close
					</Button>
				</DialogFooter>
			</GenericDialog>

			<GenericAlertDialog
				title="Delete operator"
				description={`Are you sure you want to delete ${
					operatorToDelete ? `"${operatorToDelete.name}"` : 'this operator'
				}? This will permanently remove the operator from the system and remove them from all bookings they are assigned to. This action cannot be undone.`}
				open={Boolean(operatorToDelete)}
				confirmButtonText="Delete"
				onClose={() => {
					setOperatorToDelete(null)
				}}
				onConfirm={() => {
					if (operatorToDelete) {
						handleDeleteOperator(operatorToDelete)
					}
				}}
			/>
		</>
	)
}
