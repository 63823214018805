import {
	closestCenter,
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import {
	rectSortingStrategy,
	SortableContext,
	sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import {
	createFileRoute,
	Link,
	Outlet,
	useNavigate,
} from '@tanstack/react-router'
import { LayoutGrid, Plus } from 'lucide-react'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Draggable } from '@/components/dnd/draggable'
import { PageLayout } from '@/components/layout'
import {
	ScrollableToolbar,
	ScrollableToolbarContentLeft,
	ScrollableToolbarContentRight,
} from '@/components/scrollable-toolbar'
import { Button } from '@/components/ui/button'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/ui/tooltip'
import { MachineCard } from '@/features/machines/components/machine-card'
import {
	reorderMachine,
	selectAllMachines,
} from '@/features/machines/machines-slice'
import { useTouchDeviceDetector } from '@/hooks/use-touch-device-detector'

type ViewOptions = 'list' | 'grid'

type MachineSearch = {
	view?: ViewOptions
}

const Route = createFileRoute('/machines')({
	component: MachinesComponent,
	validateSearch: (search: Record<string, unknown>): MachineSearch => {
		return {
			view: search.view as MachineSearch['view'],
		}
	},
})

function MachinesComponent() {
	const navigate = useNavigate({ from: Route.fullPath })
	const dispatch = useAppDispatch()
	const machines = useAppSelector(selectAllMachines)
	const [parent, enableAnimations] = useAutoAnimate()
	const { isTouchDevice } = useTouchDeviceDetector()

	function handleDragStart() {
		enableAnimations(false)
	}

	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event

		if (active.id !== over?.id) {
			const oldIndex = machines.findIndex(m => m.id === active.id)
			const newIndex = machines.findIndex(m => m.id === over?.id)
			if (oldIndex === -1 || newIndex === -1) return
			dispatch(reorderMachine({ newIndex, oldIndex }))
		}
		setTimeout(() => {
			enableAnimations(true)
		}, 0)
	}

	return (
		<PageLayout>
			<ScrollableToolbar>
				<ScrollableToolbarContentLeft>
					<h1 className="text-xl sm:text-2xl">Machines</h1>
				</ScrollableToolbarContentLeft>
				<ScrollableToolbarContentRight>
					<Button asChild>
						<Link to="/machines/create">
							<Plus />
							Create Machine
						</Link>
					</Button>
					<Tabs value="grid">
						<TabsList aria-label="View options">
							<Tooltip>
								<TooltipTrigger>
									<TabsTrigger
										value="grid"
										aria-label="Switch to grid view"
										asChild
									>
										<Link to="." search={old => ({ ...old, view: 'grid' })}>
											<LayoutGrid className="size-4" />
										</Link>
									</TabsTrigger>
								</TooltipTrigger>
								<TooltipContent>
									<p>Grid View</p>
								</TooltipContent>
							</Tooltip>
						</TabsList>
					</Tabs>
				</ScrollableToolbarContentRight>
			</ScrollableToolbar>
			<div className="py-8">
				<DndContext
					sensors={useSensors(
						useSensor(isTouchDevice ? TouchSensor : PointerSensor, {
							activationConstraint: isTouchDevice
								? { delay: 250, tolerance: 5 }
								: { distance: 10 },
						}),
						useSensor(KeyboardSensor, {
							coordinateGetter: sortableKeyboardCoordinates,
						}),
					)}
					modifiers={[restrictToParentElement]}
					collisionDetection={closestCenter}
					onDragStart={handleDragStart}
					onDragEnd={handleDragEnd}
				>
					<SortableContext
						strategy={rectSortingStrategy}
						items={machines.map(m => m.id)}
					>
						<div
							ref={parent}
							className="mt-4 grid w-full gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
						>
							{machines.map(machine => (
								<Draggable
									key={machine.id}
									id={machine.id}
									className="rounded-lg"
								>
									<MachineCard
										machine={machine}
										onEdit={() => {
											void navigate({
												to: '/machines/$machineId/edit',
												params: { machineId: machine.id },
											})
										}}
										onDuplicate={() => {
											void navigate({
												to: '/machines/$machineId/duplicate',
												params: { machineId: machine.id },
											})
										}}
										onDelete={() => {
											void navigate({
												to: '/machines/$machineId/delete',
												params: { machineId: machine.id },
											})
										}}
									/>
								</Draggable>
							))}
						</div>
					</SortableContext>
				</DndContext>
			</div>
			<Outlet />
		</PageLayout>
	)
}

export { Route }
