import * as React from 'react'

import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'

import { cn } from '@/lib/utils'

import { Button } from './button'
import { Calendar } from './calendar'
import { Popover, PopoverContent, PopoverTrigger } from './popover'

type DatePickerProps = {
	date?: Date
	onSelect?: (date?: Date) => void
	align?: 'start' | 'center' | 'end'
} & {
	container?: HTMLElement | null
} & Omit<React.ComponentProps<'button'>, 'onSelect'>

export function DatePicker({
	date,
	onSelect,
	align = 'start',
	container,
	className,
	...props
}: DatePickerProps) {
	const [open, setOpen] = React.useState(false)

	const handleSelect = React.useCallback(
		(selectedDate?: Date) => {
			onSelect?.(selectedDate)
			setOpen(false)
		},
		[onSelect],
	)

	return (
		<Popover modal open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					className={cn(
						'min-w-[200px] justify-start px-2 font-normal',
						!date && 'text-muted-foreground',
						className,
					)}
					{...props}
				>
					<CalendarIcon className="text-muted-foreground" />
					{date ? format(date, 'PPP') : <span>Pick a date</span>}
				</Button>
			</PopoverTrigger>
			<PopoverContent
				container={container}
				className="w-auto p-0"
				align={align}
			>
				<Calendar
					mode="single"
					selected={date}
					defaultMonth={date}
					onSelect={handleSelect}
					initialFocus
				/>
			</PopoverContent>
		</Popover>
	)
}
