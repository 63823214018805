import { createId } from '@paralleldrive/cuid2'
import {
	createEntityAdapter,
	createSelector,
	createSlice,
	EntityState,
} from '@reduxjs/toolkit'
import type { PayloadAction, Update } from '@reduxjs/toolkit'
import { TProductOperation } from '@repo/types'
import { PURGE } from 'redux-persist'

import type { RootState } from '@/app/store'

import { selectMachineEntities } from '../machines/machines-slice'
import { populateProductOperation } from './utils/populate-product-operation'

const productOperationsAdapter = createEntityAdapter<TProductOperation>()

export const {
	selectIds: selectProductOperationIds,
	selectEntities: selectProductOperationEntities,
	selectAll: selectAllProductOperations,
	selectTotal: selectTotalProductOperations,
	selectById: selectProductOperationById,
} = productOperationsAdapter.getSelectors<RootState>(
	state => state.productOperations,
)

export type ProductOperationsState = EntityState<TProductOperation, string>

const initialState: ProductOperationsState =
	productOperationsAdapter.getInitialState()

export const productOperationsSlice = createSlice({
	name: 'productOperations',
	initialState,
	reducers: {
		createProductOperation: {
			reducer: (state, action: PayloadAction<TProductOperation>) => {
				productOperationsAdapter.addOne(state, action.payload)
			},
			prepare: (
				productOperationData: Omit<TProductOperation, 'id'>,
				id: string = createId(),
			) => {
				return { payload: { ...productOperationData, id } }
			},
		},
		editProductOperation: (
			state,
			action: PayloadAction<Update<TProductOperation, string>>,
		) => {
			productOperationsAdapter.updateOne(state, action.payload)
		},
		deleteProductOperation: (state, action: PayloadAction<string>) => {
			productOperationsAdapter.removeOne(state, action.payload)
		},
		updateProductOperations: (
			state,
			action: PayloadAction<
				{ operations: TProductOperation[] } & { productId: string }
			>,
		) => {
			const { operations } = action.payload
			// upsertMany will either update existing entities or add new ones
			productOperationsAdapter.upsertMany(state, operations)

			// Remove any old operations for this product that weren't in the new set
			const operationIds = new Set(operations.map(op => op.id))
			const staleOperations = Object.values(state.entities)
				.filter(
					(op): op is TProductOperation =>
						op !== null &&
						op.productId === action.payload.productId &&
						!operationIds.has(op.id),
				)
				.map(op => op.id)

			if (staleOperations.length > 0) {
				productOperationsAdapter.removeMany(state, staleOperations)
			}
		},
		reorderProductOperation: (
			state,
			action: PayloadAction<{ oldIndex: number; newIndex: number }>,
		) => {
			const { oldIndex, newIndex } = action.payload
			const ids = [...state.ids] as string[]
			const [removed] = ids.splice(oldIndex, 1)
			ids.splice(newIndex, 0, removed)
			state.ids = ids
		},
	},
	extraReducers: builder => {
		builder.addCase(PURGE, state => {
			productOperationsAdapter.removeAll(state)
		})
	},
})

export const {
	createProductOperation,
	editProductOperation,
	deleteProductOperation,
	updateProductOperations,
	reorderProductOperation,
} = productOperationsSlice.actions

export const selectPopulatedProductOperation = createSelector(
	selectProductOperationById,
	selectMachineEntities,
	(productOperation, machineEntities) => {
		if (!productOperation) return undefined
		return populateProductOperation(productOperation, machineEntities)
	},
)

export const selectPopulatedProductOperations = createSelector(
	selectAllProductOperations,
	selectMachineEntities,
	(productOperations, machineEntities) =>
		productOperations.map(productOperation =>
			populateProductOperation(productOperation, machineEntities),
		),
)

export default productOperationsSlice.reducer
