import { TPhasedItemV2 } from '@repo/types'
import { createRootRoute, Outlet, useNavigate } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/react-router-devtools'
import { zodValidator } from '@tanstack/zod-adapter'
import { toast } from 'sonner'
import { z } from 'zod'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { store } from '@/app/store'
import { Footer } from '@/components/footer'
import { Header } from '@/components/header'
import { TailwindIndicator } from '@/components/tailwind-indicator'
import { Toaster } from '@/components/ui/sonner'
import { TooltipProvider } from '@/components/ui/tooltip'
import UpdatePrompt from '@/components/update-prompt'
import { AssignOperatorsDialog } from '@/features/operators/components/assign-operators-dialog'
import { EditOrderDialog } from '@/features/orders/components/edit-order-dialog'
import { selectOrderById } from '@/features/orders/orders-slice'
import { useFullScreen } from '@/features/planning/context/full-screen-provider'
import {
	selectAllBookings,
	selectBooking,
	updateBookingOperators,
} from '@/features/planning/planning-slice'

const rootSearchSchema = z.object({
	editOrder: z.string().optional(),
	assignOperators: z.string().optional(),
})

const Route = createRootRoute({
	component: RootComponent,
	validateSearch: zodValidator(rootSearchSchema),
	loaderDeps: ({ search: { editOrder, assignOperators } }) => ({
		editOrder,
		assignOperators,
	}),
	loader: ({ deps: { editOrder, assignOperators } }) => {
		const state = store.getState()
		return {
			editingOrder: editOrder ? selectOrderById(state, editOrder) : undefined,
			assigningOperatorsTo: assignOperators
				? selectBooking(state, assignOperators)
				: undefined,
		}
	},
})

function RootComponent() {
	const { editingOrder, assigningOperatorsTo } = Route.useLoaderData()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const bookings = useAppSelector(selectAllBookings)
	const { fullScreenContainer } = useFullScreen()

	return (
		<>
			<UpdatePrompt />
			<Toaster
				offset={{ bottom: 16, right: 96 }}
				mobileOffset={{ bottom: 80 }}
				expand
				closeButton
			/>
			<TooltipProvider>
				<div className="flex min-h-screen w-svw flex-col items-center">
					<Header bookings={bookings} />
					<main className="bg-muted/50 flex w-full flex-1 flex-col">
						<Outlet />
					</main>
					<Footer />
				</div>
			</TooltipProvider>
			<TanStackRouterDevtools />
			<TailwindIndicator />
			{editingOrder && (
				<EditOrderDialog
					container={fullScreenContainer}
					order={editingOrder}
					onClose={() => {
						void navigate({
							to: '.',
							search: old => ({
								...old,
								editOrder: undefined,
							}),
						})
					}}
				/>
			)}
			{assigningOperatorsTo && (
				<AssignOperatorsDialog
					container={fullScreenContainer}
					open={true}
					booking={assigningOperatorsTo}
					initialOperators={assigningOperatorsTo.operators}
					onOpenChange={open => {
						if (!open) {
							void navigate({
								to: '.',
								search: old => ({
									...old,
									assignOperators: undefined,
								}),
							})
						}
					}}
					onSubmit={(operators: TPhasedItemV2[]) => {
						dispatch(
							updateBookingOperators({
								id: assigningOperatorsTo.id,
								operators,
							}),
						)
						toast.success('Operators updated successfully')
						void navigate({
							to: '.',
							search: old => ({
								...old,
								assignOperators: undefined,
							}),
						})
					}}
				/>
			)}
		</>
	)
}

export { Route }
