import { type VariantProps } from 'class-variance-authority'
import { CheckIcon, CopyIcon } from 'lucide-react'
import { toast } from 'sonner'

import { Badge, badgeVariants } from '@/components/ui/badge'
import { useCopyToClipboard } from '@/hooks/use-copy-to-clipboard'
import { cn } from '@/lib/utils'

export function ClickToCopyBadge(
	props: {
		value: string
		displayValue?: string
		variant?: VariantProps<typeof badgeVariants>['variant']
	} & React.HTMLAttributes<HTMLDivElement>,
) {
	const { value, displayValue, variant = 'default', className, ...rest } = props
	const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 })

	const handleCopy = () => {
		copyToClipboard(value, {
			onSuccess: () => {
				toast.success('Copied to clipboard!', {
					duration: 2000,
				})
			},
			onError: () => {
				toast.error('Failed to copy. Please try again.', {
					duration: 2000,
				})
			},
		})
	}

	return (
		<Badge
			variant={variant}
			onClick={handleCopy}
			className={cn('inline-flex cursor-pointer items-center', className)}
			{...rest}
		>
			<span className="truncate">{displayValue ?? value}</span>
			{isCopied ? (
				<CheckIcon
					className={cn(
						'size-3',
						variant === 'default' && 'text-primary-foreground',
						variant === 'secondary' && 'text-secondary-foreground',
						variant === 'destructive' && 'text-white',
						variant === 'outline' && 'text-foreground',
					)}
				/>
			) : (
				<CopyIcon
					className={cn(
						'size-3',
						variant === 'default' && 'text-primary-foreground',
						variant === 'secondary' && 'text-secondary-foreground',
						variant === 'destructive' && 'text-white',
						variant === 'outline' && 'text-foreground',
					)}
				/>
			)}
		</Badge>
	)
}
