import { Link } from '@tanstack/react-router'
import { Bell, BellRing } from 'lucide-react'

import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { navigationMenuTriggerStyle } from '@/components/ui/navigation-menu'
import { cn } from '@/lib/utils'

export function AlertCenterIndicator(props: {
	alertCount: number
	className?: string
}) {
	const { alertCount, className } = props

	const hasAlerts = alertCount > 0

	return (
		<Button
			className={cn('gap-2 px-2', hasAlerts && 'text-destructive', className)}
			variant="outline"
			asChild
		>
			<Link to="/alerts" className={navigationMenuTriggerStyle()}>
				<div
					className={cn(
						'flex size-6 items-center justify-center rounded-full transition-colors',
						hasAlerts && 'bg-destructive text-white',
					)}
				>
					{hasAlerts ? (
						<BellRing className="size-4 shrink-0" />
					) : (
						<Bell className="size-4 shrink-0" />
					)}
				</div>
				{hasAlerts && <Badge variant="outline">{alertCount}</Badge>}
			</Link>
		</Button>
	)
}
