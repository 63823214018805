import { useCallback, useEffect, useState } from 'react'

import { AlertTriangleIcon, CloudIcon, CloudOffIcon } from 'lucide-react'

import { GenericAlertDialog } from '@/components/generic-alert-dialog'
import { Switch } from '@/components/ui/switch'
import {
	BACKUP_SETTING_CHANGED_EVENT,
	isBackupSettingEnabled,
	setBackupSettingEnabled,
} from '@/features/backup/backup-settings'
import { useDialogState } from '@/hooks/use-dialog-state'

export function BackupToggleLocalStorage() {
	// Initialize state directly from localStorage
	const [isEnabled, setIsEnabled] = useState<boolean>(() =>
		isBackupSettingEnabled(),
	)

	const backupAlertDialog = useDialogState()

	// Effect to listen for external changes to the setting
	useEffect(() => {
		const handleExternalSettingChange = (event?: Event | CustomEvent) => {
			// Check if it's our custom event and update state from its detail
			if (
				event &&
				event.type === BACKUP_SETTING_CHANGED_EVENT &&
				'detail' in event
			) {
				setIsEnabled(
					(event as CustomEvent<{ enabled: boolean }>).detail.enabled,
				)
			} else {
				// Otherwise (e.g., standard 'storage' event or initial check), re-read from localStorage
				setIsEnabled(isBackupSettingEnabled())
			}
		}

		// Listen for our custom event
		window.addEventListener(
			BACKUP_SETTING_CHANGED_EVENT,
			handleExternalSettingChange,
		)
		// Also listen for the standard 'storage' event (for changes in other tabs)
		window.addEventListener('storage', handleExternalSettingChange)

		// Cleanup listeners on component unmount
		return () => {
			window.removeEventListener(
				BACKUP_SETTING_CHANGED_EVENT,
				handleExternalSettingChange,
			)
			window.removeEventListener('storage', handleExternalSettingChange)
		}
	}, [])

	// Updates localStorage and local state
	const updateBackupSetting = useCallback((checked: boolean) => {
		setBackupSettingEnabled(checked) // Persist change (triggers event)
		setIsEnabled(checked) // Update local state immediately
	}, [])

	// Handles the switch interaction
	const handleSwitchChange = useCallback(
		(checked: boolean) => {
			if (!checked) {
				// User is trying to disable -> Show warning dialog
				backupAlertDialog.open()
			} else {
				// User is enabling -> Update setting directly
				updateBackupSetting(true)
			}
		},
		[backupAlertDialog, updateBackupSetting],
	)

	const confirmDisableBackup = useCallback(() => {
		backupAlertDialog.close()
		updateBackupSetting(false) // Update setting after confirmation
	}, [backupAlertDialog, updateBackupSetting])

	return (
		<>
			<GenericAlertDialog
				open={backupAlertDialog.isOpen}
				title="Disable Automatic Backups?"
				description={
					'Backups run hourly to protect your work from accidental mistakes or bugs. This also helps our support team resolve issues much faster if you need assistance.'
				}
				confirmButtonText={
					<>
						<AlertTriangleIcon />
						Disable Anyway
					</>
				}
				onConfirm={confirmDisableBackup}
				onClose={backupAlertDialog.close}
			>
				<p>
					We strongly recommend keeping automatic backups enabled! Disabling
					backups increases the risk of permanent data loss.
				</p>
			</GenericAlertDialog>
			{isEnabled ? <CloudIcon /> : <CloudOffIcon />}
			<span className="flex-grow">Automatic Backups</span>
			<Switch
				id="backup-toggle-localstorage"
				checked={isEnabled}
				onCheckedChange={handleSwitchChange}
				aria-label="Toggle automatic cloud backups"
			/>
		</>
	)
}
