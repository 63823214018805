/**
 * Calculates a simple hash of a JSON-serializable object to detect changes.
 *
 * This is useful for optimizing caching, syncing, deduplication, or backup operations
 * by avoiding unnecessary work when the data hasn't changed since the last check.
 *
 * The hash is based on the DJB2a algorithm — a lightweight, non-cryptographic
 * hashing function that mixes each character of a string using bitwise XOR.
 *
 * ⚠️ This hash function is **not intended for security purposes** (e.g., authentication,
 * cryptographic signatures, or tamper detection). It is only suitable for quick,
 * approximate comparison of structured data.
 *
 * ⚠️ Note: JSON.stringify is not guaranteed to preserve key order in objects.
 * If key order inconsistencies cause unnecessary hash changes, consider
 * replacing it with a stable stringifier like `json-stable-stringify`.
 *
 * @param data - A JSON-serializable object.
 * @returns A stringified positive integer hash representing the input data.
 */
export const hashJson = (data: unknown): string => {
	try {
		// Convert the object to a JSON string
		const jsonString = JSON.stringify(data)

		// DJB2a hash initialization
		let hash = 5381

		// Apply DJB2a: hash = hash * 33 ^ char
		for (let i = 0; i < jsonString.length; i++) {
			hash = (hash * 33) ^ jsonString.charCodeAt(i)
		}

		// Ensure a positive 32-bit unsigned integer
		return String(hash >>> 0)
	} catch (error) {
		console.error('Error hashing JSON object:', error)

		// Return fallback value if something goes wrong (e.g., circular refs)
		return 'hash_error'
	}
}
