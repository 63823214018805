import { createId } from '@paralleldrive/cuid2'
import { TProduct, TProductOperation } from '@repo/types'
import { createFileRoute, notFound, useNavigate } from '@tanstack/react-router'
import { toast } from 'sonner'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { store } from '@/app/store'
import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import { useConfirmExitFormDialog } from '@/features/form-dialog/use-confirm-exit-form-dialog'
import { selectAllMachines } from '@/features/machines/machines-slice'
import { ProductForm } from '@/features/products/forms/product-form'
import { updateProductOperations } from '@/features/products/product-operations-slice'
import {
	editProduct,
	selectPopulatedProduct,
	selectProductNames,
	selectProductNumbers,
} from '@/features/products/products-slice'
import {
	createStaffGroup,
	selectAllStaffGroups,
} from '@/features/staff-groups/staff-groups-slice'
import { createTool, selectAllTools } from '@/features/tools/tools-slice'

const Route = createFileRoute('/products/$productId/edit')({
	component: EditProductComponent,
	loader: ({ params: { productId } }) => {
		const product = selectPopulatedProduct(store.getState(), productId)
		if (!product) {
			// eslint-disable-next-line
			throw notFound()
		}
		return { product }
	},
})

function EditProductComponent() {
	const { product } = Route.useLoaderData()
	const { productId } = Route.useParams()
	const navigate = useNavigate({ from: Route.fullPath })
	const dispatch = useAppDispatch()
	const productNames = useAppSelector(selectProductNames)
	const productNumbers = useAppSelector(selectProductNumbers)
	const machines = useAppSelector(selectAllMachines)
	const tools = useAppSelector(selectAllTools)
	const staffGroups = useAppSelector(selectAllStaffGroups)

	const { ConfirmExitAlertDialog, formDialogProps, formProps } =
		useConfirmExitFormDialog<
			Omit<TProduct<Omit<TProductOperation, 'id' | 'productId'>>, 'id'>
		>({
			// TODO: Try using async thunk for this
			onSubmit: data => {
				const operations: TProductOperation[] = data.operations.map(
					operation => {
						const operationId = createId()
						return {
							...operation,
							id: operationId,
							productId,
						}
					},
				)
				dispatch(
					editProduct({
						id: productId,
						changes: {
							...data,
							operations: operations.map(o => ({
								id: o.id,
							})),
						},
					}),
				)
				dispatch(updateProductOperations({ operations, productId }))
				toast.success(`Product "${data.name}" updated`)
			},
			onClose: () => {
				void navigate({
					to: '/products',
					search: true,
				})
			},
		})

	return (
		<>
			<ConfirmExitAlertDialog />
			<GenericDialog
				title="Edit product"
				description="Update product details"
				hideDescription
				className="flex h-[90svh] w-[95svw] flex-col sm:max-w-screen-2xl"
				{...formDialogProps}
			>
				<ProductForm
					initialValues={product}
					submitButtons={<Button type="submit">Save Product</Button>}
					machines={machines}
					tools={tools}
					staffGroups={staffGroups}
					onOperationChanged={operation => {
						operation.tools.forEach(tool => dispatch(createTool(tool)))
						operation.staffGroups.forEach(staffGroup =>
							dispatch(createStaffGroup(staffGroup)),
						)
					}}
					existingNames={productNames.filter(name => name !== product.name)}
					existingProductNumbers={productNumbers.filter(
						productNumber => productNumber !== product.productNumber,
					)}
					{...formProps}
				/>
			</GenericDialog>
		</>
	)
}

export { Route }
