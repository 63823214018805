import { cn } from '@/lib/utils'

export function HeaderLayout(
	props: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>,
) {
	const { className, ...rest } = props
	return (
		<div className={cn('relative px-4 xl:container', className)} {...rest} />
	)
}

export function PageLayout(
	props: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>,
) {
	const { className, ...rest } = props
	return (
		<div className={cn('relative p-4 xl:container', className)} {...rest} />
	)
}

export function FooterLayout(
	props: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>,
) {
	const { className, ...rest } = props
	return <div className={cn('relative xl:container', className)} {...rest} />
}
