import {
	TCalendarAdjustment,
	TCalendarPeriodAdjustment,
	TMachine,
	TMachineAvailability,
	TMachineBooking,
	TPeriod,
	TPeriodString,
	TPhasedItem,
	TPhaseDuration,
	TPhaseSchedule,
	TPlannedMachineBooking,
	TPlanningParameters,
	TProductionTimeUnit,
	TProductOperationPhases,
	TSchedulingDirection,
	TTimeUnit,
} from '@repo/types'
import {
	addMinutes,
	addYears,
	areIntervalsOverlapping,
	compareAsc,
	differenceInSeconds,
	max,
	min,
	startOfDay,
	subMinutes,
} from 'date-fns'

import { getToolPeriods } from '../validation/validate-tool-overlaps'
import { adjustOpenPeriods } from './adjust-open-periods'
import { getCalendarAdjustmentsForMachine } from './get-calendar-adjustments-for-machine'
import { getOpenPeriods } from './get-open-periods'

// Maximum planning horizon in years
const PLANNING_HORIZON_YEARS = 10

export function convertToMinutes(
	args: {
		quantity?: number
		unit?: 'seconds' | 'minutes' | 'hours' | 'days'
	} = {},
): number {
	const quantity = args.quantity ?? 0
	switch (args.unit) {
		case 'seconds':
			return Math.ceil(quantity / 60)
		case 'minutes':
			return Math.ceil(quantity)
		case 'hours':
			return Math.ceil(quantity * 60)
		case 'days':
			return Math.ceil(quantity * 60 * 24)
		default:
			return 0
	}
}

export function getPhaseDurationMinutes(
	phaseDuration?: TPhaseDuration<TTimeUnit>,
) {
	return convertToMinutes(phaseDuration)
}
export function getProductionDurationMinutes(args: {
	productionDuration: TPhaseDuration<TProductionTimeUnit>
	quantity: number
}): number {
	const { productionDuration, quantity } = args
	const { unit, quantity: phaseQuantity } = productionDuration

	switch (unit) {
		case 'minutes':
			return Math.ceil(phaseQuantity)
		case 'seconds_per_piece':
			return Math.ceil((phaseQuantity * quantity) / 60)
		case 'minutes_per_piece':
			return Math.ceil(phaseQuantity * quantity)
		case 'pieces_per_second':
			return Math.ceil(quantity / phaseQuantity / 60)
		case 'pieces_per_minute':
			return Math.ceil(quantity / phaseQuantity)
		default:
			return 0
	}
}

export function getTotalOperationDurationMinutes(args: {
	setupDuration?: TPhaseDuration<TTimeUnit>
	productionDuration: TPhaseDuration<TProductionTimeUnit>
	teardownDuration?: TPhaseDuration<TTimeUnit>
	quantity: number
}): number {
	const { setupDuration, productionDuration, teardownDuration, quantity } = args
	const setupTime = getPhaseDurationMinutes(setupDuration)
	const teardownTime = getPhaseDurationMinutes(teardownDuration)
	const productionTime = getProductionDurationMinutes({
		productionDuration,
		quantity,
	})

	return setupTime + teardownTime + productionTime
}

function scheduleForwardFromEarliestStartDate(args: {
	earliestStartDate: Date
	dueDate: Date
	durationMinutes: number
	availability: TMachineAvailability
	calendarAdjustments: TCalendarPeriodAdjustment[]
}): TPeriod | null {
	const {
		earliestStartDate,
		dueDate,
		durationMinutes,
		availability,
		calendarAdjustments,
	} = args
	const startDate = earliestStartDate
	const endDate = addYears(max([dueDate, new Date()]), PLANNING_HORIZON_YEARS)
	const openPeriods = adjustOpenPeriods({
		startDate,
		endDate,
		calendarAdjustments,
		openPeriods: getOpenPeriods({
			startDate,
			endDate,
			availability,
		}),
	})
	if (openPeriods.length === 0) {
		console.warn('Could not find any open periods for scheduling.', args)
		return null
	}
	const currentStartDate = max([earliestStartDate, openPeriods[0].startDate])
	let currentEndDate = currentStartDate
	let minutesRemaining = durationMinutes

	for (const period of openPeriods) {
		if (currentEndDate < period.startDate) {
			currentEndDate = period.startDate
		}
		const periodStartDate = max([currentStartDate, period.startDate])
		const gain = Math.min(
			minutesRemaining,
			Math.ceil(differenceInSeconds(period.endDate, periodStartDate) / 60),
		)

		minutesRemaining -= gain
		currentEndDate = addMinutes(currentEndDate, gain)

		if (minutesRemaining <= 0) break
	}

	return minutesRemaining <= 0
		? { startDate: currentStartDate, endDate: currentEndDate }
		: null
}

function scheduleBackwardFromDueDate(args: {
	earliestStartDate: Date
	dueDate: Date
	durationMinutes: number
	availability: TMachineAvailability
	calendarAdjustments: TCalendarPeriodAdjustment[]
}): TPeriod | null {
	const {
		earliestStartDate,
		dueDate,
		durationMinutes,
		availability,
		calendarAdjustments,
	} = args
	const startDate = earliestStartDate
	const endDate = dueDate
	const openPeriods = adjustOpenPeriods({
		startDate,
		endDate,
		calendarAdjustments,
		openPeriods: getOpenPeriods({
			startDate,
			endDate,
			availability,
		}),
	}).reverse()
	if (openPeriods.length === 0) {
		console.warn('Could not find any open periods for scheduling.', args)
		return null
	}

	const currentEndDate = min([dueDate, openPeriods[0].endDate])
	let currentStartDate = currentEndDate
	let minutesRemaining = durationMinutes

	for (const period of openPeriods) {
		if (currentStartDate > period.endDate) {
			currentStartDate = period.endDate
		}

		const periodEndDate = min([currentStartDate, period.endDate])
		const gain = Math.min(
			minutesRemaining,
			Math.ceil(differenceInSeconds(periodEndDate, period.startDate) / 60),
		)

		minutesRemaining -= gain
		currentStartDate = subMinutes(currentStartDate, gain)

		if (minutesRemaining <= 0) break
	}

	return minutesRemaining <= 0
		? { startDate: currentStartDate, endDate: currentEndDate }
		: null
}

function checkToolConflict<T extends TPeriod | TPeriodString = TPeriod>(args: {
	existingBooking: TMachineBooking
	machine: Pick<TMachine, 'id'>
	tools: TPhasedItem[]
	phases: TPhaseSchedule<T>
}): boolean {
	const { existingBooking, machine, tools, phases } = args
	const { id: machineId } = machine

	if (
		existingBooking.machineId === machineId ||
		existingBooking.status === 'completed'
	) {
		return false
	}

	const existingTools = new Set(existingBooking.tools.map(tool => tool.name))

	const relevantTools = tools.filter(tool => existingTools.has(tool.name))

	for (const tool of relevantTools) {
		const toolName = tool.name
		const existingPeriods = getToolPeriods({
			toolName,
			tools: existingBooking.tools,
			phases: {
				before: {
					startDate: new Date(existingBooking.phases.before.startDate),
					endDate: new Date(existingBooking.phases.before.endDate),
				},
				during: {
					startDate: new Date(existingBooking.phases.during.startDate),
					endDate: new Date(existingBooking.phases.during.endDate),
				},
				after: {
					startDate: new Date(existingBooking.phases.after.startDate),
					endDate: new Date(existingBooking.phases.after.endDate),
				},
			},
		})
		const newPeriods = getToolPeriods({ toolName, tools, phases })

		for (const existingPeriod of existingPeriods) {
			for (const newPeriod of newPeriods) {
				if (
					areIntervalsOverlapping(
						{ start: existingPeriod.startDate, end: existingPeriod.endDate },
						{ start: newPeriod.startDate, end: newPeriod.endDate },
					)
				) {
					return true
				}
			}
		}
	}

	return false
}

function checkOverlapConflict<
	T extends TPeriod | TPeriodString = TPeriod,
>(args: {
	existingBooking: TMachineBooking
	machine: Pick<TMachine, 'id'>
	periods: TPhaseSchedule<T>
}): boolean {
	const { existingBooking, machine, periods } = args

	return (
		existingBooking.machineId === machine.id &&
		existingBooking.status !== 'completed' &&
		areIntervalsOverlapping(
			{ start: existingBooking.startDate, end: existingBooking.endDate },
			{ start: periods.before.startDate, end: periods.after.endDate },
		)
	)
}

function isBookingRelevantL1(args: {
	booking: TMachineBooking
	window: TPeriod | TPeriodString
}) {
	const { booking, window } = args
	// Remove completed bookings
	if (booking.status === 'completed') return false

	// Check if the booking interval overlaps with the scheduling window
	return areIntervalsOverlapping(
		{ start: booking.startDate, end: booking.endDate },
		{ start: window.startDate, end: window.endDate },
	)
}

function isBookingRelevantL2(args: {
	booking: TMachineBooking
	window: TPeriod | TPeriodString
	machineId: string
	toolSet: Set<string>
}) {
	const { booking, window, machineId, toolSet } = args

	const hasSameMachine = booking.machineId === machineId
	const hasToolOverlap = booking.tools.some(tool => toolSet.has(tool.name))

	return (
		isBookingRelevantL1({ booking, window }) &&
		(hasSameMachine || hasToolOverlap)
	)
}

/**
 * Schedules a single operation on a specific machine.
 *
 * Core scheduling algorithm that finds time slots for each phase of an operation
 * (setup, production, teardown) based on the specified direction.
 *
 * This function handles machine availability, calendar adjustments, and conflict resolution,
 * but does not implement fallback mechanisms. It may return null if no viable schedule
 * can be found within the given constraints.
 *
 * @see scheduleOrder For the complete scheduling strategy including fallback mechanisms
 */
export function scheduleOperation(args: {
	phases: TProductOperationPhases
	planningParameters: Pick<
		TPlanningParameters,
		'quantity' | 'earliestStartDate' | 'dueDate' | 'buffer'
	>
	machine: Pick<TMachine, 'id' | 'availability'>
	calendarAdjustments: TCalendarPeriodAdjustment[]
	bookings: TMachineBooking[]
	direction: TSchedulingDirection
	tools: TPhasedItem[]
}): Pick<
	TMachineBooking,
	'startDate' | 'endDate' | 'phases' | 'effectiveTimeMinutes'
> | null {
	const {
		phases,
		machine,
		calendarAdjustments,
		bookings,
		planningParameters,
		direction,
		tools,
	} = args
	const { quantity, earliestStartDate, dueDate } = planningParameters
	const productionDuration = phases.during.find(
		productionPhase => productionPhase.machine.id === machine.id,
	)?.duration ?? {
		quantity: 0,
		unit: 'minutes',
	}
	const [setupTime, productionTime, teardownTime] = [
		getPhaseDurationMinutes(phases.before),
		getProductionDurationMinutes({
			productionDuration,
			quantity,
		}),
		getPhaseDurationMinutes(phases.after),
	]

	const forwardDueDate = new Date(dueDate)
	const backwardDueDate = subMinutes(
		dueDate,
		convertToMinutes(planningParameters.buffer),
	)

	let attemptedEarliestStartDate = new Date(earliestStartDate)
	let attemptedDueDate =
		direction === 'backward' ? backwardDueDate : forwardDueDate

	// Calculate operation time window
	const operationWindow = {
		startDate: attemptedEarliestStartDate,
		endDate:
			direction === 'backward'
				? attemptedDueDate
				: addYears(max([attemptedDueDate, new Date()]), PLANNING_HORIZON_YEARS),
	}

	// Create tool set once
	const toolSet = new Set(tools.map(tool => tool.name))

	// Second layer of filtering using existing helper
	const relevantBookings = bookings.filter(booking =>
		isBookingRelevantL2({
			booking,
			window: operationWindow,
			machineId: machine.id,
			toolSet,
		}),
	)

	let tries = 5_000

	let before: TPeriod = { startDate: new Date(0), endDate: new Date(0) }
	let during: TPeriod = { startDate: new Date(0), endDate: new Date(0) }
	let after: TPeriod = { startDate: new Date(0), endDate: new Date(0) }

	while (tries-- > 0) {
		if (direction === 'backward') {
			const newAfter = scheduleBackwardFromDueDate({
				earliestStartDate: attemptedEarliestStartDate,
				dueDate: attemptedDueDate,
				durationMinutes: teardownTime,
				availability: machine.availability,
				calendarAdjustments,
			})
			if (!newAfter) return null
			after = newAfter
			const newDuring = scheduleBackwardFromDueDate({
				earliestStartDate: attemptedEarliestStartDate,
				dueDate: newAfter.startDate,
				durationMinutes: productionTime,
				availability: machine.availability,
				calendarAdjustments,
			})
			if (!newDuring) return null
			during = newDuring
			const newBefore = scheduleBackwardFromDueDate({
				earliestStartDate: attemptedEarliestStartDate,
				dueDate: newDuring.startDate,
				durationMinutes: setupTime,
				availability: machine.availability,
				calendarAdjustments,
			})
			if (!newBefore) return null
			before = newBefore
		} else {
			const newBefore = scheduleForwardFromEarliestStartDate({
				earliestStartDate: attemptedEarliestStartDate,
				dueDate: attemptedDueDate,
				durationMinutes: setupTime,
				availability: machine.availability,
				calendarAdjustments,
			})
			if (!newBefore) return null
			before = newBefore
			const newDuring = scheduleForwardFromEarliestStartDate({
				earliestStartDate: newBefore.endDate,
				dueDate: attemptedDueDate,
				durationMinutes: productionTime,
				availability: machine.availability,
				calendarAdjustments,
			})
			if (!newDuring) return null
			during = newDuring
			const newAfter = scheduleForwardFromEarliestStartDate({
				earliestStartDate: newDuring.endDate,
				dueDate: attemptedDueDate,
				durationMinutes: teardownTime,
				availability: machine.availability,
				calendarAdjustments,
			})
			if (!newAfter) return null
			after = newAfter
		}

		const conflicts = relevantBookings.filter(booking => {
			const hasOverlap = checkOverlapConflict({
				existingBooking: booking,
				machine,
				periods: { before, during, after },
			})

			const hasToolConflict = checkToolConflict({
				existingBooking: booking,
				machine,
				tools,
				phases: { before, during, after },
			})

			return hasOverlap || hasToolConflict
		})

		if (conflicts.length === 0) break

		if (direction === 'backward') {
			attemptedDueDate = subMinutes(
				min(conflicts.map(booking => booking.startDate)),
				1,
			)
		} else {
			attemptedEarliestStartDate = addMinutes(
				max(conflicts.map(booking => booking.endDate)),
				1,
			)
		}
	}

	if (tries <= 0) {
		console.warn('Exceeded maximum scheduling attempts.', args)
		return null
	}

	return {
		startDate: before.startDate.toISOString(),
		endDate: after.endDate.toISOString(),
		phases: {
			before: {
				startDate: before.startDate.toISOString(),
				endDate: before.endDate.toISOString(),
			},
			during: {
				startDate: during.startDate.toISOString(),
				endDate: during.endDate.toISOString(),
			},
			after: {
				startDate: after.startDate.toISOString(),
				endDate: after.endDate.toISOString(),
			},
		},
		effectiveTimeMinutes: {
			before: setupTime,
			during: productionTime,
			after: teardownTime,
			total: setupTime + productionTime + teardownTime,
		},
	}
}

type TMachineSchedulingResult = {
	machine: Pick<TMachine, 'id' | 'availability'>
	schedule: Pick<
		TMachineBooking,
		'startDate' | 'endDate' | 'phases' | 'effectiveTimeMinutes'
	>
	completionDate: Date
}

/**
 * Finds the best machine for an operation based on earliest completion time.
 *
 * This function tries each compatible machine and returns the one that can complete
 * the operation earliest. When using backward scheduling, it implements a fallback
 * to forward scheduling if no machine can be scheduled in the backward direction.
 *
 * The fallback ensures that operations are always scheduled when possible, even if
 * they cannot meet the original due date constraints.
 *
 * @see scheduleOrder For the complete scheduling strategy
 */
function findBestMachineForOperation(args: {
	operation: {
		id: string
		phases: TProductOperationPhases
		machines: Pick<TMachine, 'id' | 'availability'>[]
		tools: TPhasedItem[]
		staffGroups: TPhasedItem[]
		transition?: { waitingTime?: TPhaseDuration<TTimeUnit> }
	}
	planningParameters: Pick<
		TPlanningParameters,
		'quantity' | 'earliestStartDate' | 'dueDate' | 'buffer'
	>
	calendarAdjustments: TCalendarAdjustment[]
	bookings: TMachineBooking[]
	direction: TSchedulingDirection
}): TMachineSchedulingResult | null {
	const {
		operation,
		planningParameters,
		calendarAdjustments,
		bookings,
		direction,
	} = args

	// Try scheduling with the requested direction
	const machineResults = scheduleMachinesInDirection({
		operation,
		planningParameters,
		calendarAdjustments,
		bookings,
		direction,
	})

	// If backward scheduling failed, try forward scheduling as fallback
	if (machineResults.length === 0 && direction === 'backward') {
		console.info(
			'Backward scheduling failed, falling back to forward scheduling for operation',
			operation.id,
		)

		// Try forward scheduling
		return findBestMachineFromResults(
			scheduleMachinesInDirection({
				operation,
				planningParameters,
				calendarAdjustments,
				bookings,
				direction: 'forward',
			}),
		)
	}

	return findBestMachineFromResults(machineResults)
}

/**
 * Helper function to schedule all machines for an operation in a specific direction.
 * Returns an array of successful scheduling results.
 */
function scheduleMachinesInDirection(args: {
	operation: {
		id: string
		phases: TProductOperationPhases
		machines: Pick<TMachine, 'id' | 'availability'>[]
		tools: TPhasedItem[]
	}
	planningParameters: Pick<
		TPlanningParameters,
		'quantity' | 'earliestStartDate' | 'dueDate' | 'buffer'
	>
	calendarAdjustments: TCalendarAdjustment[]
	bookings: TMachineBooking[]
	direction: TSchedulingDirection
}): TMachineSchedulingResult[] {
	const {
		operation,
		planningParameters,
		calendarAdjustments,
		bookings,
		direction,
	} = args
	const results: TMachineSchedulingResult[] = []

	for (const machine of operation.machines) {
		const schedule = scheduleOperation({
			phases: operation.phases,
			planningParameters,
			machine,
			calendarAdjustments: getCalendarAdjustmentsForMachine({
				machineId: machine.id,
				calendarAdjustments,
			}),
			bookings,
			direction,
			tools: operation.tools,
		})

		if (schedule) {
			results.push({
				machine,
				schedule,
				completionDate: new Date(schedule.endDate),
			})
		}
	}

	return results
}

/**
 * Helper function to find the best machine from scheduling results.
 * Returns the machine with the earliest completion date, or null if no results.
 */
function findBestMachineFromResults(
	results: TMachineSchedulingResult[],
): TMachineSchedulingResult | null {
	if (results.length === 0) {
		return null
	}

	// Sort by completion date and return the best option
	results.sort((a, b) => compareAsc(a.completionDate, b.completionDate))
	return results[0]
}

/**
 * Schedules all operations for an order based on the specified planning parameters.
 *
 * Scheduling Direction Strategy:
 *
 * 1. Forward Scheduling: Operations are scheduled from the earliest start date forward.
 *    - Always feasible as the timeline extends infinitely into the future
 *    - Prioritizes starting work as soon as possible
 *    - May result in completion after the desired due date
 *    - Each operation's completion determines the earliest start of the next operation
 *
 * 2. Backward Scheduling: Operations are scheduled backward from the due date.
 *    - Attempts to complete operations just in time for the due date
 *    - Operates within a finite timeline (earliest start date to due date)
 *    - May not be feasible if insufficient time exists between now and the due date
 *    - Each operation's start time becomes the due date for the previous operation
 *
 * Automatic Fallback Mechanism:
 * The scheduling system implements fallback at two levels:
 *
 * 1. Machine Level (findBestMachineForOperation):
 *    - When no machine can be scheduled backward, automatically tries forward scheduling
 *    - Ensures individual operations can always be scheduled when machines are available
 *
 * 2. Order Level (scheduleOrder):
 *    - After backward scheduling completes, uses the calculated start date
 *    - Re-schedules the entire order forward from this start date
 *    - Ensures optimal timing while maintaining operation dependencies
 *
 * This multi-level fallback approach maximizes the chance of finding a viable schedule
 * while prioritizing due date adherence when possible.
 */
function scheduleOrder(args: {
	planningParameters: TPlanningParameters
	calendarAdjustments: TCalendarAdjustment[]
	bookings: TMachineBooking[]
	direction: TSchedulingDirection
	earliestAllowedStartDate?: Date
}): Omit<TPlannedMachineBooking, 'id'>[] | null {
	const {
		planningParameters,
		calendarAdjustments,
		bookings,
		direction,
		earliestAllowedStartDate = new Date(0),
	} = args
	const { operations, orderId } = planningParameters

	const earliestValidStartDate = max([
		planningParameters.earliestStartDate,
		earliestAllowedStartDate,
	])

	// Calculate the global time window for the entire order
	const orderWindow = {
		startDate: earliestValidStartDate,
		endDate: addYears(
			max([planningParameters.dueDate, new Date()]),
			PLANNING_HORIZON_YEARS,
		),
	}

	// First layer of filtering: Remove completed and out-of-scope bookings
	const relevantBookings = bookings.filter(booking =>
		isBookingRelevantL1({
			booking,
			window: orderWindow,
		}),
	)

	const newBookings: Omit<TPlannedMachineBooking, 'id'>[] = []
	let earliestStartDate = earliestValidStartDate
	let dueDate = planningParameters.dueDate
	const operationsInSchedulingOrder =
		direction === 'backward' ? [...operations].reverse() : operations

	for (let i = 0; i < operationsInSchedulingOrder.length; i++) {
		const operation = operationsInSchedulingOrder[i]

		// Find the best machine for this operation
		const bestMachineResult = findBestMachineForOperation({
			operation,
			planningParameters: {
				...planningParameters,
				earliestStartDate:
					operation.earliestStartDate ?? earliestStartDate.toISOString(), // Bookings can optionally have an earliestStartDate, for instance when importing from an external system
				dueDate,
			},
			calendarAdjustments,
			bookings: relevantBookings, // Pass the filtered bookings list
			direction,
		})

		if (!bestMachineResult) {
			console.warn(
				'Could not find any suitable machine for operation',
				operation,
			)
			return null
		}

		const { machine, schedule: plannedOperation } = bestMachineResult

		newBookings.push({
			...plannedOperation,
			status: 'planned',
			machineId: machine.id,
			orderId,
			productId: planningParameters.productId,
			operationId: operation.id,
			tools: operation.tools,
			staffGroups: operation.staffGroups,
			compatibleMachines: operation.machines.map(machine => machine.id),
			operators: [],
		})

		if (direction === 'backward') {
			const waitingTimeMinutes = convertToMinutes(
				operationsInSchedulingOrder[i + 1]?.transition?.waitingTime,
			)
			dueDate = subMinutes(
				plannedOperation.startDate,
				waitingTimeMinutes,
			).toISOString()
		} else {
			const waitingTimeMinutes = convertToMinutes(
				operation.transition?.waitingTime,
			)
			earliestStartDate = addMinutes(
				plannedOperation.endDate,
				waitingTimeMinutes,
			)
		}
	}

	if (direction === 'backward') {
		const backwardBookings = [...newBookings].reverse()
		const backwardStartDate = startOfDay(backwardBookings[0].startDate)
		const forwardBookings = scheduleOrder({
			planningParameters: {
				...planningParameters,
				earliestStartDate: max([
					planningParameters.earliestStartDate,
					backwardStartDate,
				]).toISOString(),
			},
			calendarAdjustments,
			bookings: relevantBookings, // Pass the filtered bookings list
			direction: 'forward',
			earliestAllowedStartDate,
		})
		return forwardBookings
	} else {
		return newBookings
	}
}

export { scheduleOrder }
