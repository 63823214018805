'use no memo'

import { useEffect, useMemo } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { TOrder, TProduct } from '@repo/types'
import { X } from 'lucide-react'
import {
	Controller,
	FieldErrors,
	SubmitHandler,
	useForm,
	UseFormHandleSubmit,
} from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

import { Combobox } from '@/components/ui/combobox'
import { DatePicker } from '@/components/ui/date-picker'
import { DialogFooter } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'
import { TextareaAutosize } from '@/components/ui/textarea'
import { TCustomer } from '@/features/customers/customers-slice'
import {
	decimalSeparator,
	thousandSeparator,
} from '@/utils/format-numeric-string'

import { OrderSchema, orderSchema } from './order-schema'

type SubmitButtonsRenderer =
	| ((props: {
			handleSubmit: UseFormHandleSubmit<OrderSchema>
	  }) => React.ReactNode)
	| React.ReactNode

function OrderForm(props: {
	container?: HTMLElement | null
	submitButtons: SubmitButtonsRenderer
	onSubmit: SubmitHandler<OrderSchema>
	onError?: (errors: FieldErrors<OrderSchema>) => void
	products: TProduct[]
	customers: TCustomer[]
	invalidProductionOrderNumbers: string[]
	onUnsavedChanges?: (hasUnsavedChanges: boolean) => void
	initialValues?: Partial<Omit<TOrder, 'id'>>
	disableProductField?: boolean
	disableQuantityField?: boolean
	disableBufferField?: boolean
}) {
	const {
		container,
		submitButtons,
		onSubmit,
		onError,
		products,
		customers,
		invalidProductionOrderNumbers,
		onUnsavedChanges,
		initialValues,
		disableProductField,
		disableQuantityField,
		disableBufferField,
	} = props

	const schema = useMemo(() => {
		return orderSchema.refine(
			data =>
				!invalidProductionOrderNumbers.includes(data.productionOrderNumber),
			data => ({
				message: `Operation "${data.productionOrderNumber}" already exists`,
				path: ['productionOrderNumber'],
			}),
		)
	}, [invalidProductionOrderNumbers])
	const {
		register,
		handleSubmit,
		control,
		formState: { errors, isDirty },
	} = useForm<OrderSchema>({
		resolver: zodResolver(schema),
		defaultValues: initialValues,
	})

	useEffect(() => {
		onUnsavedChanges?.(isDirty)
	}, [isDirty, onUnsavedChanges])

	return (
		<form
			onSubmit={e => void handleSubmit(onSubmit, onError)(e)}
			autoComplete="off"
		>
			<div className="grid gap-6 pt-4 pb-6">
				{/* Production Order Number and Sales Order Number */}
				<div className="grid grid-cols-2 items-end gap-x-4 gap-y-1.5">
					<div className="flex flex-col space-y-1.5">
						<Label htmlFor="productionOrderNumber">
							Production Order Number
						</Label>
						<Input
							id="productionOrderNumber"
							className="flex-grow"
							aria-invalid={Boolean(errors.productionOrderNumber)}
							{...register('productionOrderNumber', { required: true })}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<Label htmlFor="salesOrderNumber">Sales Order Number</Label>
						<Input
							id="salesOrderNumber"
							className="flex-grow"
							aria-invalid={Boolean(errors.salesOrderNumber)}
							{...register('salesOrderNumber', { required: true })}
						/>
					</div>
					{errors.productionOrderNumber && (
						<span className="text-destructive self-start text-xs">
							{errors.productionOrderNumber.message}
						</span>
					)}
					{errors.salesOrderNumber && (
						<span className="text-destructive col-start-2 self-start text-xs">
							{errors.salesOrderNumber.message}
						</span>
					)}
				</div>
				{/* Customer Name */}
				<div className="flex flex-col space-y-1.5">
					<Label htmlFor="customerName">Customer Name</Label>
					<Controller
						name="customerName"
						control={control}
						render={({ field }) => (
							<Combobox
								container={container}
								aria-invalid={Boolean(errors.customerName)}
								className="w-full md:max-w-none"
								initialValue={field.value}
								allowCreate
								searchPlaceholder="Search customers..."
								valuePlaceholder="Select a customer..."
								options={customers.map(c => ({
									value: c.name,
									label: c.name,
								}))}
								onSelect={field.onChange}
							/>
						)}
					/>
					{errors.customerName && (
						<span className="text-destructive text-xs">
							{errors.customerName.message}
						</span>
					)}
				</div>
				{/* Product and Quantity */}
				<div className="grid grid-cols-[1fr_auto_auto] items-end gap-x-2 gap-y-1.5">
					<div className="flex flex-col space-y-1.5">
						<Label htmlFor="product">Product</Label>
						<Controller
							name="product"
							control={control}
							render={({ field }) => (
								<Combobox
									container={container}
									className="w-full md:max-w-[220px]"
									classNameContent="w-[375px] max-w-[90svw]"
									disabled={disableProductField}
									aria-invalid={Boolean(errors.product?.id)}
									initialValue={field.value.id}
									searchPlaceholder="Search products..."
									valuePlaceholder="Select a product..."
									options={products.map(p => ({
										value: p.id,
										label: `${p.productNumber} ${p.name}`,
										keywords: [p.name, p.productNumber],
									}))}
									getOptionValue={option => option.label}
									onSelect={id => {
										field.onChange({ id })
									}}
								/>
							)}
						/>
					</div>
					<div className="flex h-9 items-center justify-center">
						<X className="text-muted-foreground size-3.5" />
					</div>
					<div className="flex flex-col space-y-1.5">
						<Label htmlFor="quantity">Order Quantity</Label>
						<Controller
							name="quantity"
							control={control}
							render={({ field }) => (
								<NumericFormat
									customInput={Input}
									id="quantity"
									className="w-full"
									aria-invalid={Boolean(errors.quantity)}
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									placeholder="0"
									value={field.value === 0 ? '' : field.value}
									onValueChange={values => {
										field.onChange(values.value)
									}}
									disabled={disableQuantityField}
								/>
							)}
						/>
					</div>
					{errors.product?.id && (
						<span className="text-destructive col-start-1 text-xs">
							{errors.product.id.message}
						</span>
					)}
					{errors.quantity && (
						<span className="text-destructive col-start-3 text-xs">
							{errors.quantity.message}
						</span>
					)}
				</div>
				{/* Earliest Production Start Date */}
				<div className="flex flex-col space-y-1.5">
					<Label htmlFor="earliestStartDate">
						Earliest Production Start Date
					</Label>
					<Controller
						name="earliestStartDate"
						control={control}
						render={({ field }) => (
							<DatePicker
								container={container}
								aria-invalid={Boolean(errors.earliestStartDate)}
								date={field.value ? new Date(field.value) : undefined}
								onSelect={date => {
									if (date) {
										field.onChange(date.toISOString())
									}
								}}
							/>
						)}
					/>
					{errors.earliestStartDate && (
						<span className="text-destructive text-xs">
							{errors.earliestStartDate.message}
						</span>
					)}
				</div>
				{/* Order Completion Due Date */}
				<div className="flex flex-col space-y-1.5">
					<Label htmlFor="dueDate">Order Completion Due Date</Label>
					<Controller
						name="dueDate"
						control={control}
						render={({ field }) => (
							<DatePicker
								container={container}
								aria-invalid={Boolean(errors.dueDate)}
								date={field.value ? new Date(field.value) : undefined}
								onSelect={date => {
									if (date) {
										field.onChange(date.toISOString())
									}
								}}
							/>
						)}
					/>
					{errors.dueDate && (
						<span className="text-destructive text-xs">
							{errors.dueDate.message}
						</span>
					)}
				</div>
				{/* Buffer Time */}
				<div className="flex flex-col space-y-1.5">
					<Label htmlFor="buffer.quantity">Buffer Time</Label>
					<div className="grid grid-cols-2 gap-x-4 gap-y-1.5">
						<Controller
							name="buffer.quantity"
							control={control}
							render={({ field }) => (
								<NumericFormat
									customInput={Input}
									id="buffer.quantity"
									className="text-right"
									aria-invalid={Boolean(errors.buffer?.quantity)}
									placeholder="0"
									value={field.value === 0 ? '' : field.value}
									onValueChange={values => {
										field.onChange(values.value || 0)
									}}
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									disabled={disableBufferField}
								/>
							)}
						/>
						<Controller
							name="buffer.unit"
							control={control}
							render={({ field }) => (
								<Select
									defaultValue={field.value}
									onValueChange={field.onChange}
									disabled={disableBufferField}
								>
									<SelectTrigger
										className="w-full"
										onBlur={field.onBlur}
										aria-invalid={Boolean(errors.buffer?.unit)}
									>
										<SelectValue placeholder="Select time unit" />
									</SelectTrigger>
									<SelectContent container={container}>
										<SelectItem value="hours">hours</SelectItem>
										<SelectItem value="days">days</SelectItem>
									</SelectContent>
								</Select>
							)}
						/>
						{errors.buffer?.message && (
							<span className="text-destructive text-xs">
								{errors.buffer.message}
							</span>
						)}
						{errors.buffer?.quantity && (
							<span className="text-destructive text-xs">
								{errors.buffer.quantity.message}
							</span>
						)}
						{errors.buffer?.unit && (
							<span className="text-destructive col-start-2 text-xs">
								{errors.buffer.unit.message}
							</span>
						)}
					</div>
				</div>
				{/* Comment */}
				<div className="flex flex-col space-y-1.5">
					<Label htmlFor="comment">Comment</Label>
					<TextareaAutosize
						id="comment"
						className="resize-none"
						minRows={2}
						maxRows={6}
						placeholder="Add a comment about this order..."
						{...register('comment')}
					/>
				</div>
			</div>
			<DialogFooter>
				{typeof submitButtons === 'function'
					? submitButtons({ handleSubmit })
					: submitButtons}
			</DialogFooter>
		</form>
	)
}

export { OrderForm }
