import { createFileRoute, notFound, useNavigate } from '@tanstack/react-router'

import { useAppDispatch } from '@/app/hooks'
import { store } from '@/app/store'
import { GenericAlertDialog } from '@/components/generic-alert-dialog'
import { cascadeDeleteOrder } from '@/features/orders/order-actions'
import { selectOrderById } from '@/features/orders/orders-slice'

const Route = createFileRoute('/orders/$orderId/delete')({
	component: DeleteOrderComponent,
	loader: ({ params: { orderId } }) => {
		const order = selectOrderById(store.getState(), orderId)
		if (!order) {
			// eslint-disable-next-line
			throw notFound()
		}
		return { order }
	},
})

function DeleteOrderComponent() {
	const { order } = Route.useLoaderData()
	const { orderId } = Route.useParams()
	const navigate = useNavigate({ from: Route.fullPath })
	const dispatch = useAppDispatch()

	function closeDialog() {
		void navigate({
			to: '/orders',
			search: true,
		})
	}

	return (
		<GenericAlertDialog
			title="Delete order"
			description={`Are you sure you want to delete "${order.productionOrderNumber}"? This action cannot be undone.`}
			confirmButtonText="Delete"
			onClose={closeDialog}
			onConfirm={() => {
				closeDialog()
				void dispatch(cascadeDeleteOrder(orderId))
			}}
		/>
	)
}

export { Route }
