import { createId } from '@paralleldrive/cuid2'
import {
	createEntityAdapter,
	createSelector,
	createSlice,
	EntityState,
} from '@reduxjs/toolkit'
import type { PayloadAction, Update } from '@reduxjs/toolkit'
import { TMachine } from '@repo/types'
import { PURGE } from 'redux-persist'

import type { RootState } from '@/app/store'

const machinesAdapter = createEntityAdapter<TMachine>()

export const {
	selectIds: selectMachineIds,
	selectEntities: selectMachineEntities,
	selectAll: selectAllMachines,
	selectTotal: selectTotalMachines,
	selectById: selectMachineById,
} = machinesAdapter.getSelectors<RootState>(state => state.machines)

export type MachinesState = EntityState<TMachine, string>

const initialState: MachinesState = machinesAdapter.getInitialState()

export const machinesSlice = createSlice({
	name: 'machines',
	initialState,
	reducers: {
		createMachine: {
			reducer: (state, action: PayloadAction<TMachine>) => {
				machinesAdapter.addOne(state, action.payload)
			},
			prepare: (machineData: Omit<TMachine, 'id'>) => {
				const id = createId()
				return { payload: { ...machineData, id } }
			},
		},
		editMachine: (state, action: PayloadAction<Update<TMachine, string>>) => {
			machinesAdapter.updateOne(state, action.payload)
		},
		deleteMachine: (state, action: PayloadAction<string>) => {
			machinesAdapter.removeOne(state, action.payload)
		},
		reorderMachine: (
			state,
			action: PayloadAction<{ oldIndex: number; newIndex: number }>,
		) => {
			const { oldIndex, newIndex } = action.payload
			const ids = [...state.ids] as string[]
			const [removed] = ids.splice(oldIndex, 1)
			ids.splice(newIndex, 0, removed)
			state.ids = ids
		},
	},
	extraReducers: builder => {
		builder.addCase(PURGE, state => {
			machinesAdapter.removeAll(state)
		})
	},
})

export const { createMachine, editMachine, deleteMachine, reorderMachine } =
	machinesSlice.actions

export const selectMultipleMachines = createSelector(
	[selectMachineEntities, (_, ids: string[]) => ids],
	(machineEntities, ids) =>
		ids
			.map(id => machineEntities[id])
			.filter((machine): machine is TMachine => Boolean(machine)),
)

export const selectMachineNames = createSelector(
	[selectAllMachines],
	machines => machines.map(machine => machine.name),
)

export const selectExternalMachineReferences = createSelector(
	[
		(state: RootState) => state.productOperations.entities,
		(state: RootState) => state.planning.bookings,
		(state: RootState) => state.planning.calendarAdjustments,
		(_, machineId: string) => machineId,
	],
	(productOperationEntities, bookings, calendarAdjustments, machineId) => {
		const productOperationReferences = Object.values(
			productOperationEntities,
		).filter(o => o.phases.during.some(p => p.machine.id === machineId))
		const bookingReferences = bookings.filter(
			b =>
				b.compatibleMachines.includes(machineId) || b.machineId === machineId,
		)
		const calendarAdjustmentReferences = calendarAdjustments.filter(a =>
			a.affectedMachines.some(m => m.id === machineId),
		)

		return {
			productOperationReferences,
			bookingReferences,
			calendarAdjustmentReferences,
		}
	},
)

export const selectCanDeleteMachine = createSelector(
	[selectExternalMachineReferences],
	externalMachineReferences => {
		return (
			externalMachineReferences.productOperationReferences.length === 0 &&
			externalMachineReferences.bookingReferences.length === 0 &&
			externalMachineReferences.calendarAdjustmentReferences.length === 0
		)
	},
)

export default machinesSlice.reducer
