import { TMachine } from '@repo/types'
import { createFileRoute, notFound, useNavigate } from '@tanstack/react-router'
import { toast } from 'sonner'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { store } from '@/app/store'
import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import { Combobox } from '@/components/ui/combobox'
import { Label } from '@/components/ui/label'
import { useConfirmExitFormDialog } from '@/features/form-dialog/use-confirm-exit-form-dialog'
import { MachineForm } from '@/features/machines/forms/machine-form'
import {
	createMachine,
	selectAllMachines,
	selectMachineById,
	selectMachineNames,
} from '@/features/machines/machines-slice'
import { useUniqueName } from '@/hooks/use-unique-name'

const Route = createFileRoute('/machines/$machineId/duplicate')({
	component: DuplicateMachineComponent,
	loader: ({ params: { machineId } }) => {
		const machine = selectMachineById(store.getState(), machineId)
		if (!machine) {
			// eslint-disable-next-line
			throw notFound()
		}
		return { machine }
	},
})

function DuplicateMachineComponent() {
	const { machine } = Route.useLoaderData()
	const navigate = useNavigate({ from: Route.fullPath })
	const dispatch = useAppDispatch()
	const machines = useAppSelector(selectAllMachines)
	const machineNames = useAppSelector(selectMachineNames)
	const { getUniqueName } = useUniqueName(machineNames)

	const { ConfirmExitAlertDialog, formDialogProps, formProps } =
		useConfirmExitFormDialog<Omit<TMachine, 'id'>>({
			onSubmit: data => {
				// TODO: Try using async thunk for this, and change the history so when you have created the machine and navigate back, it will go to the edit route instead of the create route
				dispatch(createMachine(data))
				toast.success(`Machine "${data.name}" created`)
			},
			onClose: () => {
				void navigate({
					to: '/machines',
					search: true,
				})
			},
		})

	return (
		<>
			<ConfirmExitAlertDialog />
			<GenericDialog
				title="Duplicate machine"
				description="Create a new machine by duplicating an existing one"
				hideDescription
				{...formDialogProps}
			>
				<div className="bg-muted -mx-4 -mb-4 grid grid-cols-[auto_1fr] items-center gap-4 rounded-md px-4 py-2">
					<Label className="text-xs">Duplicating</Label>
					<Combobox
						className="w-auto md:max-w-none"
						initialValue={machine.id}
						searchPlaceholder="Search machines..."
						valuePlaceholder="Select a machine..."
						options={machines.map(m => ({
							value: m.id,
							label: m.name,
						}))}
						getOptionValue={option => option.label}
						onSelect={id =>
							void navigate({
								to: '/machines/$machineId/duplicate',
								params: { machineId: id },
							})
						}
					/>
				</div>
				<MachineForm
					key={machine.id}
					initialValues={{
						...machine,
						name: getUniqueName(machine.name),
					}}
					submitButtons={<Button type="submit">Duplicate Machine</Button>}
					existingNames={machineNames}
					{...formProps}
				/>
			</GenericDialog>
		</>
	)
}

export { Route }
