import * as React from 'react'

import {
	createFileRoute,
	Link,
	Outlet,
	useNavigate,
} from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'
import { Bell, BellOff, LayoutGrid, MoreVertical, X } from 'lucide-react'
import { z } from 'zod'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { store } from '@/app/store'
import { PageLayout } from '@/components/layout'
import {
	ScrollableToolbar,
	ScrollableToolbarContentLeft,
	ScrollableToolbarContentRight,
} from '@/components/scrollable-toolbar'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { MultiSelectFilter } from '@/components/ui/multi-select-filter'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/ui/tooltip'
import { AlertCard } from '@/features/alerts/components/alert-card'
import { AlertIcon } from '@/features/alerts/components/alert-icon'
import { selectAllMachines } from '@/features/machines/machines-slice'
import {
	activateAlert,
	ignoreAlert,
	selectFilteredAlerts,
	selectOrdersWithAlerts,
	setZoomLevel,
	validatePlan,
} from '@/features/planning/planning-slice'
import { TPlanningAlert } from '@/features/validation/types'

const alertStatusEnum = z.enum(['active', 'ignored'])
const alertStatusOptions = alertStatusEnum.options.map(value => ({
	label: value.charAt(0).toUpperCase() + value.slice(1),
	value,
	icon: value === 'active' ? Bell : BellOff,
}))

const alertsSearchSchema = z.object({
	view: z.enum(['list', 'grid']).optional(),
	status: alertStatusEnum.optional().default('active'),
	categories: z.array(z.string()).optional(),
	orderIds: z.array(z.string()).optional(),
	machineIds: z.array(z.string()).optional(),
})

const Route = createFileRoute('/alerts')({
	validateSearch: zodValidator(alertsSearchSchema),
	component: AlertsComponent,
	onEnter: () => {
		void store.dispatch(validatePlan())
	},
})

function AlertsComponent() {
	const search = Route.useSearch()
	const navigate = useNavigate({ from: Route.fullPath })
	const dispatch = useAppDispatch()
	const machines = useAppSelector(selectAllMachines)
	const ordersWithAlerts = useAppSelector(selectOrdersWithAlerts)

	// Get alerts for each selected status
	const activeStatusAlertsFilter = React.useMemo(
		() => ({
			status: 'active' as const,
			categories: [],
			machineIds: search.machineIds || [],
			orderIds: search.orderIds || [],
		}),
		[search.machineIds, search.orderIds],
	)
	const activeStatusAlerts = useAppSelector(state =>
		selectFilteredAlerts(state, activeStatusAlertsFilter),
	)

	const ignoredStatusAlertsFilter = React.useMemo(
		() => ({
			status: 'ignored' as const,
			categories: [],
			machineIds: search.machineIds || [],
			orderIds: search.orderIds || [],
		}),
		[search.machineIds, search.orderIds],
	)
	const ignoredStatusAlerts = useAppSelector(state =>
		selectFilteredAlerts(state, ignoredStatusAlertsFilter),
	)

	// Combine alerts based on selected status
	const allCategoriesAlerts = search.status
		? search.status === 'active'
			? activeStatusAlerts
			: ignoredStatusAlerts
		: activeStatusAlerts

	// Get filtered alerts for each selected status
	const activeFilteredAlertsFilter = React.useMemo(
		() => ({
			status: 'active' as const,
			categories: search.categories?.includes('transition')
				? [
						'transition-hard-linked',
						'transition-soft-linked',
						...search.categories.filter(c => c !== 'transition'),
					]
				: search.categories || [],
			machineIds: search.machineIds || [],
			orderIds: search.orderIds || [],
		}),
		[search.categories, search.machineIds, search.orderIds],
	)
	const activeFilteredAlerts = useAppSelector(state =>
		selectFilteredAlerts(state, activeFilteredAlertsFilter),
	)

	const ignoredFilteredAlertsFilter = React.useMemo(
		() => ({
			status: 'ignored' as const,
			categories: search.categories?.includes('transition')
				? [
						'transition-hard-linked',
						'transition-soft-linked',
						...search.categories.filter(c => c !== 'transition'),
					]
				: search.categories || [],
			machineIds: search.machineIds || [],
			orderIds: search.orderIds || [],
		}),
		[search.categories, search.machineIds, search.orderIds],
	)
	const ignoredFilteredAlerts = useAppSelector(state =>
		selectFilteredAlerts(state, ignoredFilteredAlertsFilter),
	)

	// Combine filtered alerts based on selected status
	const alerts = search.status
		? search.status === 'active'
			? activeFilteredAlerts
			: ignoredFilteredAlerts
		: activeFilteredAlerts

	const hasIgnoredAlerts = ignoredStatusAlerts.length > 0
	const hasActiveAlerts = activeStatusAlerts.length > 0
	const isFiltered =
		search.status !== 'active' ||
		search.categories?.length ||
		search.orderIds?.length ||
		search.machineIds?.length

	function onIgnoreAlert(alert: TPlanningAlert) {
		dispatch(
			ignoreAlert({
				date: new Date().toISOString(),
				hash: alert.hash,
			}),
		)
	}

	function onActivateAlert(alert: TPlanningAlert) {
		dispatch(activateAlert(alert.hash))
	}

	return (
		<PageLayout>
			<ScrollableToolbar>
				<ScrollableToolbarContentLeft>
					<div className="flex items-center gap-2">
						<h1 className="text-xl sm:text-2xl">Alert Center</h1>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button variant="ghost" size="icon">
									<MoreVertical />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="start">
								<DropdownMenuLabel>
									Filtered Alerts
									<Badge className="ml-2" variant="outline">
										{alerts.length}
									</Badge>
								</DropdownMenuLabel>
								<DropdownMenuSeparator />
								<DropdownMenuGroup>
									<DropdownMenuItem
										disabled={!hasIgnoredAlerts}
										onClick={() => {
											ignoredStatusAlerts.forEach(alert =>
												dispatch(activateAlert(alert.hash)),
											)
										}}
									>
										<Bell />
										<span>Reactivate All</span>
									</DropdownMenuItem>
									<DropdownMenuItem
										disabled={!hasActiveAlerts}
										onClick={() => {
											activeStatusAlerts.forEach(alert =>
												dispatch(
													ignoreAlert({
														date: new Date().toISOString(),
														hash: alert.hash,
													}),
												),
											)
										}}
									>
										<BellOff />
										<span>Ignore All</span>
									</DropdownMenuItem>
								</DropdownMenuGroup>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				</ScrollableToolbarContentLeft>
				<ScrollableToolbarContentRight>
					{isFiltered && (
						<Button
							variant="ghost"
							onClick={() => {
								void navigate({
									search: old => ({
										...old,
										orderIds: undefined,
										machineIds: undefined,
										status: undefined,
										categories: undefined,
									}),
								})
							}}
							className="h-8 px-2 lg:px-3"
						>
							Reset
							<X />
						</Button>
					)}
					<Select
						value={search.status || 'active'}
						onValueChange={value => {
							void navigate({
								search: old => ({
									...old,
									status: value as z.infer<typeof alertStatusEnum>,
								}),
							})
						}}
					>
						<SelectTrigger className="w-[140px]">
							<SelectValue placeholder="Select status" />
						</SelectTrigger>
						<SelectContent>
							{alertStatusOptions.map(option => {
								const Icon = option.icon
								return (
									<SelectItem key={option.value} value={option.value}>
										<Icon />
										<span>{option.label}</span>
									</SelectItem>
								)
							})}
						</SelectContent>
					</Select>
					<MultiSelectFilter
						align="end"
						title="Category"
						options={[
							{
								label: 'Early',
								value: 'early',
								icon: () => <AlertIcon category="early" />,
								facets: allCategoriesAlerts.filter(a => a.category === 'early')
									.length,
							},
							{
								label: 'Late',
								value: 'late',
								icon: () => <AlertIcon category="late" />,
								facets: allCategoriesAlerts.filter(a => a.category === 'late')
									.length,
							},
							{
								label: 'Overlap',
								value: 'overlap',
								icon: () => <AlertIcon category="overlap" />,
								facets: allCategoriesAlerts.filter(
									a => a.category === 'overlap',
								).length,
							},
							{
								label: 'Transition',
								value: 'transition',
								icon: () => <AlertIcon category="transition-soft-linked" />,
								facets: allCategoriesAlerts.filter(
									a =>
										a.category === 'transition-soft-linked' ||
										a.category === 'transition-hard-linked',
								).length,
							},
							{
								label: 'Tool Overlap',
								value: 'tool-overlap',
								icon: () => <AlertIcon category="tool-overlap" />,
								facets: allCategoriesAlerts.filter(
									a => a.category === 'tool-overlap',
								).length,
							},
						]}
						selected={search.categories || []}
						onChange={selectedCategories => {
							void navigate({
								search: old => ({
									...old,
									categories:
										selectedCategories.length > 0
											? selectedCategories
											: undefined,
								}),
							})
						}}
					/>
					<MultiSelectFilter
						align="end"
						title="Machine"
						options={machines.map(machine => ({
							value: machine.id,
							label: machine.name,
						}))}
						selected={search.machineIds || []}
						onChange={selectedIds => {
							void navigate({
								search: old => ({
									...old,
									machineIds: selectedIds.length > 0 ? selectedIds : undefined,
								}),
							})
						}}
					/>
					<MultiSelectFilter
						align="end"
						title="Order"
						options={ordersWithAlerts.map(order => ({
							value: order.id,
							label: order.productionOrderNumber,
						}))}
						selected={search.orderIds || []}
						onChange={selectedIds => {
							void navigate({
								search: old => ({
									...old,
									orderIds: selectedIds.length > 0 ? selectedIds : undefined,
								}),
							})
						}}
					/>
					<Tabs value="grid">
						<TabsList aria-label="View options">
							<Tooltip>
								<TooltipTrigger>
									<TabsTrigger
										value="grid"
										aria-label="Switch to grid view"
										asChild
									>
										<Link to="." search={old => ({ ...old, view: 'grid' })}>
											<LayoutGrid className="size-4" />
										</Link>
									</TabsTrigger>
								</TooltipTrigger>
								<TooltipContent>
									<p>Grid View</p>
								</TooltipContent>
							</Tooltip>
						</TabsList>
					</Tabs>
				</ScrollableToolbarContentRight>
			</ScrollableToolbar>
			<div className="mt-4 py-8">
				<div className="grid gap-2">
					<div className="grid w-full gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
						{alerts.length === 0 && (
							<div className="text-muted-foreground col-span-full text-center">
								No alerts found.
							</div>
						)}
						{alerts.map(alert => (
							<AlertCard
								key={alert.id}
								alert={alert}
								onShowInPlan={() => {
									dispatch(setZoomLevel('30d'))
									void navigate({
										to: '/planning',
										search: {
											alertId: alert.id,
										},
									})
								}}
								onIgnore={
									alert.ignored
										? undefined
										: () => {
												onIgnoreAlert(alert)
											}
								}
								onActivate={
									alert.ignored
										? () => {
												onActivateAlert(alert)
											}
										: undefined
								}
							/>
						))}
					</div>
				</div>
			</div>
			<Outlet />
		</PageLayout>
	)
}

export { Route }
