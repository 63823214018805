import { createAsyncThunk } from '@reduxjs/toolkit'
import {
	TOrder,
	TPlannedOrder,
	TPlanningParameters,
	TProductOperation,
} from '@repo/types'
import { max, min } from 'date-fns'
import { toast } from 'sonner'

import type { RootState } from '@/app/store'
import { selectMachineEntities } from '@/features/machines/machines-slice'
import { getCalendarAdjustmentsForMachine } from '@/features/planning/get-calendar-adjustments-for-machine'
import {
	deleteBooking,
	editBooking,
	selectAllBookings,
	selectCalendarAdjustments,
	validatePlan,
} from '@/features/planning/planning-slice'
import { scheduleOperation } from '@/features/planning/schedule-order'
import { populateProductOperation } from '@/features/products/utils/populate-product-operation'
import { formatNumericString } from '@/utils/format-numeric-string'

import { selectProductOperationEntities } from '../products/product-operations-slice'
import { selectProductById } from '../products/products-slice'
import {
	deleteOrder,
	editOrder,
	ordersSlice,
	selectOrderById,
} from './orders-slice'

export const cascadeDeleteOrder = createAsyncThunk(
	`${ordersSlice.name}/cascadeDeleteOrder`,
	(
		orderId: string,
		{ dispatch, getState, rejectWithValue, fulfillWithValue },
	) => {
		try {
			const state = getState() as RootState
			const order = selectOrderById(state, orderId)

			if (!order) {
				return rejectWithValue('Order not found')
			}

			if ('bookingIds' in order) {
				order.bookingIds.forEach(bookingId => {
					dispatch(deleteBooking(bookingId))
				})
			}

			dispatch(deleteOrder(orderId))

			void dispatch(validatePlan())

			toast.success(`Order #${order.productionOrderNumber} deleted`)

			return fulfillWithValue(orderId)
		} catch {
			return rejectWithValue('Failed to cascade delete order')
		}
	},
)

export const cascadeDeleteOrders = createAsyncThunk(
	`${ordersSlice.name}/cascadeDeleteOrders`,
	async (
		orderIds: string[],
		{ dispatch, getState, rejectWithValue, fulfillWithValue },
	) => {
		try {
			await new Promise(resolve => setTimeout(resolve, 0))
			const state = getState() as RootState
			const orders = orderIds
				.map(id => selectOrderById(state, id))
				.filter((order): order is TOrder => Boolean(order))

			if (orders.length === 0) {
				return rejectWithValue('No valid orders found')
			}

			// Delete all bookings first
			const bookingIds = orders.flatMap(order =>
				'bookingIds' in order ? order.bookingIds : [],
			)
			bookingIds.forEach(bookingId => {
				dispatch(deleteBooking(bookingId))
			})

			// Then delete all orders
			orders.forEach(order => {
				dispatch(deleteOrder(order.id))
			})

			void dispatch(validatePlan())

			// Show success message with count
			if (orders.length === 1) {
				toast.success(`Deleted order #${orders[0].productionOrderNumber}`)
			} else {
				toast.success(`Deleted ${formatNumericString(orders.length)} orders`)
			}

			return fulfillWithValue(orderIds)
		} catch {
			return rejectWithValue('Failed to cascade delete orders')
		}
	},
)

export const updateOrderQuantity = createAsyncThunk(
	`${ordersSlice.name}/updateOrderQuantity`,
	(
		args: { id: string; quantity: number },
		{ dispatch, getState, rejectWithValue },
	) => {
		try {
			const state = getState() as RootState
			const order = selectOrderById(state, args.id)
			const operations = selectProductOperationEntities(state)
			const product = selectProductById(state, order.product.id)
			const machines = selectMachineEntities(state)
			const calendarAdjustments = selectCalendarAdjustments(state)
			const bookings = selectAllBookings(state)

			if (!order) {
				return rejectWithValue('Order not found')
			}

			// Update the order quantity first
			dispatch(
				editOrder({
					id: args.id,
					changes: { quantity: args.quantity },
				}),
			)

			// If the order is planned, we need to update its bookings
			if (order.status === 'planned') {
				const planningParameters: TPlanningParameters = {
					orderId: order.id,
					productId: order.product.id,
					quantity: args.quantity,
					earliestStartDate: order.dueDate,
					dueDate: order.dueDate,
					buffer: order.buffer,
					operations: product.operations
						.map(operation => operations[operation.id])
						.filter((o): o is TProductOperation => Boolean(o))
						.map(o => {
							const { phases, staffGroups, tools, transition } =
								populateProductOperation(o, machines)
							return {
								id: o.id,
								phases,
								staffGroups,
								tools,
								transition,
								machines: phases.during.map(phase => ({
									id: phase.machine.id,
									availability: phase.machine.availability,
								})),
							}
						}),
				}

				// For each booking of the order, reschedule it with the new duration
				for (const bookingId of order.bookingIds) {
					const booking = bookings.find(b => b.id === bookingId)
					if (!booking) continue

					const operation = planningParameters.operations.find(
						o => o.id === booking.operationId,
					)
					if (!operation) continue

					const machine = machines[booking.machineId]
					if (!machine) continue

					// Schedule the operation with the new quantity
					const updatedSchedule = scheduleOperation({
						phases: operation.phases,
						planningParameters: {
							...planningParameters,
							earliestStartDate: booking.startDate,
							dueDate: order.dueDate,
						},
						machine,
						calendarAdjustments: getCalendarAdjustmentsForMachine({
							calendarAdjustments,
							machineId: booking.machineId,
						}),
						bookings: [],
						tools: booking.tools,
						direction: 'forward',
					})

					if (updatedSchedule) {
						dispatch(
							editBooking({
								...updatedSchedule,
								id: booking.id,
							}),
						)
					}
				}

				// Update the order's planned period based on the new booking times
				const updatedBookings = bookings.filter(b =>
					order.bookingIds.includes(b.id),
				)
				if (updatedBookings.length > 0) {
					const plannedPeriod = {
						startDate: min(updatedBookings.map(b => b.startDate)).toISOString(),
						endDate: max(updatedBookings.map(b => b.endDate)).toISOString(),
					}
					dispatch(
						editOrder({
							id: args.id,
							changes: { plannedPeriod } as Partial<TPlannedOrder>,
						}),
					)
				}
			}

			void dispatch(validatePlan())

			return args
		} catch {
			return rejectWithValue('Failed to update order quantity')
		}
	},
)
