import { useState } from 'react'

import * as Sentry from '@sentry/react'
import { RefreshCw } from 'lucide-react'
import { useRegisterSW } from 'virtual:pwa-register/react'

import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'

const TEN_MINUTES = 1000 * 60 * 10

function UpdatePrompt() {
	const [isUpdating, setIsUpdating] = useState(false)
	const {
		needRefresh: [needRefresh, setNeedRefresh],
		updateServiceWorker,
	} = useRegisterSW({
		// onRegisteredSW has replaced onRegistered which is deprecated
		onRegisteredSW(swScriptUrl, registration) {
			if (registration) {
				async function checkForUpdates(
					registration: ServiceWorkerRegistration,
				) {
					if (registration.installing || !navigator) return

					if ('connection' in navigator && !navigator.onLine) return

					const response = await fetch(swScriptUrl, {
						cache: 'no-store',
						headers: {
							cache: 'no-store',
							'cache-control': 'no-cache',
						},
					})

					if (response?.status === 200) await registration.update()
				}
				setInterval(() => void checkForUpdates(registration), TEN_MINUTES)
			}
		},
		onRegisterError(error) {
			Sentry.captureException(error)
			console.error('SW registration error', error)
		},
	})

	function updateAndReload() {
		setIsUpdating(true)
		// Unregister the current service worker first to ensure clean reload
		void navigator.serviceWorker
			.getRegistration()
			.then(registration => {
				if (registration) {
					// Wait for the service worker to finish updating
					updateServiceWorker()
						.then(() => {
							console.log('SW updated, reloading...')
							// Add a small delay before reloading to ensure the SW has activated
							setTimeout(() => {
								window.location.reload()
							}, 1000)
						})
						.catch(error => {
							console.error('SW update error', error)
							setIsUpdating(false)
						})
				} else {
					// If no registration found, just reload
					window.location.reload()
				}
			})
			.catch(() => {
				setIsUpdating(false)
			})
	}

	function close() {
		setNeedRefresh(false)
	}

	if (!needRefresh) return null

	return (
		<div className="fixed bottom-10 z-[9999] w-full max-w-svw px-4 sm:right-4 sm:w-sm">
			<Alert>
				<RefreshCw className="h-4 w-4 animate-spin [animation-duration:3s]" />
				<AlertTitle>Update Available</AlertTitle>
				<AlertDescription>
					New version available. Refresh the page to update.
					<div className="mt-2 flex gap-2">
						<Button size="sm" onClick={updateAndReload} disabled={isUpdating}>
							{isUpdating ? (
								<>
									<RefreshCw className="animate-spin" />
									Updating...
								</>
							) : (
								'Update'
							)}
						</Button>
						<Button
							size="sm"
							variant="outline"
							onClick={close}
							disabled={isUpdating}
						>
							Later
						</Button>
					</div>
				</AlertDescription>
			</Alert>
		</div>
	)
}

export default UpdatePrompt
