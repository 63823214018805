import {
	TCompletedOrder,
	TInProgressOrder,
	TOrder,
	TPendingOrder,
	TPlannedOrder,
} from '@repo/types'

export function isActiveOrder(
	order: TOrder,
): order is TPendingOrder | TPlannedOrder | TInProgressOrder {
	return ['pending', 'planned', 'in-progress'].includes(order.status)
}

export function isProcessedOrder(
	order: TOrder,
): order is TPlannedOrder | TInProgressOrder | TCompletedOrder {
	return ['planned', 'in-progress', 'completed'].includes(order.status)
}
