import { createId } from '@paralleldrive/cuid2'
import {
	createEntityAdapter,
	createSelector,
	createSlice,
	EntityState,
} from '@reduxjs/toolkit'
import type { PayloadAction, Update } from '@reduxjs/toolkit'
import { TProduct, TProductOperation } from '@repo/types'
import { PURGE } from 'redux-persist'

import type { RootState } from '@/app/store'
import { isActiveOrder } from '@/features/orders/lib/order-helpers'
import { selectAllOrders } from '@/features/orders/orders-slice'

import { selectMachineEntities } from '../machines/machines-slice'
import { selectProductOperationEntities } from './product-operations-slice'
import { populateProductOperation } from './utils/populate-product-operation'

export type TPopulatedProduct = TProduct<TProductOperation> & {
	orderCounts: { total: number; active: number }
}

const productsAdapter = createEntityAdapter<TProduct>()

export const {
	selectIds: selectProductIds,
	selectEntities: selectProductEntities,
	selectAll: selectAllProducts,
	selectTotal: selectTotalProducts,
	selectById: selectProductById,
} = productsAdapter.getSelectors<RootState>(state => state.products)

export type ProductsState = EntityState<TProduct, string>

const initialState: ProductsState = productsAdapter.getInitialState()

export const productsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		createProduct: {
			reducer: (state, action: PayloadAction<TProduct>) => {
				productsAdapter.addOne(state, action.payload)
			},
			prepare: (productData: Omit<TProduct, 'id'>, id: string = createId()) => {
				return { payload: { ...productData, id } }
			},
		},
		editProduct: (state, action: PayloadAction<Update<TProduct, string>>) => {
			productsAdapter.updateOne(state, action.payload)
		},
		deleteProduct: (state, action: PayloadAction<string>) => {
			productsAdapter.removeOne(state, action.payload)
		},
		reorderProduct: (
			state,
			action: PayloadAction<{ oldIndex: number; newIndex: number }>,
		) => {
			const { oldIndex, newIndex } = action.payload
			const ids = [...state.ids] as string[]
			const [removed] = ids.splice(oldIndex, 1)
			ids.splice(newIndex, 0, removed)
			state.ids = ids
		},
	},
	extraReducers: builder => {
		builder.addCase(PURGE, state => {
			productsAdapter.removeAll(state)
		})
	},
})

export const { createProduct, editProduct, deleteProduct, reorderProduct } =
	productsSlice.actions

export const selectPopulatedProduct = createSelector(
	[selectProductById, selectProductOperationEntities, selectMachineEntities],
	(product, productOperationEntities, machineEntities) => {
		if (!product) {
			console.warn(
				`Product not found. This may indicate a data inconsistency or deleted product reference.`,
			)
			return undefined
		}
		return {
			...product,
			operations: product.operations
				.map(operation => productOperationEntities[operation.id])
				.filter((o): o is TProductOperation => Boolean(o))
				.map(operation => populateProductOperation(operation, machineEntities)),
		}
	},
)

export const selectPopulatedProducts = createSelector(
	[
		selectAllProducts,
		selectProductOperationEntities,
		selectMachineEntities,
		selectAllOrders,
	],
	(
		products,
		productOperationEntities,
		machineEntities,
		orders,
	): TPopulatedProduct[] => {
		const orderCountsByProductId = orders.reduce(
			(acc, order) => {
				if (!acc[order.product.id]) {
					acc[order.product.id] = { total: 0, active: 0 }
				}
				acc[order.product.id].total++
				if (isActiveOrder(order)) {
					acc[order.product.id].active++
				}
				return acc
			},
			{} as Record<string, { total: number; active: number }>,
		)

		return products.map(product => ({
			...product,
			operations: product.operations
				.map(operation => productOperationEntities[operation.id])
				.filter((o): o is TProductOperation => Boolean(o))
				.map(operation => populateProductOperation(operation, machineEntities)),
			orderCounts: orderCountsByProductId[product.id] ?? {
				total: 0,
				active: 0,
			},
		}))
	},
)

export const selectProductNames = createSelector(
	[selectAllProducts],
	products => products.map(product => product.name),
)

export const selectProductNumbers = createSelector(
	[selectAllProducts],
	products => products.map(product => product.productNumber),
)

export default productsSlice.reducer
