'use client'

import * as React from 'react'

import { useCommandState } from 'cmdk'
import { CheckIcon, ChevronsUpDown, PlusCircleIcon } from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/ui/command'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'

type Option = {
	value: string
	label: string
	keywords?: string[]
}

type CreateOptionProps = {
	search: string
	onSelect: (value: string) => void
}

function CreateOption({ search, onSelect }: CreateOptionProps) {
	const results = useCommandState(state => state.filtered.count)

	return (
		<>
			{results > 1 && <CommandSeparator alwaysRender />}
			<CommandGroup forceMount>
				<CommandItem
					value={search}
					onSelect={onSelect}
					className="cursor-pointer"
				>
					<PlusCircleIcon />
					<span className="truncate">
						Create <span className="font-medium">&quot;{search}&quot;</span>
					</span>
				</CommandItem>
			</CommandGroup>
		</>
	)
}

function hasExactMatch(search: string, options: Option[]): boolean {
	if (search.trim() === '') return false

	return options.some(
		option =>
			option.value.toLowerCase() === search.toLowerCase() ||
			option.label.toLowerCase() === search.toLowerCase(),
	)
}

type ComboboxProps = {
	initialValue?: string
	options: Option[]
	searchPlaceholder: string
	valuePlaceholder: string
	allowCreate?: boolean
	classNameContent?: string
	align?: 'start' | 'center' | 'end'
	container?: HTMLElement | null
	onSelect?: (value: string) => void
	getOptionValue?: (option: Option) => string
}

function Combobox({
	initialValue = '',
	options,
	searchPlaceholder,
	valuePlaceholder,
	allowCreate,
	classNameContent,
	className,
	align = 'start',
	container,
	onSelect,
	getOptionValue,
	...props
}: ComboboxProps & Omit<React.ComponentProps<'button'>, 'onSelect'>) {
	const [open, setOpen] = React.useState(false)
	const [value, setValue] = React.useState(initialValue)
	const [search, setSearch] = React.useState('')
	const [createdOption, setCreatedOption] = React.useState<Option | null>(null)
	const listRef = React.useRef<HTMLDivElement>(null)

	// Custom: Force scroll to the top of the list when the search query changes
	React.useEffect(() => {
		requestAnimationFrame(() => {
			if (listRef.current) {
				listRef.current.scrollTop = 0
			}
		})
	}, [search])

	const allOptions = createdOption ? [createdOption, ...options] : options

	const showCreateOption =
		allowCreate && search.trim() !== '' && !hasExactMatch(search, allOptions)

	const handleSelect = (option: Option) => {
		const newOption = option.value === value ? null : option
		const newValue = newOption ? newOption.value : ''
		setValue(newValue)
		setSearch('')
		setOpen(false)
		onSelect?.(newValue)
	}

	const handleCreateOption = (createdValue: string) => {
		const name = createdValue.trim()
		const option = {
			id: name,
			value: name,
			label: name,
		}
		setCreatedOption(option)
		handleSelect(option)
	}

	return (
		<Popover open={open} onOpenChange={setOpen} modal>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className={cn('w-full justify-between md:max-w-[200px]', className)}
					{...props}
				>
					<span className="truncate">
						{value
							? allOptions.find(option => option.value === value)?.label
							: valuePlaceholder}
					</span>
					<ChevronsUpDown className="text-muted-foreground" />
				</Button>
			</PopoverTrigger>
			<PopoverContent
				className={cn(
					'max-h-(--radix-popover-content-available-height) w-(--radix-popover-trigger-width) p-0',
					classNameContent,
				)}
				align={align}
				container={container}
			>
				<Command>
					<CommandInput
						placeholder={searchPlaceholder}
						value={search}
						onValueChange={setSearch}
					/>
					<CommandList ref={listRef}>
						{!allowCreate && <CommandEmpty>No results found.</CommandEmpty>}

						<CommandGroup>
							{allOptions.map(option => (
								<CommandItem
									key={option.value}
									value={getOptionValue?.(option) ?? option.value}
									keywords={option.keywords}
									onSelect={() => handleSelect(option)}
								>
									{option.label}
									<CheckIcon
										className={cn(
											'ml-auto',
											value === option.value ? 'opacity-100' : 'opacity-0',
										)}
									/>
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
					{showCreateOption && (
						<CreateOption search={search} onSelect={handleCreateOption} />
					)}
				</Command>
			</PopoverContent>
		</Popover>
	)
}

export { Combobox }
