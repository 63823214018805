import {
	createContext,
	ReactNode,
	use,
	useEffect,
	useRef,
	useState,
} from 'react'

import * as Sentry from '@sentry/react'

type FullScreenState = {
	isFullScreenSupported: boolean
	isFullScreen: boolean
	toggleFullScreen: () => void
	fullScreenRef: React.Ref<HTMLDivElement>
	fullScreenContainer: HTMLElement | null
}

const FullScreenStateContext = createContext<FullScreenState | undefined>(
	undefined,
)

function FullScreenProvider({ children }: { children: ReactNode }) {
	const [isFullScreen, setIsFullScreen] = useState(false)
	const fullScreenRef = useRef<HTMLDivElement>(null)
	const isFullScreenSupported = Boolean(document.fullscreenEnabled)
	const [fullScreenContainer, setFullScreenContainer] =
		useState<HTMLElement | null>(null)

	function toggleFullScreen() {
		if (!document.fullscreenElement) {
			void fullScreenRef.current?.requestFullscreen().catch(err => {
				Sentry.captureException(err)
				console.error('Error attempting to enable fullscreen:', err)
			})
		} else if (document.exitFullscreen) {
			void document.exitFullscreen().catch(err => {
				Sentry.captureException(err)
				console.error('Error attempting to exit fullscreen:', err)
			})
		}
	}

	useEffect(() => {
		const syncFullScreenStatus = () => {
			setIsFullScreen(Boolean(document.fullscreenElement))
		}

		syncFullScreenStatus()
		document.addEventListener('fullscreenchange', syncFullScreenStatus)
		return () => {
			document.removeEventListener('fullscreenchange', syncFullScreenStatus)
		}
	}, [])

	useEffect(() => {
		setFullScreenContainer(isFullScreen ? fullScreenRef.current : null)
	}, [isFullScreen])

	const value: FullScreenState = {
		isFullScreenSupported,
		isFullScreen,
		toggleFullScreen,
		fullScreenRef,
		fullScreenContainer,
	}

	return (
		<FullScreenStateContext value={value}>{children}</FullScreenStateContext>
	)
}

function useFullScreen() {
	const context = use(FullScreenStateContext)

	if (context === undefined) {
		throw new Error(
			`${useFullScreen.name} must be used within a ${FullScreenProvider.name}`,
		)
	}
	return context
}

export { useFullScreen, FullScreenProvider }
