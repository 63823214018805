import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
	TMachine,
	TMachineBooking,
	TPendingOrder,
	TPlannedOrder,
	TSchedulingDirection,
} from '@repo/types'
import { createFileRoute, Outlet, useNavigate } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'
import {
	addHours,
	addWeeks,
	format,
	startOfDay,
	startOfWeek,
	subWeeks,
} from 'date-fns'
import throttle from 'lodash.throttle'
import {
	ArrowLeftFromLine,
	ArrowRightFromLine,
	ArrowRightIcon,
	Bell,
	CalendarIcon,
	CheckCircleIcon,
	ChevronLeft,
	ChevronRight,
	CircleHelp,
	Eraser,
	Maximize,
	Minimize,
	MoreVertical,
	RotateCcw,
	TimerIcon,
	Undo,
	Users2,
	X,
} from 'lucide-react'
import { useHotkeys } from 'react-hotkeys-hook'
import { toast } from 'sonner'
import { z } from 'zod'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { store } from '@/app/store'
import { GenericAlertDialog } from '@/components/generic-alert-dialog'
import { GenericDialog } from '@/components/generic-dialog'
import { PageLayout } from '@/components/layout'
import {
	ScrollableToolbar,
	ScrollableToolbarContentLeft,
	ScrollableToolbarContentRight,
} from '@/components/scrollable-toolbar'
import { Badge } from '@/components/ui/badge'
import { Button, buttonVariants } from '@/components/ui/button'
import { CommandShortcut } from '@/components/ui/command'
import { DialogFooter } from '@/components/ui/dialog'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { MultiSelectFilter } from '@/components/ui/multi-select-filter'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/ui/tooltip'
import { Gantt } from '@/features/gantt/components/gantt'
import { usePersistentOrderedList } from '@/features/gantt/components/sortable-machine-list'
import { useContextualZoom } from '@/features/gantt/hooks/use-contextual-zoom'
import { useDeselect } from '@/features/gantt/hooks/use-deselect'
import { selectAllMachines } from '@/features/machines/machines-slice'
import { selectAllOperators } from '@/features/operators/operators-slice'
import { isProcessedOrder } from '@/features/orders/lib/order-helpers'
import {
	selectCategorizedOrders,
	selectOrderEntities,
	selectSortedOrders,
} from '@/features/orders/orders-slice'
import { AlignBookingsDialog } from '@/features/planning/components/align-bookings-dialog'
import { ChangeStartDateDialog } from '@/features/planning/components/change-start-date-dialog'
import { useFullScreen } from '@/features/planning/context/full-screen-provider'
import { useOrderPlanner } from '@/features/planning/hooks/use-order-planner'
import {
	activateAlert,
	ignoreAlert,
	resetProgress,
	selectAllBookings,
	selectBookingsByOrderIds,
	selectCalendarAdjustments,
	selectFilteredAlerts,
	selectProgressedBookings,
	selectSchedulingDirection,
	selectZoomLevel,
	setSchedulingDirection,
	setZoomLevel,
	validatePlan,
	ZoomLevel,
} from '@/features/planning/planning-slice'
import { selectProductEntities } from '@/features/products/products-slice'
import { UpdateProgressDialog } from '@/features/progress/components/update-progress-dialog'
import { TPlanningAlert } from '@/features/validation/types'
import { useDialogState } from '@/hooks/use-dialog-state'
import { useHotkeyLabel } from '@/hooks/use-hotkey-label'
import { useSyncedPersistedState } from '@/hooks/use-synced-persisted-state'
import { cn } from '@/lib/utils'
import { formatNumericString } from '@/utils/format-numeric-string'

type ViewOption = {
	key: keyof ViewOptions
	icon: React.ComponentType<{ className?: string }>
	label: string
	title: string
	description: string
}

type ViewOptions = {
	showAlerts: boolean
	showOperators: boolean
}

const viewOptions: ViewOption[] = [
	{
		key: 'showAlerts',
		icon: Bell,
		label: 'Alerts',
		title: 'Planning Alerts',
		description: 'Show scheduling conflicts and warnings',
	},
	{
		key: 'showOperators',
		icon: Users2,
		label: 'Operators',
		title: 'Operator Assignments',
		description: 'Show assigned staff for each booking',
	},
]

const Route = createFileRoute('/planning')({
	component: PlanningComponent,
	validateSearch: zodValidator(
		z.object({
			bookingId: z.string().optional(),
			orderId: z.string().optional(),
			alertId: z.string().optional(),
			showAlerts: z.boolean().optional(),
			showOperators: z.boolean().optional(),
			orderIds: z.array(z.string()).optional(),
		}),
	),
	onEnter: () => {
		void store.dispatch(validatePlan())
	},
})

type ZoomLevelSettings = {
	zoomLevel: string
	startDate: Date
	endDate: Date
	hourGap: number
	rowHeight: number
	rowLabelWidth: number
	rowLabelMarginLeft: number
	focalPointPercentage: number
	xGridLinesStep: number
	xLabelsStep: number
}

function applyZoomLevel(zoomLevel: ZoomLevel): ZoomLevelSettings {
	const windowWidth = window.innerWidth
	const rowLabelWidth = Math.min(250, windowWidth * 0.35)
	const startOfCurrentWeek = startOfWeek(startOfDay(new Date()), {
		weekStartsOn: 1,
	})
	const widthFactor = 1 - rowLabelWidth / windowWidth - 0.02
	const hoursInDay = 24

	// TODO: Add infinite scroll, so that the past and future weeks are not fixed and only a limited number of weeks are rendered at a time
	// TODO: Use URL params to store the zoom level and the current center view date from where to render the Gantt chart. Based on the zoom level, the number of past and future weeks to render should be calculated.
	const settingsMap = {
		'12h': {
			pastWeeks: 2,
			futureWeeks: 10,
			daysInView: 0.5,
			xGridStep: 1, // Grid lines every hour
			xLabelsStep: 1,
		},
		'24h': {
			pastWeeks: 3,
			futureWeeks: 10,
			daysInView: 1,
			xGridStep: 1, // Grid lines every hour
			xLabelsStep: 1,
		},
		'3d': {
			pastWeeks: 4,
			futureWeeks: 14,
			daysInView: 3,
			xGridStep: 3, // Grid lines every 3 hours
			xLabelsStep: 3,
		},
		'5d': {
			pastWeeks: 7,
			futureWeeks: 14,
			daysInView: 5,
			xGridStep: 6, // Grid lines every 6 hours
			xLabelsStep: 6,
		},
		'14d': {
			pastWeeks: 14,
			futureWeeks: 53,
			daysInView: 15,
			xGridStep: hoursInDay, // Grid lines every day
			xLabelsStep: 12,
		},
		'30d': {
			pastWeeks: 26,
			futureWeeks: 53,
			daysInView: 31,
			xGridStep: hoursInDay, // Grid lines every day
			xLabelsStep: 24,
		},
		'90d': {
			pastWeeks: 26,
			futureWeeks: 53,
			daysInView: 92,
			xGridStep: hoursInDay, // Grid lines every day
			xLabelsStep: 24,
		},
	} as const

	const setting = settingsMap[zoomLevel]
	const hourGap =
		(windowWidth / (hoursInDay * setting.daysInView)) * widthFactor

	return {
		zoomLevel,
		startDate: subWeeks(startOfCurrentWeek, setting.pastWeeks),
		endDate: addHours(addWeeks(startOfCurrentWeek, setting.futureWeeks), 8),
		hourGap,
		rowHeight: 40,
		rowLabelWidth,
		rowLabelMarginLeft: 8,
		focalPointPercentage: 0.1,
		xGridLinesStep: setting.xGridStep,
		xLabelsStep: setting.xLabelsStep,
	}
}

const zoomLevels: ZoomLevel[] = ['12h', '24h', '3d', '5d', '14d', '30d', '90d']

function PlanningComponent() {
	const search = Route.useSearch()
	const navigate = useNavigate({ from: Route.fullPath })
	const [activeBookingId, setActiveBookingId] = useState<string | undefined>(
		search.bookingId,
	)
	const [activeOrderId, setActiveOrderId] = useState<string | undefined>(
		search.orderId,
	)
	const dispatch = useAppDispatch()
	const machines = useAppSelector(selectAllMachines)
	const operators = useAppSelector(selectAllOperators)
	const {
		orderedList: orderedMachines,
		reorder: reorderMachines,
		isHidden,
		toggleVisibility,
	} = usePersistentOrderedList(machines, 'orderedMachines')
	const productEntities = useAppSelector(selectProductEntities)
	const bookings = useAppSelector(selectAllBookings)

	const alertsFilter = useMemo(
		() => ({
			orderIds: search.orderIds || [],
		}),
		[search.orderIds],
	)
	const alerts = useAppSelector(state =>
		selectFilteredAlerts(state, alertsFilter),
	)
	const schedulingDirection = useAppSelector(selectSchedulingDirection)
	const zoomLevel = useAppSelector(selectZoomLevel)
	const [resizeVersion, setResizeVersion] = useState(0)
	const [canMoveBookingsRaw, setCanMoveBookings] = useState<
		boolean | undefined
	>(undefined)

	const canMoveBookings = useSyncedPersistedState<boolean>(
		'planning.canMoveBookings',
		{
			value: canMoveBookingsRaw,
			defaultValue: true,
		},
	)

	const showAlerts = useSyncedPersistedState<boolean>('planning.showAlerts', {
		value: search.showAlerts,
		defaultValue: true,
	})

	const showOperators = useSyncedPersistedState<boolean>(
		'planning.showOperators',
		{
			value: search.showOperators,
			defaultValue: true,
		},
	)

	const viewStates: ViewOptions = useMemo(
		() => ({
			showAlerts,
			showOperators,
		}),
		[showAlerts, showOperators],
	)

	const handleResize = useMemo(
		() =>
			throttle(() => {
				setResizeVersion(v => v + 1)
			}, 200),
		[],
	)

	useEffect(() => {
		window.addEventListener('resize', handleResize)
		return () => {
			handleResize.cancel()
			window.removeEventListener('resize', handleResize)
		}
	}, [handleResize])

	const zoomLevelSettings = useMemo(
		() => ({ ...applyZoomLevel(zoomLevel), _: resizeVersion }),
		[zoomLevel, resizeVersion],
	)

	const calendarAdjustments = useAppSelector(selectCalendarAdjustments)
	const progressedBookings = useAppSelector(selectProgressedBookings)
	const { pendingOrders, plannedOrders, processedOrders } = useAppSelector(
		selectCategorizedOrders,
	)
	const orderEntities = useAppSelector(selectOrderEntities)
	const allOrders = useAppSelector(selectSortedOrders)
	const {
		isFullScreen,
		fullScreenRef,
		fullScreenContainer,
		isFullScreenSupported,
		toggleFullScreen,
	} = useFullScreen()

	const unscheduleLastOrderDialog = useDialogState()
	const clearAllScheduledOrdersDialog = useDialogState()
	const resetAllProgressDialog = useDialogState()

	const filteredBookingsByOrderIds = useAppSelector(state =>
		selectBookingsByOrderIds(state, search.orderIds),
	)

	const isFiltered = Boolean(search.orderIds?.length)

	// Hotkeys
	const planOrderHotkey = 'mod+shift+1'
	useHotkeys(planOrderHotkey, () => {
		if (pendingOrders.length > 0) {
			resetFilter()
			void handlePlanOrder({ order: pendingOrders[0] })
		}
	})
	const planOrderHotkeyLabel = useHotkeyLabel(planOrderHotkey)

	const selectBooking = useCallback(
		(args: { orderId: string; bookingId: string }) => {
			const { orderId, bookingId } = args
			setCurrentFilteredBookingIndex(
				filteredBookingsByOrderIds.findIndex(b => b.id === bookingId),
			)
			setActiveOrderId(orderId)
			setActiveBookingId(bookingId)
		},
		[filteredBookingsByOrderIds],
	)

	const deselectAll = useCallback(() => {
		setActiveOrderId(undefined)
		setActiveBookingId(undefined)
		setCurrentFilteredBookingIndex(-1)
		if (search.alertId || search.bookingId || search.orderId) {
			void navigate({
				search: old => ({
					...old,
					bookingId: undefined,
					orderId: undefined,
					alertId: undefined,
				}),
			})
		}
	}, [navigate, search.alertId, search.orderId, search.bookingId])

	const scrollViewRef = useRef<HTMLDivElement>(null)
	useDeselect({
		deselectRef: scrollViewRef,
		onDeselect: deselectAll,
	})

	const { focusViewOnDate, focusViewOnMachine, createZoomFn } =
		useContextualZoom({
			scrollViewRef: scrollViewRef,
			screen: isFullScreen ? fullScreenContainer : window,
			startDate: zoomLevelSettings.startDate,
			hourGap: zoomLevelSettings.hourGap,
			rowHeight: zoomLevelSettings.rowHeight,
			machines: orderedMachines,
			zoomLevel,
			offsetY: isFullScreen ? 68 : 125,
			stickySectionHeight: 60,
			focalPointPercentage: 0.1,
			reservedSpace: {
				left:
					zoomLevelSettings.rowLabelWidth +
					zoomLevelSettings.rowLabelMarginLeft,
				bottom: 100, // Fixed 100px reserved at the bottom for the toolbar
			},
		})

	const [activeBooking, setActiveBooking] = useState<TMachineBooking | null>(
		null,
	)
	useEffect(() => {
		if (activeBookingId) {
			const booking = bookings.find(booking => booking.id === activeBookingId)
			setActiveBooking(booking ?? null)
		} else {
			setActiveBooking(null)
		}
	}, [activeBookingId, bookings])

	const [isResettingOrderPlan, setIsResettingOrderPlan] = useState(false)
	const { planOrder, resetOrderPlan, moveBooking, switchMachine } =
		useOrderPlanner()

	useEffect(() => {
		if (!search.bookingId) return
		const booking = bookings.find(booking => booking.id === search.bookingId)
		if (booking) {
			focusViewOnDate(new Date(booking.startDate), 0.4)
			focusViewOnMachine(booking.machineId)
		}
	}, [
		search.bookingId,
		bookings,
		processedOrders,
		focusViewOnDate,
		focusViewOnMachine,
	])

	useEffect(() => {
		if (!search.alertId) return
		const alert = alerts.find(alert => alert.id === search.alertId)
		if (!alert) return
		if (alert.bookings[0]) {
			selectBooking({
				orderId: alert.bookings[0].orderId,
				bookingId: alert.bookings[0].id,
			})
			focusViewOnMachine(alert.bookings[0].machineId)
		}
		focusViewOnDate(new Date(alert.startDate), 0.4)
	}, [
		search.alertId,
		alerts,
		selectBooking,
		focusViewOnDate,
		focusViewOnMachine,
	])

	const selectNextBooking = useCallback(
		(bookingId: string) => {
			const booking = bookings.find(booking => booking.id === bookingId)
			if (!booking) return
			const order = processedOrders.find(order => order.id === booking.orderId)
			if (!order) return
			const bookingIds = order.bookingIds
			const currentIndex = bookingIds.findIndex(
				bookingId => booking.id === bookingId,
			)
			if (currentIndex === -1) return
			const nextIndex = currentIndex + 1
			if (nextIndex >= bookingIds.length) return
			const nextBookingId = bookingIds[nextIndex]
			const nextBooking = bookings.find(booking => booking.id === nextBookingId)
			if (!nextBooking) return
			selectBooking({
				orderId: order.id,
				bookingId: nextBookingId,
			})
			focusViewOnDate(new Date(nextBooking.startDate), 0.4)
			focusViewOnMachine(nextBooking.machineId)
		},
		[
			bookings,
			processedOrders,
			selectBooking,
			focusViewOnDate,
			focusViewOnMachine,
		],
	)

	const selectPrevBooking = useCallback(
		(bookingId: string) => {
			const booking = bookings.find(booking => booking.id === bookingId)
			if (!booking) return
			const order = processedOrders.find(order => order.id === booking.orderId)
			if (!order) return
			const bookingIds = order.bookingIds
			const currentIndex = bookingIds.findIndex(
				bookingId => booking.id === bookingId,
			)
			if (currentIndex === -1) return
			const prevIndex = currentIndex - 1
			if (prevIndex < 0) return
			const prevBookingId = bookingIds[prevIndex]
			const prevBooking = bookings.find(booking => booking.id === prevBookingId)
			if (!prevBooking) return
			selectBooking({
				orderId: order.id,
				bookingId: prevBookingId,
			})
			focusViewOnDate(new Date(prevBooking.startDate), 0.4)
			focusViewOnMachine(prevBooking.machineId)
		},
		[
			bookings,
			processedOrders,
			selectBooking,
			focusViewOnDate,
			focusViewOnMachine,
		],
	)

	const [changeBookingStartDateDialogOpen, setChangeBookingStartDateOpen] =
		useState(false)

	function openChangeBookingStartDateDialog() {
		if (activeBooking) {
			setChangeBookingStartDateOpen(true)
		} else {
			toast.error('No valid booking selected')
		}
	}

	function closeChangeBookingStartDateDialog() {
		setChangeBookingStartDateOpen(false)
	}

	const handlePlanOrder = useCallback(
		async (args: {
			order: TPendingOrder
			direction?: TSchedulingDirection
		}) => {
			const { order, direction = schedulingDirection } = args
			const newBookings = await planOrder({ order, direction })

			if (newBookings) {
				const firstBooking = newBookings[0]
				selectBooking({
					orderId: order.id,
					bookingId: firstBooking.id,
				})
				focusViewOnDate(new Date(firstBooking.startDate), 0.4)
				focusViewOnMachine(firstBooking.machineId)
				toast.success(`Planned order #${order.productionOrderNumber}`)
			}
		},
		[
			planOrder,
			selectBooking,
			schedulingDirection,
			focusViewOnDate,
			focusViewOnMachine,
		],
	)

	// TODO: In the future, handle replanning orders from a specific date in the planning slice, so resetting and planning can be done in one step
	const handleReplanOrderFromDate = useCallback(
		(desiredStartDate: Date) => {
			if (!activeBooking) {
				toast.error('No valid booking selected')
				return
			}
			void resetOrderPlan(activeBooking.orderId)
			const order = processedOrders.find(
				order => order.id === activeBooking.orderId,
			)
			if (!order) {
				toast.error('No valid order found')
				return
			}
			void handlePlanOrder({
				order: {
					...order,
					earliestStartDate: desiredStartDate.toISOString(),
					status: 'pending',
				},
				direction: 'forward',
			})
		},
		[activeBooking, handlePlanOrder, processedOrders, resetOrderPlan],
	)

	function handleUnscheduleOrder(order: TPlannedOrder) {
		setIsResettingOrderPlan(true)
		resetFilter()
		const firstBooking = bookings.filter(b => b.orderId === order.id)[0]

		if (firstBooking) {
			selectBooking({
				orderId: firstBooking.orderId,
				bookingId: firstBooking.id,
			})
			focusViewOnDate(new Date(firstBooking.startDate), 0.4)
			focusViewOnMachine(firstBooking.machineId)
		}

		setTimeout(() => {
			void resetOrderPlan(order.id)
			deselectAll()
			setIsResettingOrderPlan(false)
		}, 900)
	}
	function handleUnscheduleOrders(orderIds: string[]) {
		resetFilter()
		orderIds.forEach(orderId => void resetOrderPlan(orderId))
	}

	const [alignBookingsDialogOpen, setAlignBookingsDialogOpen] = useState(false)
	function openAlignBookingsDialog() {
		setAlignBookingsDialogOpen(true)
	}
	function closeAlignBookingsDialog() {
		setAlignBookingsDialogOpen(false)
	}

	const [updateProgressDialogOpen, setUpdateProgressDialogOpen] =
		useState(false)
	function openUpdateProgressDialog() {
		setUpdateProgressDialogOpen(true)
	}
	function closeUpdateProgressDialog() {
		setUpdateProgressDialogOpen(false)
	}
	async function handleResetProgress(targetBookingId: string) {
		return dispatch(resetProgress(targetBookingId)).unwrap()
	}
	async function handleResetAllProgress() {
		for (const booking of progressedBookings) {
			await handleResetProgress(booking.id)
		}
	}

	const [switchBookingMachine, setSwitchBookingMachine] = useState<{
		initialMachineId: string
		compatibleMachines: TMachine[]
		selectedMachineId: string
	} | null>(null)

	function openSwitchBookingMachineDialog() {
		if (!activeBooking) {
			toast.error('No valid booking selected')
			return
		}
		const compatibleMachines = machines.filter(m =>
			activeBooking.compatibleMachines.includes(m.id),
		)
		setSwitchBookingMachine({
			compatibleMachines,
			initialMachineId: activeBooking.machineId,
			selectedMachineId: activeBooking.machineId,
		})
	}
	function closeSwitchBookingMachineDialog() {
		setSwitchBookingMachine(null)
	}
	function handleSwitchMachine(toMachineId: string) {
		if (!activeBookingId) {
			toast.error('No valid booking selected')
			closeSwitchBookingMachineDialog()
			return
		}
		const updatedBooking = switchMachine({
			bookingId: activeBookingId,
			toMachineId,
		})
		if (updatedBooking) {
			selectBooking({
				orderId: updatedBooking.orderId,
				bookingId: updatedBooking.id,
			})
			focusViewOnDate(new Date(updatedBooking.startDate), 0.4)
			focusViewOnMachine(updatedBooking.machineId)
		}
		closeSwitchBookingMachineDialog()
	}

	const onZoomIn =
		zoomLevel === '12h'
			? undefined
			: () => {
					const currentIndex = zoomLevels.indexOf(zoomLevel)
					if (currentIndex > 0) {
						dispatch(setZoomLevel(zoomLevels[currentIndex - 1]))
					}
				}

	const onZoomOut =
		zoomLevel === '90d'
			? undefined
			: () => {
					const currentIndex = zoomLevels.indexOf(zoomLevel)
					if (currentIndex < zoomLevels.length - 1) {
						dispatch(setZoomLevel(zoomLevels[currentIndex + 1]))
					}
				}

	function onIgnoreAlert(alert: TPlanningAlert) {
		dispatch(
			ignoreAlert({
				date: new Date().toISOString(),
				hash: alert.hash,
			}),
		)
	}

	function onActivateAlert(alert: TPlanningAlert) {
		dispatch(activateAlert(alert.hash))
	}

	const handleEditOrder = useCallback(
		(orderId: string) => {
			void navigate({
				search: old => ({
					...old,
					editOrder: orderId,
				}),
			})
		},
		[navigate],
	)

	const toggleViewOption = useCallback(
		(key: keyof ViewOptions) => {
			void navigate({
				search: prev => ({
					...prev,
					[key]: !(prev[key] ?? viewStates[key]),
				}),
			})
		},
		[navigate, viewStates],
	)

	const filteredViewOptions = useMemo(() => {
		return viewOptions.filter(option => {
			if (option.key === 'showOperators') {
				return operators.length > 0
			}
			return true
		})
	}, [operators])

	const [currentFilteredBookingIndex, setCurrentFilteredBookingIndex] =
		useState(-1)

	const resetFilter = useCallback(() => {
		setCurrentFilteredBookingIndex(-1)
		void navigate({
			search: old => ({ ...old, orderIds: undefined }),
		})
	}, [navigate])

	const bookingNavigation = useMemo(() => {
		const goToIndex = (index: number) => {
			if (index >= 0 && index < filteredBookingsByOrderIds.length) {
				const booking = filteredBookingsByOrderIds[index]
				setCurrentFilteredBookingIndex(index)
				selectBooking({ orderId: booking.orderId, bookingId: booking.id })
				focusViewOnDate(new Date(booking.startDate), 0.4)
				focusViewOnMachine(booking.machineId)
			}
		}

		const goToPrev = () => {
			const newIndex =
				currentFilteredBookingIndex <= 0
					? filteredBookingsByOrderIds.length - 1
					: currentFilteredBookingIndex - 1
			goToIndex(newIndex)
		}
		const goToNext = () => {
			const newIndex =
				currentFilteredBookingIndex >= filteredBookingsByOrderIds.length - 1
					? 0
					: currentFilteredBookingIndex + 1
			goToIndex(newIndex)
		}

		return {
			index: currentFilteredBookingIndex,
			total: filteredBookingsByOrderIds.length,
			onPrev: goToPrev,
			onNext: goToNext,
		}
	}, [
		currentFilteredBookingIndex,
		filteredBookingsByOrderIds,
		focusViewOnDate,
		focusViewOnMachine,
		selectBooking,
	])

	return (
		<>
			<div
				ref={fullScreenRef}
				className={cn(
					'flex h-full w-full flex-col',
					isFullScreen && 'bg-background overflow-y-scroll',
				)}
			>
				<PageLayout className="border-b">
					<ScrollableToolbar>
						<ScrollableToolbarContentLeft>
							<div className="flex items-center gap-2">
								<h1 className="text-xl sm:text-2xl">Planning</h1>
								<DropdownMenu>
									<DropdownMenuTrigger asChild>
										<Button variant="ghost" size="icon">
											<MoreVertical />
										</Button>
									</DropdownMenuTrigger>
									<DropdownMenuContent
										align="start"
										container={fullScreenContainer}
									>
										<DropdownMenuItem
											disabled={
												plannedOrders.length === 0 || isResettingOrderPlan
											}
											onClick={unscheduleLastOrderDialog.open}
										>
											<Undo />
											<span>Unschedule Last Order</span>
										</DropdownMenuItem>
										<DropdownMenuSeparator />
										<DropdownMenuGroup>
											<DropdownMenuItem
												disabled={
													plannedOrders.length === 0 || isResettingOrderPlan
												}
												onClick={clearAllScheduledOrdersDialog.open}
												className="grid grid-cols-[auto_auto_1fr]"
											>
												<Eraser />
												<span>Clear All Scheduled</span>
												<Badge variant="outline" className="ml-auto font-mono">
													{plannedOrders.length}
												</Badge>
											</DropdownMenuItem>
											<DropdownMenuItem
												disabled={progressedBookings.length === 0}
												onClick={resetAllProgressDialog.open}
												className="grid grid-cols-[auto_auto_1fr]"
											>
												<RotateCcw />
												<span>Reset All Progress</span>
												<Badge variant="outline" className="ml-auto font-mono">
													{progressedBookings.length}
												</Badge>
											</DropdownMenuItem>
										</DropdownMenuGroup>
									</DropdownMenuContent>
								</DropdownMenu>
							</div>
						</ScrollableToolbarContentLeft>
						<ScrollableToolbarContentRight>
							{isFiltered && (
								<Button
									variant="ghost"
									onClick={resetFilter}
									className="h-8 px-2 lg:px-3"
								>
									Reset
									<X className="ml-1 size-4" />
								</Button>
							)}
							{isFiltered && filteredBookingsByOrderIds.length > 0 && (
								<div className="border-border flex items-center rounded-md border text-sm">
									<Button
										variant="ghost"
										size="icon"
										className="size-8 rounded-r-none border-r"
										onClick={bookingNavigation.onPrev}
										aria-label="Previous filtered booking"
									>
										<ChevronLeft className="size-4" />
									</Button>
									<span className="text-muted-foreground px-2 py-1 text-xs whitespace-nowrap">
										{currentFilteredBookingIndex + 1} of{' '}
										{filteredBookingsByOrderIds.length}
									</span>
									<Button
										variant="ghost"
										size="icon"
										className="size-8 rounded-l-none border-l"
										onClick={bookingNavigation.onNext}
										aria-label="Next filtered booking"
									>
										<ChevronRight className="size-4" />
									</Button>
								</div>
							)}
							<MultiSelectFilter
								title="Order"
								align="end"
								options={allOrders.map(order => ({
									disabled: !isProcessedOrder(order),
									value: order.id,
									label: order.productionOrderNumber,
									icon: () => {
										switch (order.status) {
											case 'pending':
												return <CircleHelp className="text-yellow-600" />
											case 'planned':
												return <CalendarIcon className="text-blue-600" />
											case 'in-progress':
												return <TimerIcon className="text-emerald-600" />
											case 'completed':
												return <CheckCircleIcon className="text-purple-600" />
											default:
												return null
										}
									},
									facets: isProcessedOrder(order)
										? order.bookingIds.length
										: undefined,
								}))}
								selected={search.orderIds || []}
								onChange={selectedIds => {
									void navigate({
										search: old => ({ ...old, orderIds: selectedIds }),
									})
								}}
							/>
							{filteredViewOptions.map(option => (
								<Tooltip key={option.key}>
									<TooltipTrigger asChild>
										<div
											role="button"
											className={cn(
												buttonVariants({ variant: 'outline', size: 'default' }),
											)}
											onClick={() => {
												toggleViewOption(option.key)
											}}
										>
											<option.icon />
											<Switch
												id={option.key}
												checked={viewStates[option.key]}
												className="h-[16px] w-[28px] [&>span]:h-[12px] [&>span]:w-[12px] [&>span[data-state=checked]]:translate-x-3"
											/>
										</div>
									</TooltipTrigger>
									<TooltipContent
										container={fullScreenContainer}
										side="bottom"
										className="flex w-60 flex-col gap-1"
									>
										<p className="font-medium">{option.title}</p>
										<p className="text-muted-foreground text-xs">
											{option.description}
										</p>
									</TooltipContent>
								</Tooltip>
							))}
							<Tooltip>
								<TooltipTrigger asChild>
									<Button
										disabled={pendingOrders.length === 0}
										onClick={() => {
											resetFilter()
											void handlePlanOrder({ order: pendingOrders[0] })
										}}
									>
										Plan Next Order
										{planOrderHotkeyLabel && (
											<CommandShortcut>{planOrderHotkeyLabel}</CommandShortcut>
										)}
										<Badge variant="secondary" className="font-mono">
											{pendingOrders.length}
										</Badge>
									</Button>
								</TooltipTrigger>
								<TooltipContent
									container={fullScreenContainer}
									side="bottom"
									align="center"
									className="w-[300px] overflow-hidden"
								>
									{pendingOrders.length === 0 ? (
										<div className="text-center">
											<span className="text-muted-foreground">
												{isFiltered
													? 'No pending orders match the filter'
													: 'No pending orders'}
											</span>
										</div>
									) : (
										<div className="space-y-2">
											<div className="space-y-1">
												<h2 className="text-muted-foreground text-xs font-medium uppercase">
													Next Order in Queue
												</h2>
												<div className="border-primary-foreground/20 bg-background/5 rounded-md border p-2">
													<h3 className="text-lg font-semibold">
														#{pendingOrders[0].productionOrderNumber}
													</h3>
													<span className="text-muted-foreground col-span-2 truncate text-xs">
														Sales Order: #{pendingOrders[0].salesOrderNumber}
													</span>
												</div>
											</div>
											<div className="grid grid-cols-2 gap-2">
												<div className="space-y-1">
													<p className="text-muted-foreground text-xs">
														Product Number
													</p>
													<p className="truncate text-sm break-words">
														#
														{productEntities[pendingOrders[0].product.id]
															?.productNumber || 'Unknown'}
													</p>
												</div>
												<div className="space-y-1">
													<p className="text-muted-foreground text-xs">
														Product Name
													</p>
													<p className="truncate text-sm break-words">
														{productEntities[pendingOrders[0].product.id]
															?.name || 'Unknown'}
													</p>
												</div>
												<div className="space-y-1">
													<p className="text-muted-foreground text-xs">
														Quantity
													</p>
													<p className="text-sm break-words">
														{formatNumericString(pendingOrders[0].quantity)}
													</p>
												</div>
												<div className="space-y-1">
													<p className="text-muted-foreground text-xs">
														Customer
													</p>
													<p className="truncate text-sm break-words">
														{pendingOrders[0].customerName}
													</p>
												</div>
												<div className="space-y-1">
													<p className="text-muted-foreground text-xs">
														Earliest Start
													</p>
													<p className="text-sm break-words">
														{format(
															pendingOrders[0].earliestStartDate,
															'd MMM yyyy',
														)}
													</p>
												</div>
												<div className="space-y-1">
													<p className="text-muted-foreground text-xs">
														Due Date
													</p>
													<p className="text-sm break-words">
														{format(pendingOrders[0].dueDate, 'd MMM yyyy')}
													</p>
												</div>
											</div>

											<Button
												size="sm"
												variant="ghost"
												className="border-primary-foreground/20 w-full border"
												onClick={() => {
													handleEditOrder(pendingOrders[0].id)
												}}
											>
												View Order Details <ArrowRightIcon />
											</Button>
										</div>
									)}
								</TooltipContent>
							</Tooltip>
							<Select
								value={schedulingDirection}
								onValueChange={(value: TSchedulingDirection) =>
									dispatch(setSchedulingDirection(value))
								}
							>
								<SelectTrigger className="w-56">
									<SelectValue placeholder="Choose Scheduling Direction" />
								</SelectTrigger>
								<SelectContent container={fullScreenContainer}>
									<SelectItem value="forward">
										<ArrowRightFromLine />
										Scheduling Forwards
									</SelectItem>
									<SelectItem value="backward">
										<ArrowLeftFromLine />
										Scheduling Backwards
									</SelectItem>
								</SelectContent>
							</Select>
							<Button
								title="Calendar Adjustments"
								variant="outline"
								size="icon"
								onClick={() =>
									void navigate({ to: '/planning/calendar-adjustments' })
								}
							>
								<CalendarIcon />
							</Button>
							{isFullScreenSupported && (
								<Button
									title={
										isFullScreen
											? 'Exit Full Screen Mode'
											: 'Enter Full Screen Mode'
									}
									variant="outline"
									size="icon"
									onClick={toggleFullScreen}
								>
									{isFullScreen ? <Minimize /> : <Maximize />}
								</Button>
							)}
						</ScrollableToolbarContentRight>
					</ScrollableToolbar>
				</PageLayout>
				<Gantt
					scrollRef={scrollViewRef}
					machines={orderedMachines}
					productEntities={productEntities}
					orderEntities={orderEntities}
					bookings={filteredBookingsByOrderIds}
					alerts={alerts}
					calendarAdjustments={calendarAdjustments}
					onZoomIn={createZoomFn(onZoomIn)}
					onZoomOut={createZoomFn(onZoomOut)}
					activeOrderId={activeOrderId} // TODO: Handle this state over URL params
					activeBookingId={activeBookingId} // TODO: Handle this state over URL params
					isHidden={isHidden}
					onToggleVisibility={toggleVisibility}
					showAlerts={viewStates.showAlerts}
					showOperators={viewStates.showOperators}
					canMoveBookings={canMoveBookings}
					setCanMoveBookings={setCanMoveBookings}
					bookingNavigation={isFiltered ? bookingNavigation : undefined}
					onAdjustMachineCalendar={machineId =>
						void navigate({
							to: '/planning/calendar-adjustments/new',
							search: {
								machines: [machineId],
							},
						})
					}
					onUpdateProgress={openUpdateProgressDialog}
					onReorderMachines={reorderMachines}
					onSelectBooking={selectBooking}
					onSelectNextBooking={selectNextBooking}
					onSelectPrevBooking={selectPrevBooking}
					onMoveBooking={moveBooking}
					onSwitchBookingMachine={openSwitchBookingMachineDialog}
					onChangeBookingStartDate={openChangeBookingStartDateDialog}
					onAlignBookings={openAlignBookingsDialog}
					focusViewOnDate={focusViewOnDate}
					onIgnoreAlert={onIgnoreAlert}
					onActivateAlert={onActivateAlert}
					onEditOrder={handleEditOrder}
					{...zoomLevelSettings}
				/>
			</div>
			{changeBookingStartDateDialogOpen && activeBooking && (
				<ChangeStartDateDialog
					booking={activeBooking}
					container={fullScreenContainer}
					onClose={closeChangeBookingStartDateDialog}
					onSubmit={date => {
						focusViewOnDate(date, 0.4)
						focusViewOnMachine(activeBooking.machineId)
					}}
					onReplan={handleReplanOrderFromDate}
				/>
			)}
			{alignBookingsDialogOpen && activeBooking && (
				<AlignBookingsDialog
					booking={activeBooking}
					container={fullScreenContainer}
					onClose={closeAlignBookingsDialog}
					onSubmit={() => {
						focusViewOnMachine(activeBooking.machineId)
					}}
				/>
			)}
			<GenericDialog
				title="Switch machine"
				description="Switch the machine for this booking"
				hideDescription
				container={fullScreenContainer}
				open={Boolean(switchBookingMachine)}
				onClose={closeSwitchBookingMachineDialog}
			>
				<Select
					value={switchBookingMachine?.selectedMachineId}
					onValueChange={value => {
						setSwitchBookingMachine(prev =>
							prev ? { ...prev, selectedMachineId: value } : null,
						)
					}}
				>
					<SelectTrigger className="w-full">
						<div>
							<span className="text-muted-foreground mr-2">Machine:</span>
							<SelectValue placeholder="Select Machine" />
						</div>
					</SelectTrigger>
					<SelectContent>
						{switchBookingMachine?.compatibleMachines.map(machine => (
							<SelectItem key={machine.id} value={machine.id}>
								{machine.name}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
				<DialogFooter>
					<Button variant="outline" onClick={closeSwitchBookingMachineDialog}>
						Cancel
					</Button>
					<Button
						disabled={
							switchBookingMachine?.initialMachineId ===
							switchBookingMachine?.selectedMachineId
						}
						onClick={() => {
							if (switchBookingMachine) {
								handleSwitchMachine(switchBookingMachine.selectedMachineId)
							} else {
								toast.error('No valid machine selected')
							}
						}}
					>
						Switch Machine
					</Button>
				</DialogFooter>
			</GenericDialog>
			{updateProgressDialogOpen && activeBooking && (
				<UpdateProgressDialog
					booking={activeBooking}
					container={fullScreenContainer}
					onClose={closeUpdateProgressDialog}
				/>
			)}
			<GenericAlertDialog
				title="Unschedule last order"
				description="Are you sure you want to unschedule the last order? This action cannot be undone."
				container={fullScreenContainer}
				open={unscheduleLastOrderDialog.isOpen}
				confirmButtonText="Unschedule Last Order"
				onClose={unscheduleLastOrderDialog.close}
				onConfirm={() => {
					handleUnscheduleOrder(plannedOrders[plannedOrders.length - 1])
				}}
			/>
			<GenericAlertDialog
				title="Clear all scheduled orders"
				description="Are you sure you want to clear all scheduled orders? This action cannot be undone."
				container={fullScreenContainer}
				open={clearAllScheduledOrdersDialog.isOpen}
				confirmButtonText="Clear All Scheduled Orders"
				onClose={clearAllScheduledOrdersDialog.close}
				onConfirm={() => {
					handleUnscheduleOrders(plannedOrders.map(o => o.id))
				}}
			/>
			<GenericAlertDialog
				title="Reset all progress"
				description="Are you sure you want to reset all progress? This action cannot be undone."
				container={fullScreenContainer}
				open={resetAllProgressDialog.isOpen}
				confirmButtonText="Reset All Progress"
				onClose={resetAllProgressDialog.close}
				onConfirm={() => {
					void handleResetAllProgress()
				}}
			/>
			<Outlet />
		</>
	)
}

export { Route }
