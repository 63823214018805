'use no memo'

import { useState } from 'react'

import { TPhasedItemV2 } from '@repo/types'
import { Plus, X } from 'lucide-react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import { useAppSelector } from '@/app/hooks'
import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Combobox } from '@/components/ui/combobox'
import { DialogFooter } from '@/components/ui/dialog'
import { Toggle } from '@/components/ui/toggle'
import {
	selectAllOperators,
	selectOperatorEntities,
	selectPopulatedOperators,
} from '@/features/operators/operators-slice'
import { cn } from '@/lib/utils'

import { ManageOperatorsDialog } from './manage-operators-dialog'

type AssignOperatorsDialogProps = {
	open: boolean
	onOpenChange: (open: boolean) => void
	onSubmit: (operators: TPhasedItemV2[]) => void
	initialOperators?: TPhasedItemV2[]
	container?: HTMLElement | null
	booking: {
		effectiveTimeMinutes: { before: number; during: number; after: number }
	}
}

type OperatorFormItem = TPhasedItemV2 & {
	name: string
	initials: string
}

type PhaseToggleGroupProps = {
	name: string
	phases: OperatorFormItem['phases']
	onChange: (value: OperatorFormItem['phases']) => void
	effectiveTimeMinutes: {
		before: number
		during: number
		after: number
	}
}

const PhaseToggleGroup = (props: PhaseToggleGroupProps) => {
	const { name, phases, onChange, effectiveTimeMinutes } = props
	if (name.length === 0) return null

	// Only show phases that have time allocated
	const availablePhases = {
		before: effectiveTimeMinutes.before > 0,
		during: true, // Production phase is always available
		after: effectiveTimeMinutes.after > 0,
	}

	const phaseLabels = {
		before: 'Setup',
		during: 'Production',
		after: 'Teardown',
	}

	// Filter to only phases that are available
	const phaseEntries = Object.entries(phaseLabels).filter(
		([key]) => availablePhases[key as keyof typeof availablePhases],
	)

	const handleToggleChange = (phase: string, pressed: boolean) => {
		onChange({
			...phases,
			[phase]: pressed,
		})
	}

	return (
		<div className="flex flex-wrap items-center gap-1">
			{phaseEntries.map(([phase, label]) => (
				<Toggle
					key={phase}
					pressed={phases[phase as keyof typeof phases]}
					onPressedChange={pressed => {
						handleToggleChange(phase, pressed)
					}}
					variant="outline"
					aria-label={`Toggle ${label.toLowerCase()} phase`}
					className="data-[state=on]:bg-primary data-[state=on]:text-primary-foreground text-xs data-[state=off]:opacity-70 sm:px-4"
				>
					{label}
				</Toggle>
			))}
		</div>
	)
}

export function AssignOperatorsDialog({
	open,
	onOpenChange,
	onSubmit,
	initialOperators = [],
	booking,
	container,
}: AssignOperatorsDialogProps) {
	const operators = useAppSelector(selectAllOperators)
	const operatorEntities = useAppSelector(selectOperatorEntities)
	const populatedOperators = useAppSelector(state =>
		selectPopulatedOperators(state, initialOperators),
	)
	const [manageOperatorsOpen, setManageOperatorsOpen] = useState(false)

	const { control, handleSubmit, watch, reset } = useForm({
		defaultValues: {
			operators:
				populatedOperators.length > 0
					? populatedOperators
					: [
							{
								id: '',
								name: '',
								initials: '',
								factor: 1,
								phases: {
									before: false,
									during: true,
									after: false,
								},
							},
						],
		},
	})

	// Reset form when dialog closes
	const handleDialogOpenChange = (isOpen: boolean) => {
		if (!isOpen) {
			// Reset form to initial values based on initialOperators
			reset({
				operators:
					populatedOperators.length > 0
						? populatedOperators
						: [
								{
									id: '',
									name: '',
									initials: '',
									factor: 1,
									phases: {
										before: false,
										during: true,
										after: false,
									},
								},
							],
			})
		}
		onOpenChange(isOpen)
	}

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'operators',
	})

	const watchedOperators = watch('operators')

	// Clean up deleted operators when the manage operators dialog closes
	const handleManageOperatorsClose = (isOpen: boolean) => {
		setManageOperatorsOpen(isOpen)
		if (!isOpen) {
			// Get current form values
			const currentOperators = watchedOperators
			// Filter out operators that no longer exist and keep valid ones
			const validOperators = currentOperators.filter(
				op => op.id === '' || operatorEntities[op.id],
			)
			// Add an empty operator if all were removed
			if (validOperators.length === 0) {
				validOperators.push({
					id: '',
					name: '',
					initials: '',
					factor: 1,
					phases: {
						before: false,
						during: true,
						after: false,
					},
				})
			}
			// Reset form with valid operators
			reset({ operators: validOperators })
		}
	}

	const handleFormSubmit = (data: { operators: OperatorFormItem[] }) => {
		// Deduplicate operators by ID to prevent duplicates
		const uniqueOperators = new Map<string, OperatorFormItem>()

		data.operators.forEach(operator => {
			if (operator.id && operator.name && operatorEntities[operator.id]) {
				uniqueOperators.set(operator.id, operator)
			}
		})

		const operatorsWithData = Array.from(uniqueOperators.values()).map(
			operator => ({
				id: operator.id,
				factor: operator.factor,
				phases: operator.phases,
			}),
		)

		onSubmit(operatorsWithData)
		onOpenChange(false)
	}

	// Function to check if an operator is already assigned
	const isOperatorAssigned = (operatorId: string, currentIndex?: number) => {
		// If no operator ID, it's not assigned
		if (!operatorId) return false

		// Check if this operator ID exists in any other field
		return watchedOperators.some(
			(op, index) =>
				// Must have both ID and name to be considered assigned
				op.id === operatorId &&
				op.name &&
				// Skip the current field when checking
				(currentIndex === undefined || index !== currentIndex),
		)
	}

	// Get available operators for a specific field
	const getAvailableOperatorsForField = (currentIndex: number) => {
		const currentOperator = watchedOperators[currentIndex]

		return operators.filter(
			operator =>
				// Include if it's the current selection
				operator.id === currentOperator.id ||
				// Or if it's not assigned elsewhere
				!isOperatorAssigned(operator.id, currentIndex),
		)
	}

	// Determine if "Add Operator" button should be visible
	const showAddOperatorButton = () => {
		// Get all currently assigned operator IDs
		const assignedIds = new Set(
			watchedOperators.filter(op => op.id && op.name).map(op => op.id),
		)

		// Check if there are any operators not yet assigned
		const hasAvailableOperators = operators.some(op => !assignedIds.has(op.id))

		// Show button if there are available operators and the last field is filled
		return (
			hasAvailableOperators &&
			(watchedOperators.length === 0 ||
				(watchedOperators.length > 0 &&
					watchedOperators[watchedOperators.length - 1].name.length > 0))
		)
	}

	return (
		<>
			<GenericDialog
				open={open}
				onClose={() => {
					handleDialogOpenChange(false)
				}}
				container={container}
				title="Assign Operators"
				description="Add operators and assign their phases for this booking."
				className="flex h-[90svh] flex-col gap-0 p-0 sm:max-w-[600px]"
				headerClassName="border-b p-6"
				headerChildren={
					<div className="flex justify-end">
						<Button
							variant="outline"
							size="sm"
							onClick={() => {
								setManageOperatorsOpen(true)
							}}
							className="h-9 whitespace-nowrap"
						>
							<Plus className="text-muted-foreground" />
							Manage Operators
						</Button>
					</div>
				}
			>
				<form
					onSubmit={e => {
						void handleSubmit(handleFormSubmit)(e)
					}}
					className="flex min-h-0 flex-1 flex-col overflow-hidden"
				>
					<div className="flex-1 overflow-hidden">
						<div className="h-full overflow-y-auto px-6 py-6">
							<div className="space-y-4">
								{operators.length === 0 ? (
									<Card className="border-dashed p-6">
										<div className="text-center">
											<p className="text-sm font-medium">
												No operators available
											</p>
											<p className="text-muted-foreground mt-1 text-sm">
												Create operators first to assign them to bookings
											</p>
										</div>
									</Card>
								) : (
									<>
										{fields.map(({ id }, index) => (
											<Controller
												key={id}
												name={`operators.${index}`}
												control={control}
												render={({ field }) => (
													<div className="group bg-card hover:bg-accent/10 relative rounded-lg border p-4 shadow-xs transition-colors">
														<div className="flex flex-col gap-4">
															{/* Header with remove button */}
															<div className="flex items-center justify-between sm:hidden">
																<div className="text-muted-foreground text-sm font-medium">
																	{field.value.name || 'Select Operator'}
																</div>
																<Button
																	type="button"
																	variant="ghost"
																	size="icon"
																	onClick={() => {
																		remove(index)
																	}}
																	className="size-8"
																>
																	<X />
																</Button>
															</div>

															{/* Main content */}
															<div className="flex flex-col gap-2 sm:flex-row sm:items-start">
																<div className="flex flex-1 flex-col gap-x-4 gap-y-2 sm:flex-row sm:items-center">
																	<div
																		className={cn(
																			'sm:w-[180px]',
																			!field.value.name && 'sm:w-full',
																		)}
																	>
																		<Combobox
																			container={container}
																			className="h-9 w-full truncate"
																			initialValue={field.value.id}
																			searchPlaceholder="Search operators..."
																			valuePlaceholder="Select an operator..."
																			options={getAvailableOperatorsForField(
																				index,
																			).map(o => ({
																				value: o.id,
																				label: `${o.name} (${o.initials})`,
																				keywords: [o.name, o.initials],
																			}))}
																			getOptionValue={option => option.label}
																			onSelect={id => {
																				// If selecting a new operator that's different from current
																				if (id !== field.value.id) {
																					const selectedOperator =
																						operatorEntities[id]
																					if (selectedOperator) {
																						// Set the new operator
																						field.onChange({
																							...field.value,
																							id: selectedOperator.id,
																							name: selectedOperator.name,
																							initials:
																								selectedOperator.initials,
																						})
																					}
																				}
																			}}
																		/>
																	</div>
																	{field.value.name.length > 0 && (
																		<div className="flex-1">
																			<PhaseToggleGroup
																				name={field.value.name}
																				phases={field.value.phases}
																				onChange={phases => {
																					field.onChange({
																						...field.value,
																						phases,
																					})
																				}}
																				effectiveTimeMinutes={
																					booking.effectiveTimeMinutes
																				}
																			/>
																		</div>
																	)}
																</div>

																{/* Desktop remove button */}
																<Button
																	type="button"
																	variant="ghost"
																	size="icon"
																	onClick={() => {
																		remove(index)
																	}}
																	className="hidden size-9 sm:flex"
																>
																	<X />
																</Button>
															</div>
														</div>
													</div>
												)}
											/>
										))}
										{showAddOperatorButton() && (
											<Button
												variant="outline"
												type="button"
												onClick={() => {
													append({
														id: '',
														name: '',
														initials: '',
														factor: 1,
														phases: {
															before: false,
															during: true,
															after: false,
														},
													})
												}}
												className="h-9 w-full"
											>
												<Plus className="text-muted-foreground" />
												Add Operator
											</Button>
										)}
									</>
								)}
							</div>
						</div>
					</div>

					<DialogFooter className="border-t p-6">
						<Button
							type="button"
							variant="outline"
							onClick={() => {
								onOpenChange(false)
							}}
							className="w-full sm:w-auto"
						>
							Cancel
						</Button>
						<Button
							type="submit"
							disabled={operators.length === 0}
							className="w-full sm:w-auto"
						>
							Save Changes
						</Button>
					</DialogFooter>
				</form>
			</GenericDialog>

			<ManageOperatorsDialog
				open={manageOperatorsOpen}
				onOpenChange={handleManageOperatorsClose}
				container={container}
			/>
		</>
	)
}
