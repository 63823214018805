import * as React from 'react'

import { CloudAlertIcon, CloudIcon, CloudOffIcon } from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/ui/tooltip'
import {
	BACKUP_SETTING_CHANGED_EVENT,
	isBackupSettingEnabled,
} from '@/features/backup/backup-settings'
import { useBackupStatus } from '@/features/backup/hooks/use-backup-status'

// Time thresholds in milliseconds
const WARNING_THRESHOLD_MS = 2 * 60 * 60 * 1000 // 2 hours

type BackupState = 'success' | 'warning' | 'none' | 'disabled'

const getBackupDisplayProps = (
	state: BackupState,
	formattedTimestamp: string | null,
): {
	IconComponent: React.ElementType
	className: string
	tooltipContent: string
} => {
	switch (state) {
		case 'disabled':
			return {
				IconComponent: CloudOffIcon,
				className: 'text-destructive',
				tooltipContent: 'Cloud backups are currently disabled.',
			}
		case 'warning':
			return {
				IconComponent: CloudAlertIcon,
				className: 'text-amber-500 dark:text-amber-400',
				tooltipContent: `Last backup over 2 hours ago: ${formattedTimestamp ?? 'N/A'}`,
			}
		case 'success':
			return {
				IconComponent: CloudIcon,
				className: 'text-emerald-500 dark:text-emerald-400',
				tooltipContent: `Data last backed up: ${formattedTimestamp ?? 'N/A'}`,
			}
		case 'none':
		default:
			return {
				IconComponent: CloudOffIcon,
				className: 'text-muted-foreground',
				tooltipContent: 'No backup found.',
			}
	}
}

export function BackupIndicator() {
	const { hasBackup, formattedLastBackupTime, lastBackupTime } =
		useBackupStatus()
	// State for the backup enabled setting, initialized from localStorage
	const [backupIsEnabled, setBackupIsEnabled] = React.useState<boolean>(() =>
		isBackupSettingEnabled(),
	)

	// Listen for changes to the setting
	React.useEffect(() => {
		const handleSettingChange = () => {
			setBackupIsEnabled(isBackupSettingEnabled())
		}

		window.addEventListener(BACKUP_SETTING_CHANGED_EVENT, handleSettingChange)
		window.addEventListener('storage', handleSettingChange) // Listen for other tabs

		return () => {
			window.removeEventListener(
				BACKUP_SETTING_CHANGED_EVENT,
				handleSettingChange,
			)
			window.removeEventListener('storage', handleSettingChange)
		}
	}, [])

	// 1. Determine the current state
	let currentState: BackupState

	if (!backupIsEnabled) {
		currentState = 'disabled'
	} else if (hasBackup && lastBackupTime) {
		const timeDifference = Date.now() - lastBackupTime
		if (timeDifference > WARNING_THRESHOLD_MS) {
			currentState = 'warning'
		} else {
			currentState = 'success'
		}
	} else {
		// Backups are enabled, but none found
		currentState = 'none'
	}

	// 2. Get display props
	const { IconComponent, className, tooltipContent } = getBackupDisplayProps(
		currentState,
		formattedLastBackupTime,
	)

	return (
		<Tooltip>
			<TooltipTrigger asChild>
				<Button className="cursor-help" variant="ghost" size="icon">
					<IconComponent className={className} />
				</Button>
			</TooltipTrigger>
			<TooltipContent side="bottom">{tooltipContent}</TooltipContent>
		</Tooltip>
	)
}
