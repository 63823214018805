import localForage from 'localforage'

// Inspired by: https://github.com/machester4/redux-persist-indexeddb-storage

export function indexedDbStorage(name: string) {
	const instance = localForage.createInstance({
		name,
	})

	return {
		getItem: (key: string) => instance.getItem(key),
		setItem: (key: string, value: string) => instance.setItem(key, value),
		removeItem: (key: string) => instance.removeItem(key),
	}
}
