import { createFileRoute, notFound, useNavigate } from '@tanstack/react-router'

import { store } from '@/app/store'
import { EditOrderDialog } from '@/features/orders/components/edit-order-dialog'
import { selectOrderById } from '@/features/orders/orders-slice'

const Route = createFileRoute('/orders/$orderId/edit')({
	component: EditOrderComponent,
	loader: ({ params: { orderId } }) => {
		const order = selectOrderById(store.getState(), orderId)
		if (!order) {
			// eslint-disable-next-line
			throw notFound()
		}
		return { order }
	},
})

function EditOrderComponent() {
	const { order } = Route.useLoaderData()
	const navigate = useNavigate({ from: Route.fullPath })

	return (
		<EditOrderDialog
			order={order}
			onClose={() => void navigate({ to: '/orders', search: true })}
		/>
	)
}

export { Route }
