import {
	TMachine,
	TPhaseDuration,
	TProductionTimeUnit,
	TProductOperation,
} from '@repo/types'

type TPopulatedMachinePhase = {
	machine: TMachine
	duration: TPhaseDuration<TProductionTimeUnit>
}

/**
 * Populates a product operation by replacing machine references with actual machine entities.
 *
 * This function is used within Redux Toolkit selectors to transform normalized data
 * into a denormalized form for consumption by components.
 */
function populateProductOperation(
	productOperation: TProductOperation,
	machineEntities: Record<string, TMachine>,
): TProductOperation<TMachine> {
	return {
		...productOperation,
		phases: {
			...productOperation.phases,
			during: productOperation.phases.during
				.map(({ machine, duration }) => ({
					machine: machineEntities[machine.id],
					duration,
				}))
				.filter((phase): phase is TPopulatedMachinePhase =>
					Boolean(phase.machine),
				),
		},
	}
}

export { populateProductOperation }
