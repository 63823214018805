import { createId } from '@paralleldrive/cuid2'
import * as Sentry from '@sentry/react'
import {
	addMinutes,
	differenceInMinutes,
	format,
	isAfter,
	isBefore,
	min,
} from 'date-fns'
import { toast } from 'sonner'

import { convertToMinutes } from '@/features/planning/schedule-order'

import { TPlanValidator } from './types'

const validateTransition: TPlanValidator = plan => {
	const alerts: ReturnType<TPlanValidator> = []

	plan.orders.forEach(order => {
		try {
			if (!('planningParameters' in order)) return

			const orderBookings = plan.bookings.filter(
				booking => booking.orderId === order.id,
			)
			// TODO: The source of truth for the correct sequence should be the sequence of the order.planningParameters.operations itself
			const orderOperations = order.planningParameters.operations

			for (let i = 0; i < orderBookings.length - 1; i++) {
				const operationA = orderOperations[i]
				const operationB = orderOperations[i + 1]

				const bookingA = orderBookings.find(
					booking => booking.operationId === operationA.id,
				)
				const bookingB = orderBookings.find(
					booking => booking.operationId === operationB.id,
				)

				if (!bookingA || !bookingB) continue
				if (bookingA.status === 'completed' || bookingB.status === 'completed')
					continue // TODO: Add test case for this

				const transition = operationA.transition ?? {
					kind: 'soft-linked',
					waitingTime: { quantity: 0, unit: 'minutes' },
					overlap: { quantity: 0, unit: 'minutes' },
				}

				const waitingTimeMinutes = convertToMinutes(transition.waitingTime)
				const allowedOverlap = transition.overlap
				const bookingADurationMinutes = differenceInMinutes(
					bookingA.endDate,
					bookingA.startDate,
				)

				let allowedOverlapMinutes = 0
				if (allowedOverlap.unit === 'percent') {
					allowedOverlapMinutes =
						(bookingADurationMinutes * allowedOverlap.quantity) / 100
				} else if (allowedOverlap.unit === 'minutes') {
					allowedOverlapMinutes = allowedOverlap.quantity
				} else if (allowedOverlap.unit === 'pieces') {
					allowedOverlapMinutes =
						Math.floor(allowedOverlap.quantity / order.quantity) *
						bookingADurationMinutes
				}

				const minStartDate = addMinutes(
					bookingA.endDate,
					waitingTimeMinutes - allowedOverlapMinutes,
				)
				const maxStartDate = addMinutes(bookingA.endDate, waitingTimeMinutes)

				if (transition.kind === 'hard-linked') {
					if (isBefore(bookingB.startDate, minStartDate)) {
						alerts.push({
							id: createId(),
							category: 'transition-hard-linked',
							orders: [{ id: order.id }],
							bookings: [{ id: bookingB.id }, { id: bookingA.id }],
							startDate: bookingB.startDate,
							endDate: min([minStartDate, bookingB.endDate]).toISOString(),
							suggestion: `Should start ${minStartDate.getTime() === maxStartDate.getTime() ? 'at' : 'no earlier than'} ${format(
								minStartDate,
								'd MMM, HH:mm',
							)}.`,
						})
					} else if (isAfter(bookingB.startDate, maxStartDate)) {
						alerts.push({
							id: createId(),
							category: 'transition-hard-linked',
							orders: [{ id: order.id }],
							bookings: [{ id: bookingB.id }, { id: bookingA.id }],
							startDate: bookingB.startDate,
							endDate: bookingB.endDate,
							suggestion: `Should start ${minStartDate.getTime() === maxStartDate.getTime() ? 'at' : 'no later than'} ${format(
								maxStartDate,
								'd MMM, HH:mm',
							)}.`,
						})
					}
				} else if (isBefore(bookingB.startDate, minStartDate)) {
					alerts.push({
						id: createId(),
						category: 'transition-soft-linked',
						orders: [{ id: order.id }],
						bookings: [{ id: bookingB.id }, { id: bookingA.id }],
						startDate: bookingB.startDate,
						endDate: min([minStartDate, bookingB.endDate]).toISOString(),
						suggestion: `Should start no earlier than ${format(
							minStartDate,
							'd MMM, HH:mm',
						)}.`,
					})
				}
			}
		} catch (error) {
			Sentry.captureException(error)
			toast.error(`Error validating transition`, {
				description: `Affects Order #${order.productionOrderNumber}. Please contact support if you need assistance.`,
			})
		}
	})

	return alerts
}

export { validateTransition }
