import { TOrder } from '@repo/types'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { toast } from 'sonner'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import {
	createCustomer,
	selectAllCustomers,
} from '@/features/customers/customers-slice'
import { useConfirmExitFormDialog } from '@/features/form-dialog/use-confirm-exit-form-dialog'
import { OrderForm } from '@/features/orders/forms/order-form'
import {
	createOrder,
	selectProductionOrderNumbers,
} from '@/features/orders/orders-slice'
import { selectAllProducts } from '@/features/products/products-slice'
import { useUniqueName } from '@/hooks/use-unique-name'

const Route = createFileRoute('/orders/create')({
	component: CreateOrderComponent,
})

function CreateOrderComponent() {
	const navigate = useNavigate({ from: Route.fullPath })
	const dispatch = useAppDispatch()
	const customers = useAppSelector(selectAllCustomers)
	const products = useAppSelector(selectAllProducts)
	const productionOrderNumbers = useAppSelector(selectProductionOrderNumbers)
	const { getUniqueName: getUniqueProductionOrderNumber } = useUniqueName(
		productionOrderNumbers,
		false,
	)

	const { ConfirmExitAlertDialog, formDialogProps, formProps } =
		useConfirmExitFormDialog<Omit<TOrder, 'id' | 'status'>>({
			onSubmit: data => {
				// TODO: Try using async thunk for this, and change the history so when you have created the order and navigate back, it will go to the edit route instead of the create route
				dispatch(createOrder(data))
				dispatch(createCustomer({ name: data.customerName }))
				toast.success(`Order "${data.productionOrderNumber}" created`)
			},
			onClose: () => {
				void navigate({
					to: '/orders',
					search: true,
				})
			},
		})

	return (
		<>
			<ConfirmExitAlertDialog />
			<GenericDialog
				title="Create order"
				description="Add a new order"
				hideDescription
				{...formDialogProps}
			>
				<OrderForm
					submitButtons={<Button type="submit">Create Order</Button>}
					initialValues={{
						productionOrderNumber: getUniqueProductionOrderNumber('P1001'),
						salesOrderNumber: '1001',
						earliestStartDate: new Date().toISOString(),
						buffer: {
							quantity: 0,
							unit: 'hours',
						},
						customerName: undefined,
						product: { id: '' },
						quantity: 0,
						dueDate: undefined,
						// Initialize comment as empty string to prevent react-hook-form from marking
						// the field as dirty due to undefined -> empty string coercion by the textarea
						comment: '',
					}}
					invalidProductionOrderNumbers={productionOrderNumbers}
					customers={customers}
					products={products}
					{...formProps}
				/>
			</GenericDialog>
		</>
	)
}

export { Route }
