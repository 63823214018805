import { useState } from 'react'

import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog'
import { cn } from '@/lib/utils'

function GenericDialog(
	props: {
		title: string
		description: string
		hideHeader?: boolean
		hideTitle?: boolean
		hideDescription?: boolean
		children: React.ReactNode
		onClose?: () => void
		open?: boolean
		container?: HTMLElement | null
		headerClassName?: string
		headerChildren?: React.ReactNode
	} & React.HTMLAttributes<HTMLDivElement>,
) {
	const {
		title,
		description,
		hideHeader,
		hideTitle,
		hideDescription,
		children,
		className,
		open,
		onClose,
		container,
		headerClassName,
		headerChildren,
		...rest
	} = props
	const [internalOpen, setInternalOpen] = useState(true)
	const dialogOpen = open ?? internalOpen

	function handleOpenChange(shouldOpen: boolean) {
		const shouldClose = !shouldOpen
		if (shouldClose) {
			if (open === undefined) {
				setInternalOpen(false)
			}
			onClose?.()
		}
	}

	return (
		<Dialog open={dialogOpen} onOpenChange={handleOpenChange}>
			<DialogContent
				className={cn(
					'max-h-[90svh] w-[95svw] max-w-[95svw] overflow-y-auto sm:max-w-[425px]',
					className,
				)}
				container={container}
				{...rest}
			>
				<DialogHeader className={cn(hideHeader && 'sr-only', headerClassName)}>
					<DialogTitle className={cn(hideTitle && 'sr-only')}>
						{title}
					</DialogTitle>
					<DialogDescription className={cn(hideDescription && 'sr-only')}>
						{description}
					</DialogDescription>
					{headerChildren}
				</DialogHeader>
				{children}
			</DialogContent>
		</Dialog>
	)
}

export { GenericDialog }
