import { createFileRoute, notFound, useNavigate } from '@tanstack/react-router'
import { TriangleAlert } from 'lucide-react'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { store } from '@/app/store'
import { GenericAlertDialog } from '@/components/generic-alert-dialog'
import { cascadeDeleteMachine } from '@/features/machines/machine-actions'
import {
	selectCanDeleteMachine,
	selectMachineById,
} from '@/features/machines/machines-slice'

const Route = createFileRoute('/machines/$machineId/delete')({
	component: DeleteMachineComponent,
	loader: ({ params: { machineId } }) => {
		const machine = selectMachineById(store.getState(), machineId)
		if (!machine) {
			// eslint-disable-next-line
			throw notFound()
		}
		return { machine }
	},
})

function DeleteMachineComponent() {
	const { machine } = Route.useLoaderData()
	const { machineId } = Route.useParams()
	const navigate = useNavigate({ from: Route.fullPath })
	const dispatch = useAppDispatch()
	const canDeleteMachine = useAppSelector(state =>
		selectCanDeleteMachine(state, machineId),
	)

	const title = canDeleteMachine ? (
		'Delete machine'
	) : (
		<span className="flex items-center gap-2">
			<TriangleAlert className="text-destructive size-5" />
			Cannot delete machine
		</span>
	)
	const description = canDeleteMachine
		? `Are you sure you want to delete "${machine.name}"? This action cannot be undone.`
		: 'This machine is used in one or more product operations, bookings, or calendar adjustments. Please remove it from these first.'

	function closeDialog() {
		void navigate({
			to: '/machines',
			search: true,
		})
	}

	return (
		<GenericAlertDialog
			title={title}
			description={description}
			confirmButtonText="Delete"
			confirmButtonDisabled={!canDeleteMachine}
			onClose={closeDialog}
			onConfirm={() => {
				closeDialog()
				void dispatch(cascadeDeleteMachine(machineId))
			}}
		/>
	)
}

export { Route }
