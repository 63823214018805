import { Middleware } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react' // Import Sentry

import debounce from 'lodash.debounce'

import { env } from '@/app/env'
import { RootState } from '@/app/store'
import { hashJson } from '@/lib/hash-json'

import {
	ANONYMOUS_USER_ID_KEY,
	BACKUP_COMPLETED_EVENT,
	isBackupSettingEnabled,
	LAST_BACKUP_TIMESTAMP_KEY,
} from '../backup-settings'
import { backupRequestSchema } from '../schemas/backup-schema'

/**
 * 📦 Backup Middleware (Debounced)
 *
 * Purpose:
 * This Redux middleware periodically backs up the application state to a remote server,
 * ensuring user progress is persisted. It uses debouncing to prevent excessive
 * backups during rapid state changes.
 *
 * Responsibilities:
 * - Monitor allowed Redux actions.
 * - Debounce backup checks to wait for state stabilization after actions cease.
 * - Check minimum backup interval and state changes (via hash) before initiating backup.
 * - Prevent concurrent backup attempts using an in-progress flag.
 * - Send validated backup payload to the configured API endpoint asynchronously.
 * - Store metadata (timestamp, hash) in `localStorage`.
 *
 * Strategies & Design Notes:
 * - Uses `lodash.debounce` to trigger backup checks only after a period of action inactivity.
 * - Uses an `isBackupRunning` flag to prevent overlapping network requests.
 * - `localStorage` persists metadata across sessions.
 * - Only backs up changed state.
 * - Denylisted actions are ignored.
 * - Works anonymously via client-generated UUID.
 */

// --- Constants ---
const LAST_BACKUP_HASH_KEY = 'polly_last_backup_hash'
const BACKUP_INTERVAL_MS = 1000 * 60 * 60 // 1 hour
const BACKUP_ENDPOINT = `${env.VITE_BACKUP_API_URL}/v1/backups`
const DEBOUNCE_WAIT_MS = 5000 // Wait 5 seconds after the last action before checking

// --- Helper Functions ---
const getStoredUserId = (): string => {
	const storedId = localStorage.getItem(ANONYMOUS_USER_ID_KEY)
	if (storedId) return storedId
	const newId = crypto.randomUUID()
	localStorage.setItem(ANONYMOUS_USER_ID_KEY, newId)
	return newId
}

const getLastBackupTimestamp = (): number => {
	const timestamp = localStorage.getItem(LAST_BACKUP_TIMESTAMP_KEY)
	return timestamp ? parseInt(timestamp, 10) : 0
}

const setLastBackupTimestamp = (timestamp: number): void => {
	localStorage.setItem(LAST_BACKUP_TIMESTAMP_KEY, timestamp.toString())
}

const getLastBackupHash = (): string | null => {
	return localStorage.getItem(LAST_BACKUP_HASH_KEY)
}

const setLastBackupHash = (hash: string): void => {
	localStorage.setItem(LAST_BACKUP_HASH_KEY, hash)
}

// --- State & Core Logic ---

// Flag to prevent concurrent backup attempts
let isBackupRunning = false

// Function to perform the actual backup network request
// Modified to return a promise and handle the flag
const performBackup = async (
	state: RootState,
	currentHash: string,
): Promise<void> => {
	// Set flag to prevent concurrent runs triggered by overlapping debounced calls
	isBackupRunning = true
	console.log('Backup initiated...')
	const userId = getStoredUserId() // Get userId early for context

	try {
		const payload = { userId, data: state }

		// 1. Zod Validation Check
		const validationResult = backupRequestSchema.safeParse(payload)
		if (!validationResult.success) {
			const errorMsg = 'Backup payload validation failed'
			console.error(errorMsg, validationResult.error.flatten())
			Sentry.captureException(new Error(errorMsg), {
				extra: {
					context: 'Backup payload failed Zod validation',
					zodErrorDetails: validationResult.error.flatten(), // Detailed validation errors
					userId: userId,
					stateHash: currentHash,
				},
				tags: { middleware: 'backupMiddleware', stage: 'validation' },
			})
			// Reset flags before returning needed here as finally won't run if we return early
			isBackupRunning = false
			return // Exit early
		}

		const response = await fetch(BACKUP_ENDPOINT, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(payload), // Use validated data if schema transforms it: validationResult.data
		})

		// 2. HTTP Response Check
		if (!response.ok) {
			const responseBody = await response.text() // Read body once
			const errorMsg = `Backup API request failed with status ${response.status}`
			console.error('Backup failed:', response.status, responseBody)
			Sentry.captureException(new Error(errorMsg), {
				// Level could be 'warning' or 'error' depending on how we want to treat API errors
				level: 'error',
				extra: {
					context: 'Backup API response not OK',
					status: response.status,
					responseBody: responseBody, // Include response body if helpful
					userId: userId,
					stateHash: currentHash,
				},
				tags: { middleware: 'backupMiddleware', stage: 'api_response' },
			})
			// Note: No state/localStorage update on failure
		} else {
			// Backup successful
			const backupTime = Date.now()
			// Update localStorage FIRST
			setLastBackupTimestamp(backupTime)
			setLastBackupHash(currentHash)
			console.debug(
				`Backup successful at ${new Date(backupTime).toISOString()}`,
			)

			// THEN, dispatch event for same-tab UI updates
			window.dispatchEvent(
				new CustomEvent(BACKUP_COMPLETED_EVENT, {
					detail: { timestamp: backupTime },
				}),
			)
		}
	} catch (error) {
		// 3. Catch block for fetch/network errors or other unexpected issues
		console.error('Error performing backup:', error)
		Sentry.captureException(error, {
			extra: {
				context: 'Error during performBackup fetch/processing',
				userId: userId,
				stateHash: currentHash, // Hash of state attempting to be backed up
			},
			tags: { middleware: 'backupMiddleware', stage: 'fetch_catch' },
		})
		// Note: No state/localStorage update on error
	} finally {
		// IMPORTANT: Always reset the flag whether backup succeeded or failed
		isBackupRunning = false
		console.log('Backup attempt finished.')
	}
}

// This function contains the core logic that decides IF a backup should run
// It will be debounced.
const checkAndInitiateBackup = (store: { getState: () => RootState }): void => {
	if (!isBackupSettingEnabled()) {
		// console.debug('Backup skipped: Feature disabled in localStorage setting.');
		return // Exit early if backups are disabled
	}

	try {
		console.debug('Debounced check triggered...')

		// Check 1: Backup already running?
		if (isBackupRunning) {
			console.debug('Backup skipped: Operation already in progress.')
			return
		}

		// Check 2: Time interval passed?
		const lastBackupTime = getLastBackupTimestamp() // Potential parseInt error if localStorage corrupted
		const currentTime = Date.now()
		if (currentTime - lastBackupTime < BACKUP_INTERVAL_MS) {
			return
		}

		// Check 3: State hash calculation
		const state = store.getState()
		const currentHash = hashJson(state) // Potential error if state is not serializable
		const lastHash = getLastBackupHash() // Potential localStorage read error

		// Check 4: State actually changed?
		if (currentHash === lastHash) {
			console.debug('Backup skipped: State hash unchanged.')
			return
		}

		// Checks passed, initiate backup
		void performBackup(state, currentHash)
	} catch (error) {
		console.error(
			'Error during backup pre-checks (hashing/localStorage):',
			error,
		)
		Sentry.captureException(error, {
			extra: {
				context: 'Error during checkAndInitiateBackup (pre-backup checks)',
			},
			tags: { middleware: 'backupMiddleware', stage: 'pre_check' },
		})
		// Do not proceed to backup if checks fail
	}
}

/**
 * How Debouncing Ensures Latest State Backup:
 * Uses `lodash.debounce` with `trailing: true` to trigger the backup check
 * (`checkAndInitiateBackup`) only *after* a period of action inactivity
 * (`DEBOUNCE_WAIT_MS`). Any relevant action dispatched during the wait period
 * resets the debounce timer. Consequently, `checkAndInitiateBackup` runs
 * once the actions cease and the state has stabilized. Its call to
 * `store.getState()` inside then captures the *latest*, cumulative state
 * resulting from the rapid action sequence, rather than an intermediate state
 * from the beginning or middle of the burst.
 */
const debouncedCheck = debounce(checkAndInitiateBackup, DEBOUNCE_WAIT_MS, {
	leading: false, // Don't run on the leading edge (wait until actions stop)
	trailing: true, // Run on the trailing edge (after the wait time)
})

// --- The Middleware ---
export const backupMiddleware: Middleware<unknown, RootState> =
	store => next => action => {
		// First, let the action go through and update the state
		const result = next(action)

		// Actions that should NOT trigger a backup check
		const actionDenylist = ['persist/PERSIST', 'persist/REHYDRATE']

		const actionIsAllowed =
			action &&
			typeof action === 'object' &&
			'type' in action &&
			typeof action.type === 'string' &&
			!actionDenylist.includes(action.type)

		// If the action is allowed, trigger the debounced check function
		if (actionIsAllowed) {
			// console.debug(`Action ${action.type} occurred, scheduling debounced check...`); // Can be noisy
			debouncedCheck(store)
		}

		return result
	}
