import React from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRouter, RouterProvider } from '@tanstack/react-router'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from '@/app/store'

import './index.css'

import * as Sentry from '@sentry/react'

import { env } from './app/env'
import { NotFound } from './components/not-found'
import { ThemeProvider } from './components/theme-provider'
import { FullScreenProvider } from './features/planning/context/full-screen-provider'
import { routeTree } from './route-tree.gen'

if (import.meta.env.PROD) {
	Sentry.init({
		dsn: 'https://d3ef833544af65a08ab72213da193ec0@o4508318799101952.ingest.de.sentry.io/4508318802903120',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost', new RegExp(`^${env.VITE_API_URL}/`)],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	})
}

const queryClient = new QueryClient()

const router = createRouter({
	routeTree,
	defaultPreload: 'intent',
	defaultNotFoundComponent: NotFound,
	notFoundMode: 'root',
})

declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router
	}
}

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ThemeProvider
					attribute="class"
					defaultTheme="system"
					enableSystem
					disableTransitionOnChange
					storageKey="theme"
				>
					<QueryClientProvider client={queryClient}>
						<FullScreenProvider>
							<RouterProvider router={router} />
						</FullScreenProvider>
					</QueryClientProvider>
				</ThemeProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
)
