import { useState } from 'react'

import { Loader2, Sparkles, Upload, X } from 'lucide-react'
import { useDropzone } from 'react-dropzone'

import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import { DialogFooter } from '@/components/ui/dialog'
import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'

export function AddDataDialog(props: {
	onClose: () => void
	onAddDemoDataset: () => Promise<void>
	onFileUpload: (file: File) => void
}) {
	const { onClose, onAddDemoDataset, onFileUpload } = props
	const [selectedFile, setSelectedFile] = useState<File | null>(null)
	const [demoDatasetLoading, setDemoDatasetLoading] = useState(false)

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: {
			'application/json': ['.json'],
		},
		maxFiles: 1,
		multiple: false,
		onDrop: acceptedFiles => {
			if (acceptedFiles[0]) {
				setSelectedFile(acceptedFiles[0])
			}
		},
	})

	const addDemoDataset = async () => {
		setDemoDatasetLoading(true)
		try {
			await onAddDemoDataset()
		} finally {
			setDemoDatasetLoading(false)
		}
	}

	const handleFileUpload = () => {
		if (selectedFile) {
			onFileUpload(selectedFile)
		}
	}

	const removeFile = (e: React.MouseEvent) => {
		e.stopPropagation()
		setSelectedFile(null)
	}

	return (
		<GenericDialog
			title="Add data"
			description="Select a predefined dataset or upload your JSON file. Either option will overwrite existing data."
			onClose={onClose}
		>
			<div className="grid gap-6">
				<div>
					<div className="mb-2 flex items-center justify-between">
						<Label className="text-sm font-medium">Demo Dataset</Label>
						{demoDatasetLoading ? (
							<Button disabled size="sm" variant="ghost" className="h-8">
								<Loader2 className="animate-spin" />
								Please wait
							</Button>
						) : (
							<Button
								variant="outline"
								size="sm"
								onClick={() => void addDemoDataset()}
							>
								<Sparkles className="text-muted-foreground" />
								Use Demo Dataset
							</Button>
						)}
					</div>
					<Separator />
				</div>

				<div>
					<div className="mb-2">
						<Label className="text-sm font-medium">Upload Dataset</Label>
					</div>
					<Separator />
					<div
						{...getRootProps()}
						className={cn(
							'mt-2 cursor-pointer rounded-lg border border-dashed px-3 py-4 transition-colors',
							isDragActive
								? 'border-primary bg-primary/5'
								: 'border-muted-foreground/50 hover:border-muted-foreground',
							selectedFile && 'border-primary/50 bg-primary/5',
						)}
					>
						<input {...getInputProps()} />
						<div className="flex items-center justify-center gap-3">
							<Upload className="text-muted-foreground size-5 shrink-0" />
							{selectedFile ? (
								<div className="flex items-center gap-2">
									<span className="text-sm">{selectedFile.name}</span>
									<Button
										type="button"
										variant="ghost"
										size="icon"
										className="size-5 rounded-full"
										onClick={removeFile}
									>
										<X className="size-3" />
									</Button>
								</div>
							) : (
								<div className="text-muted-foreground text-sm">
									Drop your JSON file here or{' '}
									<span className="text-primary inline">browse</span>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<DialogFooter className="mt-6">
				<Button type="button" variant="outline" onClick={onClose}>
					Cancel
				</Button>
				<Button onClick={handleFileUpload} disabled={!selectedFile}>
					Upload Dataset
				</Button>
			</DialogFooter>
		</GenericDialog>
	)
}
