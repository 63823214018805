import { useCallback, useEffect, useState } from 'react'

import { format } from 'date-fns'

import {
	BACKUP_COMPLETED_EVENT,
	LAST_BACKUP_TIMESTAMP_KEY,
} from '../backup-settings'

export type BackupStatus = {
	lastBackupTime: number | null
	formattedLastBackupTime: string | null
	hasBackup: boolean
}

// Event detail type (optional but good practice)
type BackupCompletedEventDetail = {
	timestamp: number
}

export function useBackupStatus(): BackupStatus {
	const [lastBackupTime, setLastBackupTime] = useState<number | null>(null)

	// Function to read current backup status from localStorage
	// useCallback to memoize the function if needed, though less critical here
	const readLatestBackupInfo = useCallback(() => {
		const timestampStr = localStorage.getItem(LAST_BACKUP_TIMESTAMP_KEY)

		setLastBackupTime(timestampStr ? parseInt(timestampStr, 10) : null)
	}, []) // No dependencies, reads directly

	useEffect(() => {
		// --- Initial Read ---
		readLatestBackupInfo()

		// --- Listener for same-tab updates (dispatched by middleware) ---
		const handleBackupCompleted = (event: Event) => {
			// Type assertion for CustomEvent
			const customEvent = event as CustomEvent<BackupCompletedEventDetail>
			console.debug('Caught backup-completed event')
			if (customEvent.detail) {
				setLastBackupTime(customEvent.detail.timestamp)
			} else {
				// Fallback if detail is missing, re-read from storage
				readLatestBackupInfo()
			}
		}
		window.addEventListener(BACKUP_COMPLETED_EVENT, handleBackupCompleted)

		// --- Listener for cross-tab updates ---
		const handleStorageChange = (event: StorageEvent) => {
			// Check if the relevant keys changed in another tab
			if (event.key === LAST_BACKUP_TIMESTAMP_KEY) {
				console.debug('Caught storage event from other tab')
				readLatestBackupInfo() // Re-read both if either changes
			}
		}
		window.addEventListener('storage', handleStorageChange)

		// --- Cleanup ---
		return () => {
			window.removeEventListener(BACKUP_COMPLETED_EVENT, handleBackupCompleted)
			window.removeEventListener('storage', handleStorageChange)
		}
	}, [readLatestBackupInfo]) // Include readLatestBackupInfo in dependency array

	// Format the timestamp for display
	const formattedLastBackupTime = lastBackupTime
		? format(new Date(lastBackupTime), 'PPp') // Using a locale-sensitive format
		: // Example: Mar 30, 2025, 1:36 PM (adjust format as needed)
			null

	// Determine if a backup exists
	const hasBackup = lastBackupTime !== null

	return {
		lastBackupTime,
		formattedLastBackupTime,
		hasBackup,
	}
}
