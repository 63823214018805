import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TPhasedItem } from '@repo/types'
import {
	createMigrate,
	FLUSH,
	PAUSE,
	PERSIST,
	PersistedState,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE,
} from 'redux-persist'

import { backupMiddleware } from '@/features/backup/middlewares/backup-middleware'
import customersReducer from '@/features/customers/customers-slice'
import machinesReducer from '@/features/machines/machines-slice'
import operatorsReducer from '@/features/operators/operators-slice'
import ordersReducer from '@/features/orders/orders-slice'
import planningReducer from '@/features/planning/planning-slice'
import productOperationsReducer from '@/features/products/product-operations-slice'
import productsReducer from '@/features/products/products-slice'
import staffGroupsReducer from '@/features/staff-groups/staff-groups-slice'
import toolsReducer from '@/features/tools/tools-slice'

import { indexedDbStorage } from './storage/indexeddb'
import { crossTabSync } from './sync/cross-tab-sync'

const rootReducer = combineReducers({
	machines: machinesReducer,
	products: productsReducer,
	productOperations: productOperationsReducer,
	tools: toolsReducer,
	staffGroups: staffGroupsReducer,
	orders: ordersReducer,
	customers: customersReducer,
	planning: planningReducer,
	operators: operatorsReducer,
})

const migrations = {
	// Migrations 2-6 removed as all users have upgraded past version 6
	7: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		newState.planning.bookings = newState.planning.bookings.map(booking => {
			const order = newState.orders.entities[booking.orderId]
			let tools: TPhasedItem[] = []

			if (order && 'planningParameters' in order) {
				const operation = order.planningParameters.operations.find(
					op => op.id === booking.operationId,
				)
				if (operation) {
					tools = operation.tools
				}
			}

			return {
				...booking,
				tools,
			}
		})

		return newState
	},
	8: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		newState.planning.bookings = newState.planning.bookings.map(booking => {
			const order = newState.orders.entities[booking.orderId]
			let staffGroups: TPhasedItem[] = []

			if (order && 'planningParameters' in order) {
				const operation = order.planningParameters.operations.find(
					op => op.id === booking.operationId,
				)
				if (operation) {
					staffGroups = operation.staffGroups
				}
			}

			return {
				...booking,
				staffGroups,
			}
		})

		return newState
	},
	9: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		newState.planning.bookings = newState.planning.bookings.map(booking => {
			if (!booking.operators) {
				return {
					...booking,
					operators: [],
				}
			}
			return booking
		})

		return newState
	},
	10: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		// Skip if already normalized or doesn't exist
		if (!newState.customers || !('list' in newState.customers)) {
			return newState
		}

		// Get the old customers list with proper typing
		const customersList = (
			newState.customers as unknown as {
				list: Array<{ id: string; name: string }>
			}
		).list

		// Transform the customers structure from array to normalized
		const transformedCustomers = {
			ids: customersList.map(customer => customer.id),
			entities: customersList.reduce<
				Record<string, { id: string; name: string }>
			>((acc, customer) => {
				acc[customer.id] = customer
				return acc
			}, {}),
		}

		// Replace the old customers structure with the new one
		newState.customers =
			transformedCustomers as unknown as typeof newState.customers

		return newState
	},
	11: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		// Skip if already normalized or doesn't exist
		if (!newState.tools || !('list' in newState.tools)) {
			return newState
		}

		// Get the old tools list with proper typing
		const toolsList = (
			newState.tools as unknown as {
				list: Array<{ id: string; name: string }>
			}
		).list

		// Transform the tools structure from array to normalized
		const transformedTools = {
			ids: toolsList.map(tool => tool.id),
			entities: toolsList.reduce<Record<string, { id: string; name: string }>>(
				(acc, tool) => {
					acc[tool.id] = tool
					return acc
				},
				{},
			),
		}

		// Replace the old tools structure with the new one
		newState.tools = transformedTools as unknown as typeof newState.tools

		return newState
	},
	12: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		// Skip if already normalized or doesn't exist
		if (!newState.staffGroups || !('list' in newState.staffGroups)) {
			return newState
		}

		// Get the old staffGroups list with proper typing
		const staffGroupsList = (
			newState.staffGroups as unknown as {
				list: Array<{ id: string; name: string }>
			}
		).list

		// Transform the staffGroups structure from array to normalized
		const transformedStaffGroups = {
			ids: staffGroupsList.map(staffGroup => staffGroup.id),
			entities: staffGroupsList.reduce<
				Record<string, { id: string; name: string }>
			>((acc, staffGroup) => {
				acc[staffGroup.id] = staffGroup
				return acc
			}, {}),
		}

		// Replace the old staffGroups structure with the new one
		newState.staffGroups =
			transformedStaffGroups as unknown as typeof newState.staffGroups

		return newState
	},
	13: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		// Skip if already normalized or doesn't exist
		if (!newState.machines || !('list' in newState.machines)) {
			return newState
		}

		// Get the old machines list with proper typing
		const machinesList = (
			newState.machines as unknown as {
				list: Array<{ id: string /* other machine properties */ }>
			}
		).list

		// Transform the machines structure from array to normalized
		const transformedMachines = {
			ids: machinesList.map(machine => machine.id),
			entities: machinesList.reduce<Record<string, unknown>>((acc, machine) => {
				acc[machine.id] = machine
				return acc
			}, {}),
		}

		// Replace the old machines structure with the new one
		newState.machines =
			transformedMachines as unknown as typeof newState.machines

		return newState
	},
	14: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		// Skip if already normalized or doesn't exist
		// @ts-expect-error - Checking for legacy structure
		if (!newState.products || !newState.products.list) {
			return newState
		}

		// Get the old products list with proper typing
		// @ts-expect-error - Accessing legacy structure
		const productsList = newState.products.list as Array<{
			id: string
			[key: string]: unknown
		}>

		// Transform the products structure from array to normalized
		const transformedProducts = {
			ids: productsList.map(product => product.id),
			entities: productsList.reduce<Record<string, unknown>>((acc, product) => {
				acc[product.id] = product
				return acc
			}, {}),
		}

		// Replace the old products structure with the new one
		newState.products =
			transformedProducts as unknown as typeof newState.products

		return newState
	},
	15: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		// Skip if already normalized or doesn't exist
		// @ts-expect-error - Checking for legacy structure
		if (!newState.productOperations || !newState.productOperations.list) {
			return newState
		}

		// Get the old productOperations list with proper typing
		// @ts-expect-error - Accessing legacy structure
		const productOperationsList = newState.productOperations.list as Array<{
			id: string
			productId: string
			[key: string]: unknown
		}>

		// Transform the productOperations structure from array to normalized
		const transformedProductOperations = {
			ids: productOperationsList.map(operation => operation.id),
			entities: productOperationsList.reduce<Record<string, unknown>>(
				(acc, operation) => {
					acc[operation.id] = operation
					return acc
				},
				{},
			),
		}

		// Replace the old productOperations structure with the new one
		newState.productOperations =
			transformedProductOperations as unknown as typeof newState.productOperations

		return newState
	},
}

export const storage = indexedDbStorage('polly-planning')

const persistConfig = {
	key: 'root',
	version: 15, // Increment version for the new migration
	storage,
	migrate: createMigrate(migrations, {
		debug: process.env.NODE_ENV === 'development',
	}),
}

// Export the persist version for use in cross-tab sync
export const PERSIST_VERSION = persistConfig.version

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(
	persistConfig,
	rootReducer,
)

// Define RootState *before* the store is created to avoid circular dependency
export type RootState = ReturnType<typeof persistedReducer>

export const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(crossTabSync, backupMiddleware),
})

// Define AppStore and AppDispatch after the store is created
export type AppStore = typeof store
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)
