import { Action, Middleware } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import { PERSIST_VERSION } from '../store'

/**
 * @description Denylist for cross-tab action synchronization.
 * Action types listed here will NOT be broadcast to other tabs,
 * nor processed if received from other tabs.
 * Use this for actions that are tab-specific or problematic when synced.
 * Note: Async thunk actions (/pending, /fulfilled, /rejected) ARE synced by default unless their base type is listed here.
 */
const syncDenylist: ReadonlyArray<string> = [
	// Persistence actions are often not suitable for direct syncing.
	'persist/PERSIST',
	'persist/REHYDRATE',
	// Add other specific action types to deny here.
	// Example: 'ui/toggleSomeLocalThing',
]

// Type for actions potentially involved in synchronization.
export type SyncAction = Action & {
	type: string // Redux action type.
	meta?: {
		isSync?: boolean // True if the action originated from another tab via this middleware.
		syncVersion?: number // Version of the persisted state relevant to this action.
	}
}

/**
 * @description Redux middleware using BroadcastChannel to synchronize actions across tabs.
 * - Broadcasts actions not originating from sync and not in `syncDenylist`.
 * - Listens for actions from other tabs, dispatches them locally if not in `syncDenylist`.
 * - Prevents sync loops using `meta.isSync`.
 * - Checks `meta.syncVersion` against local `PERSIST_VERSION` for compatibility.
 * - Syncs all action types by default, except those explicitly in `syncDenylist`.
 */
export const crossTabSync: Middleware = store => {
	// Channel for this app's store synchronization. Tabs with the same channel name connect.
	const channel = new BroadcastChannel('polly-store-sync')

	/**
	 * Handles actions received FROM other tabs via the BroadcastChannel.
	 */
	channel.onmessage = event => {
		try {
			// Assume incoming data is a valid action structure.
			const action = event.data as SyncAction

			// Process only if the action is valid (has a type) and not denied.
			if (action?.type && !syncDenylist.includes(action.type)) {
				// Version Check: Prevent applying actions from tabs with an incompatible (older) state version.
				if (
					!action.meta?.syncVersion ||
					action.meta.syncVersion < PERSIST_VERSION
				) {
					console.warn(
						`Ignoring ${!action.meta?.syncVersion ? 'unversioned' : 'outdated'} sync action type "${action.type}" ${action.meta?.syncVersion ? ` (v${action.meta.syncVersion})` : ''} from another tab. Current version: v${PERSIST_VERSION}`,
					)
					return // Do not process this action.
				}

				// Dispatch the action to the local store. Mark `isSync: true` in meta
				// to prevent this middleware instance from broadcasting it back out.
				store.dispatch({
					...action,
					meta: { ...action.meta, isSync: true },
				})
			}
			// Denied actions are silently ignored. Log here if needed for debugging:
			// else if (action?.type && syncDenylist.includes(action.type)) { console.debug(...) }
		} catch (error) {
			// Catch unexpected errors during message processing or dispatch.
			console.error(
				'Error processing synced action from another tab:',
				error,
				event.data, // Log the received data that caused the error.
			)
			Sentry.captureException(error, {
				extra: {
					context: 'Error processing received cross-tab action',
					receivedAction: event.data, // Add received action as extra data
				},
				tags: { middleware: 'crossTabSync', direction: 'receive' },
			})
			// Consider dispatching a global error state update if necessary.
		}
	}

	/**
	 * Intercepts actions dispatched in THIS tab to potentially broadcast them.
	 */
	return next => action => {
		// Let the action run through reducers first.
		const result = next(action)

		const typedAction = action as SyncAction

		// Determine if this action should be broadcast to other tabs.
		const shouldBroadcast =
			typedAction?.type && // Action must have a type.
			!typedAction.meta?.isSync && // Action must NOT have originated from sync (prevents loops).
			!syncDenylist.includes(typedAction.type) // Action type must NOT be in the specific denylist.

		if (shouldBroadcast) {
			try {
				// Prepare action for broadcast, adding the current persistence version.
				const syncAction: SyncAction = {
					...typedAction,
					meta: {
						...typedAction.meta,
						syncVersion: PERSIST_VERSION,
					},
				}
				// Send the action to other listening tabs.
				channel.postMessage(syncAction)
			} catch (error: unknown) {
				// Log errors during broadcasting (e.g., data serialization issues).
				console.warn(
					'Failed to broadcast action across tabs:',
					error,
					typedAction,
				)
				Sentry.captureException(error, {
					level: 'warning', // Match console level, less critical than failing to process
					extra: {
						context: 'Error broadcasting cross-tab action',
						broadcastAction: typedAction, // Add the action that failed as extra data
					},
					tags: { middleware: 'crossTabSync', direction: 'broadcast' },
				})
			}
		}

		return result
	}
}

// Note: BroadcastChannel cleanup (`channel.close()`) is typically handled
// by the browser upon tab closure and may not be necessary to call explicitly.
