export const LAST_BACKUP_TIMESTAMP_KEY = 'polly_last_backup_timestamp'
export const BACKUP_COMPLETED_EVENT = 'polly_backup_completed'
export const BACKUP_ENABLED_KEY = 'polly_backup_enabled'
export const ANONYMOUS_USER_ID_KEY = 'polly_anonymous_user_id'

/**
 * Checks if backups are enabled according to the setting in localStorage.
 * Defaults to true (enabled) if the setting is not found or invalid.
 */
export function isBackupSettingEnabled(): boolean {
	try {
		const storedValue = localStorage.getItem(BACKUP_ENABLED_KEY)
		// Consider 'null' (not set) as enabled by default.
		// Otherwise, check if the stored string is exactly 'true'.
		return storedValue === null || storedValue === 'true'
	} catch (error) {
		console.error(
			'Error reading backup enabled setting from localStorage:',
			error,
		)
		// Default to enabled in case of error reading localStorage
		return true
	}
}

/**
 * Sets the backup enabled setting in localStorage.
 * Dispatches a custom event to notify UI components of the change.
 */
export function setBackupSettingEnabled(enabled: boolean): void {
	try {
		const value = enabled ? 'true' : 'false'
		localStorage.setItem(BACKUP_ENABLED_KEY, value)
		// Dispatch a custom event that React components can listen to
		// Direct localStorage changes don't automatically trigger React re-renders.
		// We dispatch a custom window event (backupSettingChanged) whenever the setting is changed.
		// React components (like the toggle switch and the indicator) can listen for this event and update their own state accordingly.
		window.dispatchEvent(
			new CustomEvent('backupSettingChanged', { detail: { enabled } }),
		)
	} catch (error) {
		console.error('Error saving backup enabled setting to localStorage:', error)
	}
}

export const BACKUP_SETTING_CHANGED_EVENT = 'backupSettingChanged'
