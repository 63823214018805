import { createId } from '@paralleldrive/cuid2'
import {
	createEntityAdapter,
	createSelector,
	createSlice,
} from '@reduxjs/toolkit'
import type { EntityState, PayloadAction, Update } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import type { RootState } from '@/app/store'

export type TCustomer = {
	id: string
	name: string
}

const customersAdapter = createEntityAdapter<TCustomer>()

export const {
	selectIds: selectCustomerIds,
	selectEntities: selectCustomerEntities,
	selectAll: selectAllCustomers,
	selectTotal: selectTotalCustomers,
	selectById: selectCustomerById,
} = customersAdapter.getSelectors<RootState>(state => state.customers)

export type CustomersState = EntityState<TCustomer, string>

const initialState: CustomersState = customersAdapter.getInitialState()

export const customersSlice = createSlice({
	name: 'customers',
	initialState,
	reducers: {
		createCustomer: {
			reducer: (state, action: PayloadAction<TCustomer>) => {
				// Check if customer with same name already exists
				const customerExists = Object.values(state.entities).some(
					(customer): customer is TCustomer =>
						customer.name === action.payload.name,
				)
				if (!customerExists && action.payload.name !== '') {
					customersAdapter.addOne(state, action.payload)
				}
			},
			prepare: (customerData: Omit<TCustomer, 'id'>) => {
				const id = createId()
				return { payload: { ...customerData, id } }
			},
		},
		editCustomer: (state, action: PayloadAction<Update<TCustomer, string>>) => {
			customersAdapter.updateOne(state, action)
		},
		deleteCustomer: (state, action: PayloadAction<string>) => {
			customersAdapter.removeOne(state, action.payload)
		},
	},
	extraReducers: builder => {
		builder.addCase(PURGE, state => {
			customersAdapter.removeAll(state)
		})
	},
})

export const { createCustomer, editCustomer, deleteCustomer } =
	customersSlice.actions

export const selectCustomerNames = createSelector(
	[selectAllCustomers],
	customers => customers.map(customer => customer.name),
)

export default customersSlice.reducer
