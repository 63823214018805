import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@/components/ui/alert-dialog'

import { buttonVariants } from './ui/button'

function GenericAlertDialog(props: {
	children?: React.ReactNode
	title: React.ReactNode
	description: React.ReactNode
	confirmButtonText: React.ReactNode
	confirmButtonDisabled?: boolean
	cancelButtonText?: string
	open?: boolean
	container?: HTMLElement | null
	destructive?: boolean
	onClose: () => void
	onConfirm: () => void
}) {
	function handleOpenChange(open: boolean) {
		if (!open) {
			props.onClose()
		}
	}

	return (
		<AlertDialog open={props.open ?? true} onOpenChange={handleOpenChange}>
			<AlertDialogContent
				className="max-h-[90svh] w-[95svw] overflow-y-auto sm:max-w-lg"
				container={props.container}
			>
				<AlertDialogHeader>
					<AlertDialogTitle>{props.title}</AlertDialogTitle>
					<AlertDialogDescription>{props.description}</AlertDialogDescription>
				</AlertDialogHeader>
				{props.children}
				<AlertDialogFooter>
					<AlertDialogCancel onClick={props.onClose}>
						{props.cancelButtonText ?? 'Cancel'}
					</AlertDialogCancel>
					<AlertDialogAction
						disabled={props.confirmButtonDisabled}
						onClick={props.onConfirm}
						className={buttonVariants({ variant: 'destructive' })}
					>
						{props.confirmButtonText}
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

export { GenericAlertDialog }
